var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

import decodePng from "png-chunks-extract";
import tEXt from "png-chunk-text";
import encodePng from "png-chunks-encode";
import { stringToBase64, encode, decode, base64ToString } from "./encode";
import { EXPORT_DATA_TYPES, MIME_TYPES } from "../constants";
import { blobToArrayBuffer } from "./blob"; // -----------------------------------------------------------------------------
// PNG
// -----------------------------------------------------------------------------

export const getTEXtChunk = blob => __awaiter(void 0, void 0, void 0, function* () {
  const chunks = decodePng(new Uint8Array(yield blobToArrayBuffer(blob)));
  const metadataChunk = chunks.find(chunk => chunk.name === "tEXt");

  if (metadataChunk) {
    return tEXt.decode(metadataChunk.data);
  }

  return null;
});
export const encodePngMetadata = ({
  blob,
  metadata
}) => __awaiter(void 0, void 0, void 0, function* () {
  const chunks = decodePng(new Uint8Array(yield blobToArrayBuffer(blob)));
  const metadataChunk = tEXt.encode(MIME_TYPES.excalidraw, JSON.stringify(yield encode({
    text: metadata,
    compress: true
  }))); // insert metadata before last chunk (iEND)

  chunks.splice(-1, 0, metadataChunk);
  return new Blob([encodePng(chunks)], {
    type: MIME_TYPES.png
  });
});
export const decodePngMetadata = blob => __awaiter(void 0, void 0, void 0, function* () {
  const metadata = yield getTEXtChunk(blob);

  if ((metadata === null || metadata === void 0 ? void 0 : metadata.keyword) === MIME_TYPES.excalidraw) {
    try {
      const encodedData = JSON.parse(metadata.text);

      if (!("encoded" in encodedData)) {
        // legacy, un-encoded scene JSON
        if ("type" in encodedData && encodedData.type === EXPORT_DATA_TYPES.excalidraw) {
          return metadata.text;
        }

        throw new Error("FAILED");
      }

      return yield decode(encodedData);
    } catch (error) {
      console.error(error);
      throw new Error("FAILED");
    }
  }

  throw new Error("INVALID");
}); // -----------------------------------------------------------------------------
// SVG
// -----------------------------------------------------------------------------

export const encodeSvgMetadata = ({
  text
}) => __awaiter(void 0, void 0, void 0, function* () {
  const base64 = yield stringToBase64(JSON.stringify(yield encode({
    text
  })), true
  /* is already byte string */
  );
  let metadata = "";
  metadata += `<!-- payload-type:${MIME_TYPES.excalidraw} -->`;
  metadata += `<!-- payload-version:2 -->`;
  metadata += "<!-- payload-start -->";
  metadata += base64;
  metadata += "<!-- payload-end -->";
  return metadata;
});
export const decodeSvgMetadata = ({
  svg
}) => __awaiter(void 0, void 0, void 0, function* () {
  if (svg.includes(`payload-type:${MIME_TYPES.excalidraw}`)) {
    const match = svg.match(/<!-- payload-start -->\s*(.+?)\s*<!-- payload-end -->/);

    if (!match) {
      throw new Error("INVALID");
    }

    const versionMatch = svg.match(/<!-- payload-version:(\d+) -->/);
    const version = (versionMatch === null || versionMatch === void 0 ? void 0 : versionMatch[1]) || "1";
    const isByteString = version !== "1";

    try {
      const json = yield base64ToString(match[1], isByteString);
      const encodedData = JSON.parse(json);

      if (!("encoded" in encodedData)) {
        // legacy, un-encoded scene JSON
        if ("type" in encodedData && encodedData.type === EXPORT_DATA_TYPES.excalidraw) {
          return json;
        }

        throw new Error("FAILED");
      }

      return yield decode(encodedData);
    } catch (error) {
      console.error(error);
      throw new Error("FAILED");
    }
  }

  throw new Error("INVALID");
});