import { useEffect } from "react";
import { atom, useAtom } from "jotai";
import throttle from "lodash.throttle";
const scrollPositionAtom = atom(0);
export const useScrollPosition = elementRef => {
  const [scrollPosition, setScrollPosition] = useAtom(scrollPositionAtom);
  useEffect(() => {
    const {
      current: element
    } = elementRef;

    if (!element) {
      return;
    }

    const handleScroll = throttle(() => {
      const {
        scrollTop
      } = element;
      setScrollPosition(scrollTop);
    }, 200);
    element.addEventListener("scroll", handleScroll);
    return () => {
      handleScroll.cancel();
      element.removeEventListener("scroll", handleScroll);
    };
  }, [elementRef, setScrollPosition]);
  return scrollPosition;
};