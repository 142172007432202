import { register } from "./register";
import { deepCopyElement } from "../element/newElement";
import { randomId } from "../random";
import { t } from "../i18n";
import { LIBRARY_DISABLED_TYPES } from "../constants";
export const actionAddToLibrary = register({
  name: "addToLibrary",
  trackEvent: {
    category: "element"
  },
  perform: (elements, appState, _, app) => {
    const selectedElements = app.scene.getSelectedElements({
      selectedElementIds: appState.selectedElementIds,
      includeBoundTextElement: true,
      includeElementsInFrames: true
    });

    for (const type of LIBRARY_DISABLED_TYPES) {
      if (selectedElements.some(element => element.type === type)) {
        return {
          commitToHistory: false,
          appState: Object.assign(Object.assign({}, appState), {
            errorMessage: t(`errors.libraryElementTypeError.${type}`)
          })
        };
      }
    }

    return app.library.getLatestLibrary().then(items => {
      return app.library.setLibrary([{
        id: randomId(),
        status: "unpublished",
        elements: selectedElements.map(deepCopyElement),
        created: Date.now()
      }, ...items]);
    }).then(() => {
      return {
        commitToHistory: false,
        appState: Object.assign(Object.assign({}, appState), {
          toast: {
            message: t("toast.addedToLibrary")
          }
        })
      };
    }).catch(error => {
      return {
        commitToHistory: false,
        appState: Object.assign(Object.assign({}, appState), {
          errorMessage: error.message
        })
      };
    });
  },
  contextItemLabel: "labels.addToLibrary"
});