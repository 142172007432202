var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { LoadingMessage } from "./LoadingMessage";
import { defaultLang, languages, setLanguage } from "../i18n";
export const InitializeApp = props => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const updateLang = () => __awaiter(void 0, void 0, void 0, function* () {
      yield setLanguage(currentLang);
      setLoading(false);
    });

    const currentLang = languages.find(lang => lang.code === props.langCode) || defaultLang;
    updateLang();
  }, [props.langCode]);
  return loading ? _jsx(LoadingMessage, {
    theme: props.theme
  }) : props.children;
};