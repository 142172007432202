import { jsx as _jsx } from "react/jsx-runtime";
import "./UserList.scss";
import React from "react";
import clsx from "clsx";
import { Tooltip } from "./Tooltip";
import { useExcalidrawActionManager } from "./App";
export const UserList = ({
  className,
  mobile,
  collaborators
}) => {
  const actionManager = useExcalidrawActionManager();
  const uniqueCollaborators = new Map();
  collaborators.forEach((collaborator, socketId) => {
    uniqueCollaborators.set( // filter on user id, else fall back on unique socketId
    collaborator.id || socketId, collaborator);
  });
  const avatars = uniqueCollaborators.size > 0 && Array.from(uniqueCollaborators).filter(([_, client]) => Object.keys(client).length !== 0).map(([clientId, collaborator]) => {
    const avatarJSX = actionManager.renderAction("goToCollaborator", [clientId, collaborator]);
    return mobile ? _jsx(Tooltip, Object.assign({
      label: collaborator.username || "Unknown user"
    }, {
      children: avatarJSX
    }), clientId) : _jsx(React.Fragment, {
      children: avatarJSX
    }, clientId);
  });
  return _jsx("div", Object.assign({
    className: clsx("UserList", className, {
      UserList_mobile: mobile
    })
  }, {
    children: avatars
  }));
};