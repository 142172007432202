import { KEYS } from "../keys";
import { register } from "./register";
import { selectGroupsForSelectedElements } from "../groups";
import { getNonDeletedElements, isTextElement } from "../element";
import { isLinearElement } from "../element/typeChecks";
import { LinearElementEditor } from "../element/linearElementEditor";
import { excludeElementsInFramesFromSelection } from "../scene/selection";
export const actionSelectAll = register({
  name: "selectAll",
  trackEvent: {
    category: "canvas"
  },
  perform: (elements, appState, value, app) => {
    if (appState.editingLinearElement) {
      return false;
    }

    const selectedElementIds = excludeElementsInFramesFromSelection(elements.filter(element => !element.isDeleted && !(isTextElement(element) && element.containerId) && !element.locked)).reduce((map, element) => {
      map[element.id] = true;
      return map;
    }, {});
    return {
      appState: Object.assign(Object.assign(Object.assign({}, appState), selectGroupsForSelectedElements({
        editingGroupId: null,
        selectedElementIds
      }, getNonDeletedElements(elements), appState, app)), {
        selectedLinearElement: // single linear element selected
        Object.keys(selectedElementIds).length === 1 && isLinearElement(elements[0]) ? new LinearElementEditor(elements[0], app.scene) : null
      }),
      commitToHistory: true
    };
  },
  contextItemLabel: "labels.selectAll",
  keyTest: event => event[KEYS.CTRL_OR_CMD] && event.key === KEYS.A
});