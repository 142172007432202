var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import { jsx as _jsx } from "react/jsx-runtime";
import clsx from "clsx";
import { composeEventHandlers } from "../utils";
import "./Button.scss";
/**
 * A generic button component that follows Excalidraw's design system.
 * Style can be customised using `className` or `style` prop.
 * Accepts all props that a regular `button` element accepts.
 */

export const Button = _a => {
  var {
    type = "button",
    onSelect,
    selected,
    children,
    className = ""
  } = _a,
      rest = __rest(_a, ["type", "onSelect", "selected", "children", "className"]);

  return _jsx("button", Object.assign({
    onClick: composeEventHandlers(rest.onClick, event => {
      onSelect();
    }),
    type: type,
    className: clsx("excalidraw-button", className, {
      selected
    })
  }, rest, {
    children: children
  }));
};