// -----------------------------------------------------------------------------
// ExcalidrawImageElement & related helpers
// -----------------------------------------------------------------------------
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

import { MIME_TYPES, SVG_NS } from "../constants";
import { t } from "../i18n";
import { isInitializedImageElement } from "./typeChecks";
export const loadHTMLImageElement = dataURL => {
  return new Promise((resolve, reject) => {
    const image = new Image();

    image.onload = () => {
      resolve(image);
    };

    image.onerror = error => {
      reject(error);
    };

    image.src = dataURL;
  });
};
/** NOTE: updates cache even if already populated with given image. Thus,
 * you should filter out the images upstream if you want to optimize this. */

export const updateImageCache = ({
  fileIds,
  files,
  imageCache
}) => __awaiter(void 0, void 0, void 0, function* () {
  const updatedFiles = new Map();
  const erroredFiles = new Map();
  yield Promise.all(fileIds.reduce((promises, fileId) => {
    const fileData = files[fileId];

    if (fileData && !updatedFiles.has(fileId)) {
      updatedFiles.set(fileId, true);
      return promises.concat((() => __awaiter(void 0, void 0, void 0, function* () {
        try {
          if (fileData.mimeType === MIME_TYPES.binary) {
            throw new Error("Only images can be added to ImageCache");
          }

          const imagePromise = loadHTMLImageElement(fileData.dataURL);
          const data = {
            image: imagePromise,
            mimeType: fileData.mimeType
          }; // store the promise immediately to indicate there's an in-progress
          // initialization

          imageCache.set(fileId, data);
          const image = yield imagePromise;
          imageCache.set(fileId, Object.assign(Object.assign({}, data), {
            image
          }));
        } catch (error) {
          erroredFiles.set(fileId, true);
        }
      }))());
    }

    return promises;
  }, []));
  return {
    imageCache,

    /** includes errored files because they cache was updated nonetheless */
    updatedFiles,

    /** files that failed when creating HTMLImageElement */
    erroredFiles
  };
});
export const getInitializedImageElements = elements => elements.filter(element => isInitializedImageElement(element));
export const isHTMLSVGElement = node => {
  // lower-casing due to XML/HTML convention differences
  // https://johnresig.com/blog/nodename-case-sensitivity
  return (node === null || node === void 0 ? void 0 : node.nodeName.toLowerCase()) === "svg";
};
export const normalizeSVG = SVGString => __awaiter(void 0, void 0, void 0, function* () {
  const doc = new DOMParser().parseFromString(SVGString, MIME_TYPES.svg);
  const svg = doc.querySelector("svg");
  const errorNode = doc.querySelector("parsererror");

  if (errorNode || !isHTMLSVGElement(svg)) {
    throw new Error(t("errors.invalidSVGString"));
  } else {
    if (!svg.hasAttribute("xmlns")) {
      svg.setAttribute("xmlns", SVG_NS);
    }

    if (!svg.hasAttribute("width") || !svg.hasAttribute("height")) {
      const viewBox = svg.getAttribute("viewBox");
      let width = svg.getAttribute("width") || "50";
      let height = svg.getAttribute("height") || "50";

      if (viewBox) {
        const match = viewBox.match(/\d+ +\d+ +(\d+) +(\d+)/);

        if (match) {
          [, width, height] = match;
        }
      }

      svg.setAttribute("width", width);
      svg.setAttribute("height", height);
    }

    return svg.outerHTML;
  }
});