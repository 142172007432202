var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

import { atom, useAtom } from "jotai";
import { useEffect, useState } from "react";
import { COLOR_PALETTE } from "../colors";
import { jotaiScope } from "../jotai";
import { exportToSvg } from "../packages/utils";
export const libraryItemSvgsCache = atom(new Map());

const exportLibraryItemToSvg = elements => __awaiter(void 0, void 0, void 0, function* () {
  return yield exportToSvg({
    elements,
    appState: {
      exportBackground: false,
      viewBackgroundColor: COLOR_PALETTE.white
    },
    files: null,
    renderEmbeddables: false
  });
});

export const useLibraryItemSvg = (id, elements, svgCache) => {
  const [svg, setSvg] = useState();
  useEffect(() => {
    if (elements) {
      if (id) {
        // Try to load cached svg
        const cachedSvg = svgCache.get(id);

        if (cachedSvg) {
          setSvg(cachedSvg);
        } else {
          // When there is no svg in cache export it and save to cache
          (() => __awaiter(void 0, void 0, void 0, function* () {
            var _a;

            const exportedSvg = yield exportLibraryItemToSvg(elements);
            (_a = exportedSvg.querySelector(".style-fonts")) === null || _a === void 0 ? void 0 : _a.remove();

            if (exportedSvg) {
              svgCache.set(id, exportedSvg);
              setSvg(exportedSvg);
            }
          }))();
        }
      } else {
        // When we have no id (usualy selected items from canvas) just export the svg
        (() => __awaiter(void 0, void 0, void 0, function* () {
          const exportedSvg = yield exportLibraryItemToSvg(elements);
          setSvg(exportedSvg);
        }))();
      }
    }
  }, [id, elements, svgCache, setSvg]);
  return svg;
};
export const useLibraryCache = () => {
  const [svgCache] = useAtom(libraryItemSvgsCache, jotaiScope);

  const clearLibraryCache = () => svgCache.clear();

  const deleteItemsFromLibraryCache = items => {
    items.forEach(item => svgCache.delete(item));
  };

  return {
    clearLibraryCache,
    deleteItemsFromLibraryCache,
    svgCache
  };
};