import { updateBoundElements } from "./binding";
import { getCommonBounds } from "./bounds";
import { mutateElement } from "./mutateElement";
import { getPerfectElementSize } from "./sizeHelpers";
import { getBoundTextElement } from "./textElement";
import { isSelectedViaGroup } from "../groups";
import { getGridPoint } from "../math";
import { isArrowElement, isBoundToContainer, isFrameElement } from "./typeChecks";
export const dragSelectedElements = (pointerDownState, selectedElements, offset, appState, scene, snapOffset, gridSize) => {
  // we do not want a frame and its elements to be selected at the same time
  // but when it happens (due to some bug), we want to avoid updating element
  // in the frame twice, hence the use of set
  const elementsToUpdate = new Set(selectedElements);
  const frames = selectedElements.filter(e => isFrameElement(e)).map(f => f.id);

  if (frames.length > 0) {
    const elementsInFrames = scene.getNonDeletedElements().filter(e => !isBoundToContainer(e)).filter(e => e.frameId !== null).filter(e => frames.includes(e.frameId));
    elementsInFrames.forEach(element => elementsToUpdate.add(element));
  }

  const commonBounds = getCommonBounds(Array.from(elementsToUpdate).map(el => {
    var _a;

    return (_a = pointerDownState.originalElements.get(el.id)) !== null && _a !== void 0 ? _a : el;
  }));
  const adjustedOffset = calculateOffset(commonBounds, offset, snapOffset, gridSize);
  elementsToUpdate.forEach(element => {
    updateElementCoords(pointerDownState, element, adjustedOffset); // update coords of bound text only if we're dragging the container directly
    // (we don't drag the group that it's part of)

    if ( // Don't update coords of arrow label since we calculate its position during render
    !isArrowElement(element) && ( // container isn't part of any group
    // (perf optim so we don't check `isSelectedViaGroup()` in every case)
    !element.groupIds.length || // container is part of a group, but we're dragging the container directly
    appState.editingGroupId && !isSelectedViaGroup(appState, element))) {
      const textElement = getBoundTextElement(element);

      if (textElement) {
        updateElementCoords(pointerDownState, textElement, adjustedOffset);
      }
    }

    updateBoundElements(element, {
      simultaneouslyUpdated: Array.from(elementsToUpdate)
    });
  });
};

const calculateOffset = (commonBounds, dragOffset, snapOffset, gridSize) => {
  const [x, y] = commonBounds;
  let nextX = x + dragOffset.x + snapOffset.x;
  let nextY = y + dragOffset.y + snapOffset.y;

  if (snapOffset.x === 0 || snapOffset.y === 0) {
    const [nextGridX, nextGridY] = getGridPoint(x + dragOffset.x, y + dragOffset.y, gridSize);

    if (snapOffset.x === 0) {
      nextX = nextGridX;
    }

    if (snapOffset.y === 0) {
      nextY = nextGridY;
    }
  }

  return {
    x: nextX - x,
    y: nextY - y
  };
};

const updateElementCoords = (pointerDownState, element, dragOffset) => {
  var _a;

  const originalElement = (_a = pointerDownState.originalElements.get(element.id)) !== null && _a !== void 0 ? _a : element;
  const nextX = originalElement.x + dragOffset.x;
  const nextY = originalElement.y + dragOffset.y;
  mutateElement(element, {
    x: nextX,
    y: nextY
  });
};

export const getDragOffsetXY = (selectedElements, x, y) => {
  const [x1, y1] = getCommonBounds(selectedElements);
  return [x - x1, y - y1];
};
export const dragNewElement = (draggingElement, elementType, originX, originY, x, y, width, height, shouldMaintainAspectRatio, shouldResizeFromCenter,
/** whether to keep given aspect ratio when `isResizeWithSidesSameLength` is
    true */
widthAspectRatio, originOffset = null) => {
  var _a, _b;

  if (shouldMaintainAspectRatio && draggingElement.type !== "selection") {
    if (widthAspectRatio) {
      height = width / widthAspectRatio;
    } else {
      // Depending on where the cursor is at (x, y) relative to where the starting point is
      // (originX, originY), we use ONLY width or height to control size increase.
      // This allows the cursor to always "stick" to one of the sides of the bounding box.
      if (Math.abs(y - originY) > Math.abs(x - originX)) {
        ({
          width,
          height
        } = getPerfectElementSize(elementType, height, x < originX ? -width : width));
      } else {
        ({
          width,
          height
        } = getPerfectElementSize(elementType, width, y < originY ? -height : height));
      }

      if (height < 0) {
        height = -height;
      }
    }
  }

  let newX = x < originX ? originX - width : originX;
  let newY = y < originY ? originY - height : originY;

  if (shouldResizeFromCenter) {
    width += width;
    height += height;
    newX = originX - width / 2;
    newY = originY - height / 2;
  }

  if (width !== 0 && height !== 0) {
    mutateElement(draggingElement, {
      x: newX + ((_a = originOffset === null || originOffset === void 0 ? void 0 : originOffset.x) !== null && _a !== void 0 ? _a : 0),
      y: newY + ((_b = originOffset === null || originOffset === void 0 ? void 0 : originOffset.y) !== null && _b !== void 0 ? _b : 0),
      width,
      height
    });
  }
};