import { getNonDeletedElements } from "../element";
import { removeAllElementsFromFrame } from "../frame";
import { getFrameChildren } from "../frame";
import { KEYS } from "../keys";
import { updateActiveTool } from "../utils";
import { setCursorForShape } from "../cursor";
import { register } from "./register";

const isSingleFrameSelected = (appState, app) => {
  const selectedElements = app.scene.getSelectedElements(appState);
  return selectedElements.length === 1 && selectedElements[0].type === "frame";
};

export const actionSelectAllElementsInFrame = register({
  name: "selectAllElementsInFrame",
  trackEvent: {
    category: "canvas"
  },
  perform: (elements, appState, _, app) => {
    const selectedFrame = app.scene.getSelectedElements(appState)[0];

    if (selectedFrame && selectedFrame.type === "frame") {
      const elementsInFrame = getFrameChildren(getNonDeletedElements(elements), selectedFrame.id).filter(element => !(element.type === "text" && element.containerId));
      return {
        elements,
        appState: Object.assign(Object.assign({}, appState), {
          selectedElementIds: elementsInFrame.reduce((acc, element) => {
            acc[element.id] = true;
            return acc;
          }, {})
        }),
        commitToHistory: false
      };
    }

    return {
      elements,
      appState,
      commitToHistory: false
    };
  },
  contextItemLabel: "labels.selectAllElementsInFrame",
  predicate: (elements, appState, _, app) => isSingleFrameSelected(appState, app)
});
export const actionRemoveAllElementsFromFrame = register({
  name: "removeAllElementsFromFrame",
  trackEvent: {
    category: "history"
  },
  perform: (elements, appState, _, app) => {
    const selectedFrame = app.scene.getSelectedElements(appState)[0];

    if (selectedFrame && selectedFrame.type === "frame") {
      return {
        elements: removeAllElementsFromFrame(elements, selectedFrame, appState),
        appState: Object.assign(Object.assign({}, appState), {
          selectedElementIds: {
            [selectedFrame.id]: true
          }
        }),
        commitToHistory: true
      };
    }

    return {
      elements,
      appState,
      commitToHistory: false
    };
  },
  contextItemLabel: "labels.removeAllElementsFromFrame",
  predicate: (elements, appState, _, app) => isSingleFrameSelected(appState, app)
});
export const actionupdateFrameRendering = register({
  name: "updateFrameRendering",
  viewMode: true,
  trackEvent: {
    category: "canvas"
  },
  perform: (elements, appState) => {
    return {
      elements,
      appState: Object.assign(Object.assign({}, appState), {
        frameRendering: Object.assign(Object.assign({}, appState.frameRendering), {
          enabled: !appState.frameRendering.enabled
        })
      }),
      commitToHistory: false
    };
  },
  contextItemLabel: "labels.updateFrameRendering",
  checked: appState => appState.frameRendering.enabled
});
export const actionSetFrameAsActiveTool = register({
  name: "setFrameAsActiveTool",
  trackEvent: {
    category: "toolbar"
  },
  perform: (elements, appState, _, app) => {
    const nextActiveTool = updateActiveTool(appState, {
      type: "frame"
    });
    setCursorForShape(app.interactiveCanvas, Object.assign(Object.assign({}, appState), {
      activeTool: nextActiveTool
    }));
    return {
      elements,
      appState: Object.assign(Object.assign({}, appState), {
        activeTool: updateActiveTool(appState, {
          type: "frame"
        })
      }),
      commitToHistory: false
    };
  },
  keyTest: event => !event[KEYS.CTRL_OR_CMD] && !event.shiftKey && !event.altKey && event.key.toLocaleLowerCase() === KEYS.F
});