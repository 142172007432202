var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

import { fileOpen, fileSave } from "./filesystem";
import { cleanAppStateForExport, clearAppStateForDatabase } from "../appState";
import { EXPORT_DATA_TYPES, EXPORT_SOURCE, MIME_TYPES, VERSIONS } from "../constants";
import { clearElementsForDatabase, clearElementsForExport } from "../element";
import { isImageFileHandle, loadFromBlob, normalizeFile } from "./blob";
/**
 * Strips out files which are only referenced by deleted elements
 */

const filterOutDeletedFiles = (elements, files) => {
  const nextFiles = {};

  for (const element of elements) {
    if (!element.isDeleted && "fileId" in element && element.fileId && files[element.fileId]) {
      nextFiles[element.fileId] = files[element.fileId];
    }
  }

  return nextFiles;
};

export const serializeAsJSON = (elements, appState, files, type) => {
  const data = {
    type: EXPORT_DATA_TYPES.excalidraw,
    version: VERSIONS.excalidraw,
    source: EXPORT_SOURCE,
    elements: type === "local" ? clearElementsForExport(elements) : clearElementsForDatabase(elements),
    appState: type === "local" ? cleanAppStateForExport(appState) : clearAppStateForDatabase(appState),
    files: type === "local" ? filterOutDeletedFiles(elements, files) : // will be stripped from JSON
    undefined
  };
  return JSON.stringify(data, null, 2);
};
export const saveAsJSON = (elements, appState, files) => __awaiter(void 0, void 0, void 0, function* () {
  const serialized = serializeAsJSON(elements, appState, files, "local");
  const blob = new Blob([serialized], {
    type: MIME_TYPES.excalidraw
  });
  const fileHandle = yield fileSave(blob, {
    name: appState.name,
    extension: "excalidraw",
    description: "Excalidraw file",
    fileHandle: isImageFileHandle(appState.fileHandle) ? null : appState.fileHandle
  });
  return {
    fileHandle
  };
});
export const loadFromJSON = (localAppState, localElements) => __awaiter(void 0, void 0, void 0, function* () {
  const file = yield fileOpen({
    description: "Excalidraw files" // ToDo: Be over-permissive until https://bugs.webkit.org/show_bug.cgi?id=34442
    // gets resolved. Else, iOS users cannot open `.excalidraw` files.
    // extensions: ["json", "excalidraw", "png", "svg"],

  });
  return loadFromBlob(yield normalizeFile(file), localAppState, localElements, file.handle);
});
export const isValidExcalidrawData = data => {
  return (data === null || data === void 0 ? void 0 : data.type) === EXPORT_DATA_TYPES.excalidraw && (!data.elements || Array.isArray(data.elements) && (!data.appState || typeof data.appState === "object"));
};
export const isValidLibrary = json => {
  return typeof json === "object" && json && json.type === EXPORT_DATA_TYPES.excalidrawLibrary && (json.version === 1 || json.version === 2);
};
export const serializeLibraryAsJSON = libraryItems => {
  const data = {
    type: EXPORT_DATA_TYPES.excalidrawLibrary,
    version: VERSIONS.excalidrawLibrary,
    source: EXPORT_SOURCE,
    libraryItems
  };
  return JSON.stringify(data, null, 2);
};
export const saveLibraryAsJSON = libraryItems => __awaiter(void 0, void 0, void 0, function* () {
  const serialized = serializeLibraryAsJSON(libraryItems);
  yield fileSave(new Blob([serialized], {
    type: MIME_TYPES.excalidrawlib
  }), {
    name: "library",
    extension: "excalidrawlib",
    description: "Excalidraw library file"
  });
});