import { newElementWith } from "./element/mutateElement";
import { getCommonBoundingBox } from "./element/bounds";
import { getMaximumGroups } from "./groups";
export const alignElements = (selectedElements, alignment) => {
  const groups = getMaximumGroups(selectedElements);
  const selectionBoundingBox = getCommonBoundingBox(selectedElements);
  return groups.flatMap(group => {
    const translation = calculateTranslation(group, selectionBoundingBox, alignment);
    return group.map(element => newElementWith(element, {
      x: element.x + translation.x,
      y: element.y + translation.y
    }));
  });
};

const calculateTranslation = (group, selectionBoundingBox, {
  axis,
  position
}) => {
  const groupBoundingBox = getCommonBoundingBox(group);
  const [min, max] = axis === "x" ? ["minX", "maxX"] : ["minY", "maxY"];
  const noTranslation = {
    x: 0,
    y: 0
  };

  if (position === "start") {
    return Object.assign(Object.assign({}, noTranslation), {
      [axis]: selectionBoundingBox[min] - groupBoundingBox[min]
    });
  } else if (position === "end") {
    return Object.assign(Object.assign({}, noTranslation), {
      [axis]: selectionBoundingBox[max] - groupBoundingBox[max]
    });
  } // else if (position === "center") {


  return Object.assign(Object.assign({}, noTranslation), {
    [axis]: (selectionBoundingBox[min] + selectionBoundingBox[max]) / 2 - (groupBoundingBox[min] + groupBoundingBox[max]) / 2
  });
};