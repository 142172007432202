var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useRef, useEffect, useDeferredValue } from "react";
import { useApp } from "./App";
import { Button } from "./Button";
import { Dialog } from "./Dialog";
import { DEFAULT_EXPORT_PADDING, DEFAULT_FONT_SIZE } from "../constants";
import { convertToExcalidrawElements, exportToCanvas } from "../packages/excalidraw/index";
import { canvasToBlob } from "../data/blob";
import { ArrowRightIcon } from "./icons";
import Spinner from "./Spinner";
import "./MermaidToExcalidraw.scss";
import { t } from "../i18n";
import Trans from "./Trans";
const LOCAL_STORAGE_KEY_MERMAID_TO_EXCALIDRAW = "mermaid-to-excalidraw";
const MERMAID_EXAMPLE = "flowchart TD\n A[Christmas] -->|Get money| B(Go shopping)\n B --> C{Let me think}\n C -->|One| D[Laptop]\n C -->|Two| E[iPhone]\n C -->|Three| F[Car]";

const saveMermaidDataToStorage = data => {
  try {
    localStorage.setItem(LOCAL_STORAGE_KEY_MERMAID_TO_EXCALIDRAW, data);
  } catch (error) {
    // Unable to access window.localStorage
    console.error(error);
  }
};

const importMermaidDataFromStorage = () => {
  try {
    const data = localStorage.getItem(LOCAL_STORAGE_KEY_MERMAID_TO_EXCALIDRAW);

    if (data) {
      return data;
    }
  } catch (error) {
    // Unable to access localStorage
    console.error(error);
  }

  return null;
};

const ErrorComp = ({
  error
}) => {
  return _jsxs("div", Object.assign({
    "data-testid": "mermaid-error",
    className: "mermaid-error"
  }, {
    children: ["Error! ", _jsx("p", {
      children: error
    })]
  }));
};

const MermaidToExcalidraw = () => {
  const [mermaidToExcalidrawLib, setMermaidToExcalidrawLib] = useState({
    loaded: false,
    api: null
  });
  const [text, setText] = useState("");
  const deferredText = useDeferredValue(text.trim());
  const [error, setError] = useState(null);
  const canvasRef = useRef(null);
  const data = useRef({
    elements: [],
    files: null
  });
  const app = useApp();

  const resetPreview = () => {
    const canvasNode = canvasRef.current;

    if (!canvasNode) {
      return;
    }

    const parent = canvasNode.parentElement;

    if (!parent) {
      return;
    }

    parent.style.background = "";
    setError(null);
    canvasNode.replaceChildren();
  };

  useEffect(() => {
    const loadMermaidToExcalidrawLib = () => __awaiter(void 0, void 0, void 0, function* () {
      const api = yield import(
      /* webpackChunkName:"mermaid-to-excalidraw" */
      "@excalidraw/mermaid-to-excalidraw");
      setMermaidToExcalidrawLib({
        loaded: true,
        api
      });
    });

    loadMermaidToExcalidrawLib();
  }, []);
  useEffect(() => {
    const data = importMermaidDataFromStorage() || MERMAID_EXAMPLE;
    setText(data);
  }, []);
  useEffect(() => {
    const renderExcalidrawPreview = () => __awaiter(void 0, void 0, void 0, function* () {
      const canvasNode = canvasRef.current;
      const parent = canvasNode === null || canvasNode === void 0 ? void 0 : canvasNode.parentElement;

      if (!mermaidToExcalidrawLib.loaded || !canvasNode || !parent || !mermaidToExcalidrawLib.api) {
        return;
      }

      if (!deferredText) {
        resetPreview();
        return;
      }

      try {
        const {
          elements,
          files
        } = yield mermaidToExcalidrawLib.api.parseMermaidToExcalidraw(deferredText, {
          fontSize: DEFAULT_FONT_SIZE
        });
        setError(null);
        data.current = {
          elements: convertToExcalidrawElements(elements, {
            regenerateIds: true
          }),
          files
        };
        const canvas = yield exportToCanvas({
          elements: data.current.elements,
          files: data.current.files,
          exportPadding: DEFAULT_EXPORT_PADDING,
          maxWidthOrHeight: Math.max(parent.offsetWidth, parent.offsetHeight) * window.devicePixelRatio
        }); // if converting to blob fails, there's some problem that will
        // likely prevent preview and export (e.g. canvas too big)

        yield canvasToBlob(canvas);
        parent.style.background = "var(--default-bg-color)";
        canvasNode.replaceChildren(canvas);
      } catch (e) {
        parent.style.background = "var(--default-bg-color)";

        if (deferredText) {
          setError(e.message);
        }
      }
    });

    renderExcalidrawPreview();
  }, [deferredText, mermaidToExcalidrawLib]);

  const onClose = () => {
    app.setOpenDialog(null);
    saveMermaidDataToStorage(text);
  };

  const onSelect = () => {
    const {
      elements: newElements,
      files
    } = data.current;
    app.addElementsFromPasteOrLibrary({
      elements: newElements,
      files,
      position: "center",
      fitToContent: true
    });
    onClose();
  };

  return _jsx(Dialog, Object.assign({
    className: "dialog-mermaid",
    onCloseRequest: onClose,
    size: 1200,
    title: _jsxs(_Fragment, {
      children: [_jsx("p", Object.assign({
        className: "dialog-mermaid-title"
      }, {
        children: t("mermaid.title")
      })), _jsxs("span", Object.assign({
        className: "dialog-mermaid-desc"
      }, {
        children: [_jsx(Trans, {
          i18nKey: "mermaid.description",
          flowchartLink: el => _jsx("a", Object.assign({
            href: "https://mermaid.js.org/syntax/flowchart.html"
          }, {
            children: el
          })),
          sequenceLink: el => _jsx("a", Object.assign({
            href: "https://mermaid.js.org/syntax/sequenceDiagram.html"
          }, {
            children: el
          }))
        }), _jsx("br", {})]
      }))]
    })
  }, {
    children: _jsxs("div", Object.assign({
      className: "dialog-mermaid-body"
    }, {
      children: [_jsxs("div", Object.assign({
        className: "dialog-mermaid-panels"
      }, {
        children: [_jsxs("div", Object.assign({
          className: "dialog-mermaid-panels-text"
        }, {
          children: [_jsx("label", {
            children: t("mermaid.syntax")
          }), _jsx("textarea", {
            onChange: event => setText(event.target.value),
            value: text
          })]
        })), _jsxs("div", Object.assign({
          className: "dialog-mermaid-panels-preview"
        }, {
          children: [_jsx("label", {
            children: t("mermaid.preview")
          }), _jsxs("div", Object.assign({
            className: "dialog-mermaid-panels-preview-wrapper"
          }, {
            children: [error && _jsx(ErrorComp, {
              error: error
            }), mermaidToExcalidrawLib.loaded ? _jsx("div", {
              ref: canvasRef,
              style: {
                opacity: error ? "0.15" : 1
              },
              className: "dialog-mermaid-panels-preview-canvas-container"
            }) : _jsx(Spinner, {
              size: "2rem"
            })]
          }))]
        }))]
      })), _jsx("div", Object.assign({
        className: "dialog-mermaid-buttons"
      }, {
        children: _jsxs(Button, Object.assign({
          className: "dialog-mermaid-insert",
          onSelect: onSelect
        }, {
          children: [t("mermaid.button"), _jsx("span", {
            children: ArrowRightIcon
          })]
        }))
      }))]
    }))
  }));
};

export default MermaidToExcalidraw;