var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from "clsx";
import "./DialogActionButton.scss";
import Spinner from "./Spinner";

const DialogActionButton = _a => {
  var {
    label,
    onClick,
    className,
    children,
    actionType,
    type = "button",
    isLoading
  } = _a,
      rest = __rest(_a, ["label", "onClick", "className", "children", "actionType", "type", "isLoading"]);

  const cs = actionType ? `Dialog__action-button--${actionType}` : "";
  return _jsxs("button", Object.assign({
    className: clsx("Dialog__action-button", cs, className),
    type: type,
    "aria-label": label,
    onClick: onClick
  }, rest, {
    children: [children && _jsx("div", Object.assign({
      style: isLoading ? {
        visibility: "hidden"
      } : {}
    }, {
      children: children
    })), _jsx("div", Object.assign({
      style: isLoading ? {
        visibility: "hidden"
      } : {}
    }, {
      children: label
    })), isLoading && _jsx("div", Object.assign({
      style: {
        position: "absolute",
        inset: 0
      }
    }, {
      children: _jsx(Spinner, {})
    }))]
  }));
};

export default DialogActionButton;