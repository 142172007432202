var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

import { exportToCanvas as _exportToCanvas, exportToSvg as _exportToSvg } from "../scene/export";
import { getDefaultAppState } from "../appState";
import { restore } from "../data/restore";
import { MIME_TYPES } from "../constants";
import { encodePngMetadata } from "../data/image";
import { serializeAsJSON } from "../data/json";
import { copyBlobToClipboardAsPng, copyTextToSystemClipboard, copyToClipboard } from "../clipboard";
export { MIME_TYPES };
export const exportToCanvas = ({
  elements,
  appState,
  files,
  maxWidthOrHeight,
  getDimensions,
  exportPadding,
  exportingFrame
}) => {
  const {
    elements: restoredElements,
    appState: restoredAppState
  } = restore({
    elements,
    appState
  }, null, null);
  const {
    exportBackground,
    viewBackgroundColor
  } = restoredAppState;
  return _exportToCanvas(restoredElements, Object.assign(Object.assign({}, restoredAppState), {
    offsetTop: 0,
    offsetLeft: 0,
    width: 0,
    height: 0
  }), files || {}, {
    exportBackground,
    exportPadding,
    viewBackgroundColor,
    exportingFrame
  }, (width, height) => {
    var _a, _b;

    const canvas = document.createElement("canvas");

    if (maxWidthOrHeight) {
      if (typeof getDimensions === "function") {
        console.warn("`getDimensions()` is ignored when `maxWidthOrHeight` is supplied.");
      }

      const max = Math.max(width, height); // if content is less then maxWidthOrHeight, fallback on supplied scale

      const scale = maxWidthOrHeight < max ? maxWidthOrHeight / max : (_a = appState === null || appState === void 0 ? void 0 : appState.exportScale) !== null && _a !== void 0 ? _a : 1;
      canvas.width = width * scale;
      canvas.height = height * scale;
      return {
        canvas,
        scale
      };
    }

    const ret = (getDimensions === null || getDimensions === void 0 ? void 0 : getDimensions(width, height)) || {
      width,
      height
    };
    canvas.width = ret.width;
    canvas.height = ret.height;
    return {
      canvas,
      scale: (_b = ret.scale) !== null && _b !== void 0 ? _b : 1
    };
  });
};
export const exportToBlob = opts => __awaiter(void 0, void 0, void 0, function* () {
  var _a;

  let {
    mimeType = MIME_TYPES.png,
    quality
  } = opts;

  if (mimeType === MIME_TYPES.png && typeof quality === "number") {
    console.warn(`"quality" will be ignored for "${MIME_TYPES.png}" mimeType`);
  } // typo in MIME type (should be "jpeg")


  if (mimeType === "image/jpg") {
    mimeType = MIME_TYPES.jpg;
  }

  if (mimeType === MIME_TYPES.jpg && !((_a = opts.appState) === null || _a === void 0 ? void 0 : _a.exportBackground)) {
    console.warn(`Defaulting "exportBackground" to "true" for "${MIME_TYPES.jpg}" mimeType`);
    opts = Object.assign(Object.assign({}, opts), {
      appState: Object.assign(Object.assign({}, opts.appState), {
        exportBackground: true
      })
    });
  }

  const canvas = yield exportToCanvas(opts);
  quality = quality ? quality : /image\/jpe?g/.test(mimeType) ? 0.92 : 0.8;
  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => __awaiter(void 0, void 0, void 0, function* () {
      var _a;

      if (!blob) {
        return reject(new Error("couldn't export to blob"));
      }

      if (blob && mimeType === MIME_TYPES.png && ((_a = opts.appState) === null || _a === void 0 ? void 0 : _a.exportEmbedScene)) {
        blob = yield encodePngMetadata({
          blob,
          metadata: serializeAsJSON( // NOTE as long as we're using the Scene hack, we need to ensure
          // we pass the original, uncloned elements when serializing
          // so that we keep ids stable
          opts.elements, opts.appState, opts.files || {}, "local")
        });
      }

      resolve(blob);
    }), mimeType, quality);
  });
});
export const exportToSvg = ({
  elements,
  appState = getDefaultAppState(),
  files = {},
  exportPadding,
  renderEmbeddables,
  exportingFrame
}) => __awaiter(void 0, void 0, void 0, function* () {
  const {
    elements: restoredElements,
    appState: restoredAppState
  } = restore({
    elements,
    appState
  }, null, null);
  const exportAppState = Object.assign(Object.assign({}, restoredAppState), {
    exportPadding
  });
  return _exportToSvg(restoredElements, exportAppState, files, {
    exportingFrame,
    renderEmbeddables
  });
});
export const exportToClipboard = opts => __awaiter(void 0, void 0, void 0, function* () {
  if (opts.type === "svg") {
    const svg = yield exportToSvg(opts);
    yield copyTextToSystemClipboard(svg.outerHTML);
  } else if (opts.type === "png") {
    yield copyBlobToClipboardAsPng(exportToBlob(opts));
  } else if (opts.type === "json") {
    yield copyToClipboard(opts.elements, opts.files);
  } else {
    throw new Error("Invalid export type");
  }
});
export * from "./bbox";
export { elementsOverlappingBBox, isElementInsideBBox, elementPartiallyOverlapsWithOrContainsBBox } from "./withinBounds";
export { serializeAsJSON, serializeLibraryAsJSON } from "../data/json";
export { loadFromBlob, loadSceneOrLibraryFromBlob, loadLibraryFromBlob } from "../data/blob";
export { getFreeDrawSvgPath } from "../renderer/renderElement";
export { mergeLibraryItems } from "../data/library";