import { getNonDeletedElements, getNormalizedDimensions, isInvisiblySmallElement, refreshTextDimensions } from "../element";
import { isTextElement, isUsingAdaptiveRadius } from "../element/typeChecks";
import { randomId } from "../random";
import { DEFAULT_FONT_FAMILY, DEFAULT_TEXT_ALIGN, DEFAULT_VERTICAL_ALIGN, PRECEDING_ELEMENT_KEY, FONT_FAMILY, ROUNDNESS, DEFAULT_SIDEBAR, DEFAULT_ELEMENT_PROPS } from "../constants";
import { getDefaultAppState } from "../appState";
import { LinearElementEditor } from "../element/linearElementEditor";
import { bumpVersion } from "../element/mutateElement";
import { getFontString, getUpdatedTimestamp, updateActiveTool } from "../utils";
import { arrayToMap } from "../utils";
import { detectLineHeight, getDefaultLineHeight, measureBaseline } from "../element/textElement";
import { normalizeLink } from "./url";
export const AllowedExcalidrawActiveTools = {
  selection: true,
  text: true,
  rectangle: true,
  diamond: true,
  ellipse: true,
  line: true,
  image: true,
  arrow: true,
  freedraw: true,
  eraser: false,
  custom: true,
  frame: true,
  embeddable: true,
  hand: true,
  laser: false
};

const getFontFamilyByName = fontFamilyName => {
  if (Object.keys(FONT_FAMILY).includes(fontFamilyName)) {
    return FONT_FAMILY[fontFamilyName];
  }

  return DEFAULT_FONT_FAMILY;
};

const repairBinding = binding => {
  if (!binding) {
    return null;
  }

  return Object.assign(Object.assign({}, binding), {
    focus: binding.focus || 0
  });
};

const restoreElementWithProperties = (element, extra) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;

  const base = {
    type: extra.type || element.type,
    // all elements must have version > 0 so getSceneVersion() will pick up
    // newly added elements
    version: element.version || 1,
    versionNonce: (_a = element.versionNonce) !== null && _a !== void 0 ? _a : 0,
    isDeleted: (_b = element.isDeleted) !== null && _b !== void 0 ? _b : false,
    id: element.id || randomId(),
    fillStyle: element.fillStyle || DEFAULT_ELEMENT_PROPS.fillStyle,
    strokeWidth: element.strokeWidth || DEFAULT_ELEMENT_PROPS.strokeWidth,
    strokeStyle: (_c = element.strokeStyle) !== null && _c !== void 0 ? _c : DEFAULT_ELEMENT_PROPS.strokeStyle,
    roughness: (_d = element.roughness) !== null && _d !== void 0 ? _d : DEFAULT_ELEMENT_PROPS.roughness,
    opacity: element.opacity == null ? DEFAULT_ELEMENT_PROPS.opacity : element.opacity,
    angle: element.angle || 0,
    x: (_f = (_e = extra.x) !== null && _e !== void 0 ? _e : element.x) !== null && _f !== void 0 ? _f : 0,
    y: (_h = (_g = extra.y) !== null && _g !== void 0 ? _g : element.y) !== null && _h !== void 0 ? _h : 0,
    strokeColor: element.strokeColor || DEFAULT_ELEMENT_PROPS.strokeColor,
    backgroundColor: element.backgroundColor || DEFAULT_ELEMENT_PROPS.backgroundColor,
    width: element.width || 0,
    height: element.height || 0,
    seed: (_j = element.seed) !== null && _j !== void 0 ? _j : 1,
    groupIds: (_k = element.groupIds) !== null && _k !== void 0 ? _k : [],
    frameId: (_l = element.frameId) !== null && _l !== void 0 ? _l : null,
    roundness: element.roundness ? element.roundness : element.strokeSharpness === "round" ? {
      // for old elements that would now use adaptive radius algo,
      // use legacy algo instead
      type: isUsingAdaptiveRadius(element.type) ? ROUNDNESS.LEGACY : ROUNDNESS.PROPORTIONAL_RADIUS
    } : null,
    boundElements: element.boundElementIds ? element.boundElementIds.map(id => ({
      type: "arrow",
      id
    })) : (_m = element.boundElements) !== null && _m !== void 0 ? _m : [],
    updated: (_o = element.updated) !== null && _o !== void 0 ? _o : getUpdatedTimestamp(),
    link: element.link ? normalizeLink(element.link) : null,
    locked: (_p = element.locked) !== null && _p !== void 0 ? _p : false
  };

  if ("customData" in element) {
    base.customData = element.customData;
  }

  if (PRECEDING_ELEMENT_KEY in element) {
    base[PRECEDING_ELEMENT_KEY] = element[PRECEDING_ELEMENT_KEY];
  }

  return Object.assign(Object.assign(Object.assign({}, base), getNormalizedDimensions(base)), extra);
};

const restoreElement = (element, refreshDimensions = false) => {
  var _a, _b;

  switch (element.type) {
    case "text":
      let fontSize = element.fontSize;
      let fontFamily = element.fontFamily;

      if ("font" in element) {
        const [fontPx, _fontFamily] = element.font.split(" ");
        fontSize = parseFloat(fontPx);
        fontFamily = getFontFamilyByName(_fontFamily);
      }

      const text = typeof element.text === "string" && element.text || ""; // line-height might not be specified either when creating elements
      // programmatically, or when importing old diagrams.
      // For the latter we want to detect the original line height which
      // will likely differ from our per-font fixed line height we now use,
      // to maintain backward compatibility.

      const lineHeight = element.lineHeight || (element.height ? // detect line-height from current element height and font-size
      detectLineHeight(element) : // no element height likely means programmatic use, so default
      // to a fixed line height
      getDefaultLineHeight(element.fontFamily));
      const baseline = measureBaseline(element.text, getFontString(element), lineHeight);
      element = restoreElementWithProperties(element, {
        fontSize,
        fontFamily,
        text,
        textAlign: element.textAlign || DEFAULT_TEXT_ALIGN,
        verticalAlign: element.verticalAlign || DEFAULT_VERTICAL_ALIGN,
        containerId: (_a = element.containerId) !== null && _a !== void 0 ? _a : null,
        originalText: element.originalText || text,
        lineHeight,
        baseline
      }); // if empty text, mark as deleted. We keep in array
      // for data integrity purposes (collab etc.)

      if (!text && !element.isDeleted) {
        element = Object.assign(Object.assign({}, element), {
          originalText: text,
          isDeleted: true
        });
        element = bumpVersion(element);
      }

      if (refreshDimensions) {
        element = Object.assign(Object.assign({}, element), refreshTextDimensions(element));
      }

      return element;

    case "freedraw":
      {
        return restoreElementWithProperties(element, {
          points: element.points,
          lastCommittedPoint: null,
          simulatePressure: element.simulatePressure,
          pressures: element.pressures
        });
      }

    case "image":
      return restoreElementWithProperties(element, {
        status: element.status || "pending",
        fileId: element.fileId,
        scale: element.scale || [1, 1]
      });

    case "line": // @ts-ignore LEGACY type
    // eslint-disable-next-line no-fallthrough

    case "draw":
    case "arrow":
      {
        const {
          startArrowhead = null,
          endArrowhead = element.type === "arrow" ? "arrow" : null
        } = element;
        let x = element.x;
        let y = element.y;
        let points = // migrate old arrow model to new one
        !Array.isArray(element.points) || element.points.length < 2 ? [[0, 0], [element.width, element.height]] : element.points;

        if (points[0][0] !== 0 || points[0][1] !== 0) {
          ({
            points,
            x,
            y
          } = LinearElementEditor.getNormalizedPoints(element));
        }

        return restoreElementWithProperties(element, {
          type: element.type === "draw" ? "line" : element.type,
          startBinding: repairBinding(element.startBinding),
          endBinding: repairBinding(element.endBinding),
          lastCommittedPoint: null,
          startArrowhead,
          endArrowhead,
          points,
          x,
          y
        });
      }
    // generic elements

    case "ellipse":
      return restoreElementWithProperties(element, {});

    case "rectangle":
      return restoreElementWithProperties(element, {});

    case "diamond":
      return restoreElementWithProperties(element, {});

    case "embeddable":
      return restoreElementWithProperties(element, {
        validated: null
      });

    case "frame":
      return restoreElementWithProperties(element, {
        name: (_b = element.name) !== null && _b !== void 0 ? _b : null
      });
    // Don't use default case so as to catch a missing an element type case.
    // We also don't want to throw, but instead return void so we filter
    // out these unsupported elements from the restored array.
  }

  return null;
};
/**
 * Repairs contaienr element's boundElements array by removing duplicates and
 * fixing containerId of bound elements if not present. Also removes any
 * bound elements that do not exist in the elements array.
 *
 * NOTE mutates elements.
 */


const repairContainerElement = (container, elementsMap) => {
  if (container.boundElements) {
    // copy because we're not cloning on restore, and we don't want to mutate upstream
    const boundElements = container.boundElements.slice(); // dedupe bindings & fix boundElement.containerId if not set already

    const boundIds = new Set();
    container.boundElements = boundElements.reduce((acc, binding) => {
      const boundElement = elementsMap.get(binding.id);

      if (boundElement && !boundIds.has(binding.id)) {
        boundIds.add(binding.id);

        if (boundElement.isDeleted) {
          return acc;
        }

        acc.push(binding);

        if (isTextElement(boundElement) && // being slightly conservative here, preserving existing containerId
        // if defined, lest boundElements is stale
        !boundElement.containerId) {
          boundElement.containerId = container.id;
        }
      }

      return acc;
    }, []);
  }
};
/**
 * Repairs target bound element's container's boundElements array,
 * or removes contaienrId if container does not exist.
 *
 * NOTE mutates elements.
 */


const repairBoundElement = (boundElement, elementsMap) => {
  const container = boundElement.containerId ? elementsMap.get(boundElement.containerId) : null;

  if (!container) {
    boundElement.containerId = null;
    return;
  }

  if (boundElement.isDeleted) {
    return;
  }

  if (container.boundElements && !container.boundElements.find(binding => binding.id === boundElement.id)) {
    // copy because we're not cloning on restore, and we don't want to mutate upstream
    const boundElements = (container.boundElements || (container.boundElements = [])).slice();
    boundElements.push({
      type: "text",
      id: boundElement.id
    });
    container.boundElements = boundElements;
  }
};
/**
 * Remove an element's frameId if its containing frame is non-existent
 *
 * NOTE mutates elements.
 */


const repairFrameMembership = (element, elementsMap) => {
  if (element.frameId) {
    const containingFrame = elementsMap.get(element.frameId);

    if (!containingFrame) {
      element.frameId = null;
    }
  }
};

export const restoreElements = (elements,
/** NOTE doesn't serve for reconciliation */
localElements, opts) => {
  // used to detect duplicate top-level element ids
  const existingIds = new Set();
  const localElementsMap = localElements ? arrayToMap(localElements) : null;
  const restoredElements = (elements || []).reduce((elements, element) => {
    // filtering out selection, which is legacy, no longer kept in elements,
    // and causing issues if retained
    if (element.type !== "selection" && !isInvisiblySmallElement(element)) {
      let migratedElement = restoreElement(element, opts === null || opts === void 0 ? void 0 : opts.refreshDimensions);

      if (migratedElement) {
        const localElement = localElementsMap === null || localElementsMap === void 0 ? void 0 : localElementsMap.get(element.id);

        if (localElement && localElement.version > migratedElement.version) {
          migratedElement = bumpVersion(migratedElement, localElement.version);
        }

        if (existingIds.has(migratedElement.id)) {
          migratedElement = Object.assign(Object.assign({}, migratedElement), {
            id: randomId()
          });
        }

        existingIds.add(migratedElement.id);
        elements.push(migratedElement);
      }
    }

    return elements;
  }, []);

  if (!(opts === null || opts === void 0 ? void 0 : opts.repairBindings)) {
    return restoredElements;
  } // repair binding. Mutates elements.


  const restoredElementsMap = arrayToMap(restoredElements);

  for (const element of restoredElements) {
    if (element.frameId) {
      repairFrameMembership(element, restoredElementsMap);
    }

    if (isTextElement(element) && element.containerId) {
      repairBoundElement(element, restoredElementsMap);
    } else if (element.boundElements) {
      repairContainerElement(element, restoredElementsMap);
    }
  }

  return restoredElements;
};

const coalesceAppStateValue = (key, appState, defaultAppState) => {
  const value = appState[key]; // NOTE the value! assertion is needed in TS 4.5.5 (fixed in newer versions)

  return value !== undefined ? value : defaultAppState[key];
};

const LegacyAppStateMigrations = {
  isSidebarDocked: (appState, defaultAppState) => {
    var _a;

    return ["defaultSidebarDockedPreference", (_a = appState.isSidebarDocked) !== null && _a !== void 0 ? _a : coalesceAppStateValue("defaultSidebarDockedPreference", appState, defaultAppState)];
  }
};
export const restoreAppState = (appState, localAppState) => {
  var _a, _b, _c, _d;

  appState = appState || {};
  const defaultAppState = getDefaultAppState();
  const nextAppState = {}; // first, migrate all legacy AppState properties to new ones. We do it
  // in one go before migrate the rest of the properties in case the new ones
  // depend on checking any other key (i.e. they are coupled)

  for (const legacyKey of Object.keys(LegacyAppStateMigrations)) {
    if (legacyKey in appState) {
      const [nextKey, nextValue] = LegacyAppStateMigrations[legacyKey](appState, defaultAppState);
      nextAppState[nextKey] = nextValue;
    }
  }

  for (const [key, defaultValue] of Object.entries(defaultAppState)) {
    // if AppState contains a legacy key, prefer that one and migrate its
    // value to the new one
    const suppliedValue = appState[key];
    const localValue = localAppState ? localAppState[key] : undefined;
    nextAppState[key] = suppliedValue !== undefined ? suppliedValue : localValue !== undefined ? localValue : defaultValue;
  }

  return Object.assign(Object.assign({}, nextAppState), {
    cursorButton: (localAppState === null || localAppState === void 0 ? void 0 : localAppState.cursorButton) || "up",
    // reset on fresh restore so as to hide the UI button if penMode not active
    penDetected: (_a = localAppState === null || localAppState === void 0 ? void 0 : localAppState.penDetected) !== null && _a !== void 0 ? _a : appState.penMode ? (_b = appState.penDetected) !== null && _b !== void 0 ? _b : false : false,
    activeTool: Object.assign(Object.assign({}, updateActiveTool(defaultAppState, nextAppState.activeTool.type && AllowedExcalidrawActiveTools[nextAppState.activeTool.type] ? nextAppState.activeTool : {
      type: "selection"
    })), {
      lastActiveTool: null,
      locked: (_c = nextAppState.activeTool.locked) !== null && _c !== void 0 ? _c : false
    }),
    // Migrates from previous version where appState.zoom was a number
    zoom: typeof appState.zoom === "number" ? {
      value: appState.zoom
    } : ((_d = appState.zoom) === null || _d === void 0 ? void 0 : _d.value) ? appState.zoom : defaultAppState.zoom,
    openSidebar: // string (legacy)
    typeof appState.openSidebar === "string" ? {
      name: DEFAULT_SIDEBAR.name
    } : nextAppState.openSidebar
  });
};
export const restore = (data,
/**
 * Local AppState (`this.state` or initial state from localStorage) so that we
 * don't overwrite local state with default values (when values not
 * explicitly specified).
 * Supply `null` if you can't get access to it.
 */
localAppState, localElements, elementsConfig) => {
  return {
    elements: restoreElements(data === null || data === void 0 ? void 0 : data.elements, localElements, elementsConfig),
    appState: restoreAppState(data === null || data === void 0 ? void 0 : data.appState, localAppState || null),
    files: (data === null || data === void 0 ? void 0 : data.files) || {}
  };
};

const restoreLibraryItem = libraryItem => {
  const elements = restoreElements(getNonDeletedElements(libraryItem.elements), null);
  return elements.length ? Object.assign(Object.assign({}, libraryItem), {
    elements
  }) : null;
};

export const restoreLibraryItems = (libraryItems = [], defaultStatus) => {
  const restoredItems = [];

  for (const item of libraryItems) {
    // migrate older libraries
    if (Array.isArray(item)) {
      const restoredItem = restoreLibraryItem({
        status: defaultStatus,
        elements: item,
        id: randomId(),
        created: Date.now()
      });

      if (restoredItem) {
        restoredItems.push(restoredItem);
      }
    } else {
      const _item = item;
      const restoredItem = restoreLibraryItem(Object.assign(Object.assign({}, _item), {
        id: _item.id || randomId(),
        status: _item.status || defaultStatus,
        created: _item.created || Date.now()
      }));

      if (restoredItem) {
        restoredItems.push(restoredItem);
      }
    }
  }

  return restoredItems;
};