var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";

const DropdownMenuItemCustom = _a => {
  var {
    children,
    className = "",
    selected
  } = _a,
      rest = __rest(_a, ["children", "className", "selected"]);

  return _jsx("div", Object.assign({}, rest, {
    className: `dropdown-menu-item-base dropdown-menu-item-custom ${className} ${selected ? `dropdown-menu-item--selected` : ``}`.trim()
  }, {
    children: children
  }));
};

export default DropdownMenuItemCustom;