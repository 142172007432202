var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

import { isTextElement, refreshTextDimensions } from "../element";
import { newElementWith } from "../element/mutateElement";
import { isBoundToContainer } from "../element/typeChecks";
import { getFontString } from "../utils";
import { ShapeCache } from "./ShapeCache";
export class Fonts {
  constructor({
    scene,
    onSceneUpdated
  }) {
    /**
     * if we load a (new) font, it's likely that text elements using it have
     * already been rendered using a fallback font. Thus, we want invalidate
     * their shapes and rerender. See #637.
     *
     * Invalidates text elements and rerenders scene, provided that at least one
     * of the supplied fontFaces has not already been processed.
     */
    this.onFontsLoaded = fontFaces => {
      if ( // bail if all fonts with have been processed. We're checking just a
      // subset of the font properties (though it should be enough), so it
      // can technically bail on a false positive.
      fontFaces.every(fontFace => {
        const sig = `${fontFace.family}-${fontFace.style}-${fontFace.weight}`;

        if (Fonts.loadedFontFaces.has(sig)) {
          return true;
        }

        Fonts.loadedFontFaces.add(sig);
        return false;
      })) {
        return false;
      }

      let didUpdate = false;
      this.scene.mapElements(element => {
        if (isTextElement(element) && !isBoundToContainer(element)) {
          ShapeCache.delete(element);
          didUpdate = true;
          return newElementWith(element, Object.assign({}, refreshTextDimensions(element)));
        }

        return element;
      });

      if (didUpdate) {
        this.onSceneUpdated();
      }
    };

    this.loadFontsForElements = elements => __awaiter(this, void 0, void 0, function* () {
      const fontFaces = yield Promise.all([...new Set(elements.filter(element => isTextElement(element)).map(element => element.fontFamily))].map(fontFamily => {
        var _a, _b, _c, _d;

        const fontString = getFontString({
          fontFamily,
          fontSize: 16
        });

        if (!((_b = (_a = document.fonts) === null || _a === void 0 ? void 0 : _a.check) === null || _b === void 0 ? void 0 : _b.call(_a, fontString))) {
          return (_d = (_c = document.fonts) === null || _c === void 0 ? void 0 : _c.load) === null || _d === void 0 ? void 0 : _d.call(_c, fontString);
        }

        return undefined;
      }));
      this.onFontsLoaded(fontFaces.flat().filter(Boolean));
    });

    this.scene = scene;
    this.onSceneUpdated = onSceneUpdated;
  }

} // it's ok to track fonts across multiple instances only once, so let's use
// a static member to reduce memory footprint

Fonts.loadedFontFaces = new Set();