import { jsx as _jsx } from "react/jsx-runtime";
import { HamburgerMenuIcon, palette } from "../components/icons";
import { ToolButton } from "../components/ToolButton";
import { t } from "../i18n";
import { showSelectedShapeActions, getNonDeletedElements } from "../element";
import { register } from "./register";
import { KEYS } from "../keys";
export const actionToggleCanvasMenu = register({
  name: "toggleCanvasMenu",
  trackEvent: {
    category: "menu"
  },
  perform: (_, appState) => ({
    appState: Object.assign(Object.assign({}, appState), {
      openMenu: appState.openMenu === "canvas" ? null : "canvas"
    }),
    commitToHistory: false
  }),
  PanelComponent: ({
    appState,
    updateData
  }) => _jsx(ToolButton, {
    type: "button",
    icon: HamburgerMenuIcon,
    "aria-label": t("buttons.menu"),
    onClick: updateData,
    selected: appState.openMenu === "canvas"
  })
});
export const actionToggleEditMenu = register({
  name: "toggleEditMenu",
  trackEvent: {
    category: "menu"
  },
  perform: (_elements, appState) => ({
    appState: Object.assign(Object.assign({}, appState), {
      openMenu: appState.openMenu === "shape" ? null : "shape"
    }),
    commitToHistory: false
  }),
  PanelComponent: ({
    elements,
    appState,
    updateData
  }) => _jsx(ToolButton, {
    visible: showSelectedShapeActions(appState, getNonDeletedElements(elements)),
    type: "button",
    icon: palette,
    "aria-label": t("buttons.edit"),
    onClick: updateData,
    selected: appState.openMenu === "shape"
  })
});
export const actionShortcuts = register({
  name: "toggleShortcuts",
  viewMode: true,
  trackEvent: {
    category: "menu",
    action: "toggleHelpDialog"
  },
  perform: (_elements, appState, _, {
    focusContainer
  }) => {
    if (appState.openDialog === "help") {
      focusContainer();
    }

    return {
      appState: Object.assign(Object.assign({}, appState), {
        openDialog: appState.openDialog === "help" ? null : "help"
      }),
      commitToHistory: false
    };
  },
  keyTest: event => event.key === KEYS.QUESTION_MARK
});