var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { getShortcutFromShortcutName } from "../../actions/shortcuts";
import { useI18n } from "../../i18n";
import { useExcalidrawSetAppState, useExcalidrawActionManager, useExcalidrawElements, useAppProps } from "../App";
import { ExportIcon, ExportImageIcon, HelpIcon, LoadIcon, MoonIcon, save, SunIcon, TrashIcon, usersIcon } from "../icons";
import { GithubIcon, DiscordIcon, TwitterIcon } from "../icons";
import DropdownMenuItem from "../dropdownMenu/DropdownMenuItem";
import DropdownMenuItemLink from "../dropdownMenu/DropdownMenuItemLink";
import { actionClearCanvas, actionLoadScene, actionSaveToActiveFile, actionShortcuts, actionToggleTheme } from "../../actions";
import "./DefaultItems.scss";
import clsx from "clsx";
import { useSetAtom } from "jotai";
import { activeConfirmDialogAtom } from "../ActiveConfirmDialog";
import { jotaiScope } from "../../jotai";
import { useUIAppState } from "../../context/ui-appState";
import { openConfirmModal } from "../OverwriteConfirm/OverwriteConfirmState";
import Trans from "../Trans";
export const LoadScene = () => {
  const {
    t
  } = useI18n();
  const actionManager = useExcalidrawActionManager();
  const elements = useExcalidrawElements();

  if (!actionManager.isActionEnabled(actionLoadScene)) {
    return null;
  }

  const handleSelect = () => __awaiter(void 0, void 0, void 0, function* () {
    if (!elements.length || (yield openConfirmModal({
      title: t("overwriteConfirm.modal.loadFromFile.title"),
      actionLabel: t("overwriteConfirm.modal.loadFromFile.button"),
      color: "warning",
      description: _jsx(Trans, {
        i18nKey: "overwriteConfirm.modal.loadFromFile.description",
        bold: text => _jsx("strong", {
          children: text
        }),
        br: () => _jsx("br", {})
      })
    }))) {
      actionManager.executeAction(actionLoadScene);
    }
  });

  return _jsx(DropdownMenuItem, Object.assign({
    icon: LoadIcon,
    onSelect: handleSelect,
    "data-testid": "load-button",
    shortcut: getShortcutFromShortcutName("loadScene"),
    "aria-label": t("buttons.load")
  }, {
    children: t("buttons.load")
  }));
};
LoadScene.displayName = "LoadScene";
export const SaveToActiveFile = () => {
  const {
    t
  } = useI18n();
  const actionManager = useExcalidrawActionManager();

  if (!actionManager.isActionEnabled(actionSaveToActiveFile)) {
    return null;
  }

  return _jsx(DropdownMenuItem, Object.assign({
    shortcut: getShortcutFromShortcutName("saveScene"),
    "data-testid": "save-button",
    onSelect: () => actionManager.executeAction(actionSaveToActiveFile),
    icon: save,
    "aria-label": `${t("buttons.save")}`
  }, {
    children: `${t("buttons.save")}`
  }));
};
SaveToActiveFile.displayName = "SaveToActiveFile";
export const SaveAsImage = () => {
  const setAppState = useExcalidrawSetAppState();
  const {
    t
  } = useI18n();
  return _jsx(DropdownMenuItem, Object.assign({
    icon: ExportImageIcon,
    "data-testid": "image-export-button",
    onSelect: () => setAppState({
      openDialog: "imageExport"
    }),
    shortcut: getShortcutFromShortcutName("imageExport"),
    "aria-label": t("buttons.exportImage")
  }, {
    children: t("buttons.exportImage")
  }));
};
SaveAsImage.displayName = "SaveAsImage";
export const Help = () => {
  const {
    t
  } = useI18n();
  const actionManager = useExcalidrawActionManager();
  return _jsx(DropdownMenuItem, Object.assign({
    "data-testid": "help-menu-item",
    icon: HelpIcon,
    onSelect: () => actionManager.executeAction(actionShortcuts),
    shortcut: "?",
    "aria-label": t("helpDialog.title")
  }, {
    children: t("helpDialog.title")
  }));
};
Help.displayName = "Help";
export const ClearCanvas = () => {
  const {
    t
  } = useI18n();
  const setActiveConfirmDialog = useSetAtom(activeConfirmDialogAtom, jotaiScope);
  const actionManager = useExcalidrawActionManager();

  if (!actionManager.isActionEnabled(actionClearCanvas)) {
    return null;
  }

  return _jsx(DropdownMenuItem, Object.assign({
    icon: TrashIcon,
    onSelect: () => setActiveConfirmDialog("clearCanvas"),
    "data-testid": "clear-canvas-button",
    "aria-label": t("buttons.clearReset")
  }, {
    children: t("buttons.clearReset")
  }));
};
ClearCanvas.displayName = "ClearCanvas";
export const ToggleTheme = () => {
  const {
    t
  } = useI18n();
  const appState = useUIAppState();
  const actionManager = useExcalidrawActionManager();

  if (!actionManager.isActionEnabled(actionToggleTheme)) {
    return null;
  }

  return _jsx(DropdownMenuItem, Object.assign({
    onSelect: event => {
      // do not close the menu when changing theme
      event.preventDefault();
      return actionManager.executeAction(actionToggleTheme);
    },
    icon: appState.theme === "dark" ? SunIcon : MoonIcon,
    "data-testid": "toggle-dark-mode",
    shortcut: getShortcutFromShortcutName("toggleTheme"),
    "aria-label": appState.theme === "dark" ? t("buttons.lightMode") : t("buttons.darkMode")
  }, {
    children: appState.theme === "dark" ? t("buttons.lightMode") : t("buttons.darkMode")
  }));
};
ToggleTheme.displayName = "ToggleTheme";
export const ChangeCanvasBackground = () => {
  const {
    t
  } = useI18n();
  const appState = useUIAppState();
  const actionManager = useExcalidrawActionManager();
  const appProps = useAppProps();

  if (appState.viewModeEnabled || !appProps.UIOptions.canvasActions.changeViewBackgroundColor) {
    return null;
  }

  return _jsxs("div", Object.assign({
    style: {
      marginTop: "0.5rem"
    }
  }, {
    children: [_jsx("div", Object.assign({
      "data-testid": "canvas-background-label",
      style: {
        fontSize: ".75rem",
        marginBottom: ".5rem"
      }
    }, {
      children: t("labels.canvasBackground")
    })), _jsx("div", Object.assign({
      style: {
        padding: "0 0.625rem"
      }
    }, {
      children: actionManager.renderAction("changeViewBackgroundColor")
    }))]
  }));
};
ChangeCanvasBackground.displayName = "ChangeCanvasBackground";
export const Export = () => {
  const {
    t
  } = useI18n();
  const setAppState = useExcalidrawSetAppState();
  return _jsx(DropdownMenuItem, Object.assign({
    icon: ExportIcon,
    onSelect: () => {
      setAppState({
        openDialog: "jsonExport"
      });
    },
    "data-testid": "json-export-button",
    "aria-label": t("buttons.export")
  }, {
    children: t("buttons.export")
  }));
};
Export.displayName = "Export";
export const Socials = () => _jsxs(_Fragment, {
  children: [_jsx(DropdownMenuItemLink, Object.assign({
    icon: GithubIcon,
    href: "https://github.com/excalidraw/excalidraw",
    "aria-label": "GitHub"
  }, {
    children: "GitHub"
  })), _jsx(DropdownMenuItemLink, Object.assign({
    icon: DiscordIcon,
    href: "https://discord.gg/UexuTaE",
    "aria-label": "Discord"
  }, {
    children: "Discord"
  })), _jsx(DropdownMenuItemLink, Object.assign({
    icon: TwitterIcon,
    href: "https://twitter.com/excalidraw",
    "aria-label": "Twitter"
  }, {
    children: "Twitter"
  }))]
});
Socials.displayName = "Socials";
export const LiveCollaborationTrigger = ({
  onSelect,
  isCollaborating
}) => {
  const {
    t
  } = useI18n();
  return _jsx(DropdownMenuItem, Object.assign({
    "data-testid": "collab-button",
    icon: usersIcon,
    className: clsx({
      "active-collab": isCollaborating
    }),
    onSelect: onSelect
  }, {
    children: t("labels.liveCollaboration")
  }));
};
LiveCollaborationTrigger.displayName = "LiveCollaborationTrigger";