import { jsx as _jsx } from "react/jsx-runtime";
import "./Avatar.scss";
import React, { useState } from "react";
import { getNameInitial } from "../clients";
export const Avatar = ({
  color,
  onClick,
  name,
  src
}) => {
  const shortName = getNameInitial(name);
  const [error, setError] = useState(false);
  const loadImg = !error && src;
  const style = loadImg ? undefined : {
    background: color
  };
  return _jsx("div", Object.assign({
    className: "Avatar",
    style: style,
    onClick: onClick
  }, {
    children: loadImg ? _jsx("img", {
      className: "Avatar-img",
      src: src,
      alt: shortName,
      referrerPolicy: "no-referrer",
      onError: () => setError(true)
    }) : shortName
  }));
};