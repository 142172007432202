var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { t } from "../i18n";
import { Dialog } from "./Dialog";
import "./ConfirmDialog.scss";
import DialogActionButton from "./DialogActionButton";
import { useSetAtom } from "jotai";
import { isLibraryMenuOpenAtom } from "./LibraryMenu";
import { useExcalidrawContainer, useExcalidrawSetAppState } from "./App";
import { jotaiScope } from "../jotai";

const ConfirmDialog = props => {
  const {
    onConfirm,
    onCancel,
    children,
    confirmText = t("buttons.confirm"),
    cancelText = t("buttons.cancel"),
    className = ""
  } = props,
        rest = __rest(props, ["onConfirm", "onCancel", "children", "confirmText", "cancelText", "className"]);

  const setAppState = useExcalidrawSetAppState();
  const setIsLibraryMenuOpen = useSetAtom(isLibraryMenuOpenAtom, jotaiScope);
  const {
    container
  } = useExcalidrawContainer();
  return _jsxs(Dialog, Object.assign({
    onCloseRequest: onCancel,
    size: "small"
  }, rest, {
    className: `confirm-dialog ${className}`
  }, {
    children: [children, _jsxs("div", Object.assign({
      className: "confirm-dialog-buttons"
    }, {
      children: [_jsx(DialogActionButton, {
        label: cancelText,
        onClick: () => {
          setAppState({
            openMenu: null
          });
          setIsLibraryMenuOpen(false);
          onCancel();
          container === null || container === void 0 ? void 0 : container.focus();
        }
      }), _jsx(DialogActionButton, {
        label: confirmText,
        onClick: () => {
          setAppState({
            openMenu: null
          });
          setIsLibraryMenuOpen(false);
          onConfirm();
          container === null || container === void 0 ? void 0 : container.focus();
        },
        actionType: "danger"
      })]
    }))]
  }));
};

export default ConfirmDialog;