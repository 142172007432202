var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from "react";
import { InitializeApp } from "../../components/InitializeApp";
import App from "../../components/App";
import { isShallowEqual } from "../../utils";
import "../../css/app.scss";
import "../../css/styles.scss";
import { defaultLang } from "../../i18n";
import { DEFAULT_UI_OPTIONS } from "../../constants";
import { Provider } from "jotai";
import { jotaiScope, jotaiStore } from "../../jotai";
import Footer from "../../components/footer/FooterCenter";
import MainMenu from "../../components/main-menu/MainMenu";
import WelcomeScreen from "../../components/welcome-screen/WelcomeScreen";
import LiveCollaborationTrigger from "../../components/live-collaboration/LiveCollaborationTrigger";

const ExcalidrawBase = props => {
  var _a, _b, _c, _d, _e, _f;

  const {
    onChange,
    initialData,
    excalidrawAPI,
    isCollaborating = false,
    onPointerUpdate,
    renderTopRightUI,
    langCode = defaultLang.code,
    viewModeEnabled,
    zenModeEnabled,
    gridModeEnabled,
    libraryReturnUrl,
    theme,
    name,
    renderCustomStats,
    onPaste,
    detectScroll = true,
    handleKeyboardGlobally = false,
    onLibraryChange,
    autoFocus = false,
    generateIdForFile,
    onLinkOpen,
    onPointerDown,
    onScrollChange,
    children,
    validateEmbeddable,
    renderEmbeddable
  } = props;
  const canvasActions = (_a = props.UIOptions) === null || _a === void 0 ? void 0 : _a.canvasActions; // FIXME normalize/set defaults in parent component so that the memo resolver
  // compares the same values

  const UIOptions = Object.assign(Object.assign({}, props.UIOptions), {
    canvasActions: Object.assign(Object.assign({}, DEFAULT_UI_OPTIONS.canvasActions), canvasActions),
    tools: {
      image: (_d = (_c = (_b = props.UIOptions) === null || _b === void 0 ? void 0 : _b.tools) === null || _c === void 0 ? void 0 : _c.image) !== null && _d !== void 0 ? _d : true
    }
  });

  if (canvasActions === null || canvasActions === void 0 ? void 0 : canvasActions.export) {
    UIOptions.canvasActions.export.saveFileToDisk = (_f = (_e = canvasActions.export) === null || _e === void 0 ? void 0 : _e.saveFileToDisk) !== null && _f !== void 0 ? _f : DEFAULT_UI_OPTIONS.canvasActions.export.saveFileToDisk;
  }

  if (UIOptions.canvasActions.toggleTheme === null && typeof theme === "undefined") {
    UIOptions.canvasActions.toggleTheme = true;
  }

  useEffect(() => {
    // Block pinch-zooming on iOS outside of the content area
    const handleTouchMove = event => {
      // @ts-ignore
      if (typeof event.scale === "number" && event.scale !== 1) {
        event.preventDefault();
      }
    };

    document.addEventListener("touchmove", handleTouchMove, {
      passive: false
    });
    return () => {
      document.removeEventListener("touchmove", handleTouchMove);
    };
  }, []);
  return _jsx(Provider, Object.assign({
    unstable_createStore: () => jotaiStore,
    scope: jotaiScope
  }, {
    children: _jsx(InitializeApp, Object.assign({
      langCode: langCode,
      theme: theme
    }, {
      children: _jsx(App, Object.assign({
        onChange: onChange,
        initialData: initialData,
        excalidrawAPI: excalidrawAPI,
        isCollaborating: isCollaborating,
        onPointerUpdate: onPointerUpdate,
        renderTopRightUI: renderTopRightUI,
        langCode: langCode,
        viewModeEnabled: viewModeEnabled,
        zenModeEnabled: zenModeEnabled,
        gridModeEnabled: gridModeEnabled,
        libraryReturnUrl: libraryReturnUrl,
        theme: theme,
        name: name,
        renderCustomStats: renderCustomStats,
        UIOptions: UIOptions,
        onPaste: onPaste,
        detectScroll: detectScroll,
        handleKeyboardGlobally: handleKeyboardGlobally,
        onLibraryChange: onLibraryChange,
        autoFocus: autoFocus,
        generateIdForFile: generateIdForFile,
        onLinkOpen: onLinkOpen,
        onPointerDown: onPointerDown,
        onScrollChange: onScrollChange,
        validateEmbeddable: validateEmbeddable,
        renderEmbeddable: renderEmbeddable
      }, {
        children: children
      }))
    }))
  }));
};

const areEqual = (prevProps, nextProps) => {
  // short-circuit early
  if (prevProps.children !== nextProps.children) {
    return false;
  }

  const {
    initialData: prevInitialData,
    UIOptions: prevUIOptions = {}
  } = prevProps,
        prev = __rest(prevProps, ["initialData", "UIOptions"]);

  const {
    initialData: nextInitialData,
    UIOptions: nextUIOptions = {}
  } = nextProps,
        next = __rest(nextProps, ["initialData", "UIOptions"]); // comparing UIOptions


  const prevUIOptionsKeys = Object.keys(prevUIOptions);
  const nextUIOptionsKeys = Object.keys(nextUIOptions);

  if (prevUIOptionsKeys.length !== nextUIOptionsKeys.length) {
    return false;
  }

  const isUIOptionsSame = prevUIOptionsKeys.every(key => {
    if (key === "canvasActions") {
      const canvasOptionKeys = Object.keys(prevUIOptions.canvasActions);
      return canvasOptionKeys.every(key => {
        var _a, _b, _c, _d;

        if (key === "export" && ((_a = prevUIOptions === null || prevUIOptions === void 0 ? void 0 : prevUIOptions.canvasActions) === null || _a === void 0 ? void 0 : _a.export) && ((_b = nextUIOptions === null || nextUIOptions === void 0 ? void 0 : nextUIOptions.canvasActions) === null || _b === void 0 ? void 0 : _b.export)) {
          return prevUIOptions.canvasActions.export.saveFileToDisk === nextUIOptions.canvasActions.export.saveFileToDisk;
        }

        return ((_c = prevUIOptions === null || prevUIOptions === void 0 ? void 0 : prevUIOptions.canvasActions) === null || _c === void 0 ? void 0 : _c[key]) === ((_d = nextUIOptions === null || nextUIOptions === void 0 ? void 0 : nextUIOptions.canvasActions) === null || _d === void 0 ? void 0 : _d[key]);
      });
    }

    return prevUIOptions[key] === nextUIOptions[key];
  });
  return isUIOptionsSame && isShallowEqual(prev, next);
};

export const Excalidraw = React.memo(ExcalidrawBase, areEqual);
Excalidraw.displayName = "Excalidraw";
export { getSceneVersion, isInvisiblySmallElement, getNonDeletedElements } from "../../element";
export { defaultLang, useI18n, languages } from "../../i18n";
export { restore, restoreAppState, restoreElements, restoreLibraryItems } from "../../data/restore";
export { exportToCanvas, exportToBlob, exportToSvg, serializeAsJSON, serializeLibraryAsJSON, loadLibraryFromBlob, loadFromBlob, loadSceneOrLibraryFromBlob, getFreeDrawSvgPath, exportToClipboard, mergeLibraryItems } from "../../packages/utils";
export { isLinearElement } from "../../element/typeChecks";
export { FONT_FAMILY, THEME, MIME_TYPES } from "../../constants";
export { mutateElement, newElementWith, bumpVersion } from "../../element/mutateElement";
export { parseLibraryTokensFromUrl, useHandleLibrary } from "../../data/library";
export { sceneCoordsToViewportCoords, viewportCoordsToSceneCoords } from "../../utils";
export { Sidebar } from "../../components/Sidebar/Sidebar";
export { Button } from "../../components/Button";
export { Footer };
export { MainMenu };
export { useDevice } from "../../components/App";
export { WelcomeScreen };
export { LiveCollaborationTrigger };
export { DefaultSidebar } from "../../components/DefaultSidebar";
export { normalizeLink } from "../../data/url";
export { convertToExcalidrawElements } from "../../data/transform";
export { getCommonBounds } from "../../element/bounds";
export { elementsOverlappingBBox, isElementInsideBBox, elementPartiallyOverlapsWithOrContainsBBox } from "../withinBounds";