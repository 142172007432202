import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useExcalidrawSetAppState } from "../App";
import { useUIAppState } from "../../context/ui-appState";
import clsx from "clsx";
import "./SidebarTrigger.scss";
export const SidebarTrigger = ({
  name,
  tab,
  icon,
  title,
  children,
  onToggle,
  className,
  style
}) => {
  var _a;

  const setAppState = useExcalidrawSetAppState();
  const appState = useUIAppState();
  return _jsxs("label", Object.assign({
    title: title
  }, {
    children: [_jsx("input", {
      className: "ToolIcon_type_checkbox",
      type: "checkbox",
      onChange: event => {
        var _a;

        (_a = document.querySelector(".layer-ui__wrapper")) === null || _a === void 0 ? void 0 : _a.classList.remove("animate");
        const isOpen = event.target.checked;
        setAppState({
          openSidebar: isOpen ? {
            name,
            tab
          } : null
        });
        onToggle === null || onToggle === void 0 ? void 0 : onToggle(isOpen);
      },
      checked: ((_a = appState.openSidebar) === null || _a === void 0 ? void 0 : _a.name) === name,
      "aria-label": title,
      "aria-keyshortcuts": "0"
    }), _jsxs("div", Object.assign({
      className: clsx("sidebar-trigger", className),
      style: style
    }, {
      children: [icon && _jsx("div", {
        children: icon
      }), children && _jsx("div", Object.assign({
        className: "sidebar-trigger__label"
      }, {
        children: children
      }))]
    }))]
  }));
};
SidebarTrigger.displayName = "SidebarTrigger";