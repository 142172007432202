var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

import { loadLibraryFromBlob } from "./blob";
import { restoreLibraryItems } from "./restore";
import { atom } from "jotai";
import { jotaiStore } from "../jotai";
import { getCommonBoundingBox } from "../element/bounds";
import { AbortError } from "../errors";
import { t } from "../i18n";
import { useEffect, useRef } from "react";
import { URL_HASH_KEYS, URL_QUERY_KEYS, APP_NAME, EVENT, DEFAULT_SIDEBAR, LIBRARY_SIDEBAR_TAB } from "../constants";
import { libraryItemSvgsCache } from "../hooks/useLibraryItemSvg";
import { cloneJSON } from "../utils";
export const libraryItemsAtom = atom({
  status: "loaded",
  isInitialized: true,
  libraryItems: []
});

const cloneLibraryItems = libraryItems => cloneJSON(libraryItems);
/**
 * checks if library item does not exist already in current library
 */


const isUniqueItem = (existingLibraryItems, targetLibraryItem) => {
  return !existingLibraryItems.find(libraryItem => {
    if (libraryItem.elements.length !== targetLibraryItem.elements.length) {
      return false;
    } // detect z-index difference by checking the excalidraw elements
    // are in order


    return libraryItem.elements.every((libItemExcalidrawItem, idx) => {
      return libItemExcalidrawItem.id === targetLibraryItem.elements[idx].id && libItemExcalidrawItem.versionNonce === targetLibraryItem.elements[idx].versionNonce;
    });
  });
};
/** Merges otherItems into localItems. Unique items in otherItems array are
    sorted first. */


export const mergeLibraryItems = (localItems, otherItems) => {
  const newItems = [];

  for (const item of otherItems) {
    if (isUniqueItem(localItems, item)) {
      newItems.push(item);
    }
  }

  return [...newItems, ...localItems];
};

class Library {
  constructor(app) {
    /** latest libraryItems */
    this.lastLibraryItems = [];
    /** indicates whether library is initialized with library items (has gone
     * though at least one update) */

    this.isInitialized = false;
    this.updateQueue = [];

    this.getLastUpdateTask = () => {
      return this.updateQueue[this.updateQueue.length - 1];
    };

    this.notifyListeners = () => {
      var _a, _b;

      if (this.updateQueue.length > 0) {
        jotaiStore.set(libraryItemsAtom, {
          status: "loading",
          libraryItems: this.lastLibraryItems,
          isInitialized: this.isInitialized
        });
      } else {
        this.isInitialized = true;
        jotaiStore.set(libraryItemsAtom, {
          status: "loaded",
          libraryItems: this.lastLibraryItems,
          isInitialized: this.isInitialized
        });

        try {
          (_b = (_a = this.app.props).onLibraryChange) === null || _b === void 0 ? void 0 : _b.call(_a, cloneLibraryItems(this.lastLibraryItems));
        } catch (error) {
          console.error(error);
        }
      }
    };
    /** call on excalidraw instance unmount */


    this.destroy = () => {
      this.isInitialized = false;
      this.updateQueue = [];
      this.lastLibraryItems = [];
      jotaiStore.set(libraryItemSvgsCache, new Map()); // TODO uncomment after/if we make jotai store scoped to each excal instance
      // jotaiStore.set(libraryItemsAtom, {
      //   status: "loading",
      //   isInitialized: false,
      //   libraryItems: [],
      // });
    };

    this.resetLibrary = () => {
      return this.setLibrary([]);
    };
    /**
     * @returns latest cloned libraryItems. Awaits all in-progress updates first.
     */


    this.getLatestLibrary = () => {
      return new Promise(resolve => __awaiter(this, void 0, void 0, function* () {
        try {
          const libraryItems = yield this.getLastUpdateTask() || this.lastLibraryItems;

          if (this.updateQueue.length > 0) {
            resolve(this.getLatestLibrary());
          } else {
            resolve(cloneLibraryItems(libraryItems));
          }
        } catch (error) {
          return resolve(this.lastLibraryItems);
        }
      }));
    }; // NOTE this is a high-level public API (exposed on ExcalidrawAPI) with
    // a slight overhead (always restoring library items). For internal use
    // where merging isn't needed, use `library.setLibrary()` directly.


    this.updateLibrary = ({
      libraryItems,
      prompt = false,
      merge = false,
      openLibraryMenu = false,
      defaultStatus = "unpublished"
    }) => __awaiter(this, void 0, void 0, function* () {
      if (openLibraryMenu) {
        this.app.setState({
          openSidebar: {
            name: DEFAULT_SIDEBAR.name,
            tab: LIBRARY_SIDEBAR_TAB
          }
        });
      }

      return this.setLibrary(() => {
        return new Promise((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
          try {
            const source = yield typeof libraryItems === "function" && !(libraryItems instanceof Blob) ? libraryItems(this.lastLibraryItems) : libraryItems;
            let nextItems;

            if (source instanceof Blob) {
              nextItems = yield loadLibraryFromBlob(source, defaultStatus);
            } else {
              nextItems = restoreLibraryItems(source, defaultStatus);
            }

            if (!prompt || window.confirm(t("alerts.confirmAddLibrary", {
              numShapes: nextItems.length
            }))) {
              if (prompt) {
                // focus container if we've prompted. We focus conditionally
                // lest `props.autoFocus` is disabled (in which case we should
                // focus only on user action such as prompt confirm)
                this.app.focusContainer();
              }

              if (merge) {
                resolve(mergeLibraryItems(this.lastLibraryItems, nextItems));
              } else {
                resolve(nextItems);
              }
            } else {
              reject(new AbortError());
            }
          } catch (error) {
            reject(error);
          }
        }));
      });
    });

    this.setLibrary = (
    /**
     * LibraryItems that will replace current items. Can be a function which
     * will be invoked after all previous tasks are resolved
     * (this is the prefered way to update the library to avoid race conditions,
     * but you'll want to manually merge the library items in the callback
     *  - which is what we're doing in Library.importLibrary()).
     *
     * If supplied promise is rejected with AbortError, we swallow it and
     * do not update the library.
     */
    libraryItems) => {
      const task = new Promise((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
        try {
          yield this.getLastUpdateTask();

          if (typeof libraryItems === "function") {
            libraryItems = libraryItems(this.lastLibraryItems);
          }

          this.lastLibraryItems = cloneLibraryItems(yield libraryItems);
          resolve(this.lastLibraryItems);
        } catch (error) {
          reject(error);
        }
      })).catch(error => {
        if (error.name === "AbortError") {
          console.warn("Library update aborted by user");
          return this.lastLibraryItems;
        }

        throw error;
      }).finally(() => {
        this.updateQueue = this.updateQueue.filter(_task => _task !== task);
        this.notifyListeners();
      });
      this.updateQueue.push(task);
      this.notifyListeners();
      return task;
    };

    this.app = app;
  }

}

export default Library;
export const distributeLibraryItemsOnSquareGrid = libraryItems => {
  const PADDING = 50;
  const ITEMS_PER_ROW = Math.ceil(Math.sqrt(libraryItems.length));
  const resElements = [];

  const getMaxHeightPerRow = row => {
    const maxHeight = libraryItems.slice(row * ITEMS_PER_ROW, row * ITEMS_PER_ROW + ITEMS_PER_ROW).reduce((acc, item) => {
      const {
        height
      } = getCommonBoundingBox(item.elements);
      return Math.max(acc, height);
    }, 0);
    return maxHeight;
  };

  const getMaxWidthPerCol = targetCol => {
    let index = 0;
    let currCol = 0;
    let maxWidth = 0;

    for (const item of libraryItems) {
      if (index % ITEMS_PER_ROW === 0) {
        currCol = 0;
      }

      if (currCol === targetCol) {
        const {
          width
        } = getCommonBoundingBox(item.elements);
        maxWidth = Math.max(maxWidth, width);
      }

      index++;
      currCol++;
    }

    return maxWidth;
  };

  let colOffsetX = 0;
  let rowOffsetY = 0;
  let maxHeightCurrRow = 0;
  let maxWidthCurrCol = 0;
  let index = 0;
  let col = 0;
  let row = 0;

  for (const item of libraryItems) {
    if (index && index % ITEMS_PER_ROW === 0) {
      rowOffsetY += maxHeightCurrRow + PADDING;
      colOffsetX = 0;
      col = 0;
      row++;
    }

    if (col === 0) {
      maxHeightCurrRow = getMaxHeightPerRow(row);
    }

    maxWidthCurrCol = getMaxWidthPerCol(col);
    const {
      minX,
      minY,
      width,
      height
    } = getCommonBoundingBox(item.elements);
    const offsetCenterX = (maxWidthCurrCol - width) / 2;
    const offsetCenterY = (maxHeightCurrRow - height) / 2;
    resElements.push( // eslint-disable-next-line no-loop-func
    ...item.elements.map(element => Object.assign(Object.assign({}, element), {
      x: element.x + // offset for column
      colOffsetX + // offset to center in given square grid
      offsetCenterX - // subtract minX so that given item starts at 0 coord
      minX,
      y: element.y + // offset for row
      rowOffsetY + // offset to center in given square grid
      offsetCenterY - // subtract minY so that given item starts at 0 coord
      minY
    })));
    colOffsetX += maxWidthCurrCol + PADDING;
    index++;
    col++;
  }

  return resElements;
};
export const parseLibraryTokensFromUrl = () => {
  const libraryUrl = // current
  new URLSearchParams(window.location.hash.slice(1)).get(URL_HASH_KEYS.addLibrary) || // legacy, kept for compat reasons
  new URLSearchParams(window.location.search).get(URL_QUERY_KEYS.addLibrary);
  const idToken = libraryUrl ? new URLSearchParams(window.location.hash.slice(1)).get("token") : null;
  return libraryUrl ? {
    libraryUrl,
    idToken
  } : null;
};
export const useHandleLibrary = ({
  excalidrawAPI,
  getInitialLibraryItems
}) => {
  const getInitialLibraryRef = useRef(getInitialLibraryItems);
  useEffect(() => {
    if (!excalidrawAPI) {
      return;
    }

    const importLibraryFromURL = ({
      libraryUrl,
      idToken
    }) => __awaiter(void 0, void 0, void 0, function* () {
      const libraryPromise = new Promise((resolve, reject) => __awaiter(void 0, void 0, void 0, function* () {
        try {
          const request = yield fetch(decodeURIComponent(libraryUrl));
          const blob = yield request.blob();
          resolve(blob);
        } catch (error) {
          reject(error);
        }
      }));
      const shouldPrompt = idToken !== excalidrawAPI.id; // wait for the tab to be focused before continuing in case we'll prompt
      // for confirmation

      yield shouldPrompt && document.hidden ? new Promise(resolve => {
        window.addEventListener("focus", () => resolve(), {
          once: true
        });
      }) : null;

      try {
        yield excalidrawAPI.updateLibrary({
          libraryItems: libraryPromise,
          prompt: shouldPrompt,
          merge: true,
          defaultStatus: "published",
          openLibraryMenu: true
        });
      } catch (error) {
        throw error;
      } finally {
        if (window.location.hash.includes(URL_HASH_KEYS.addLibrary)) {
          const hash = new URLSearchParams(window.location.hash.slice(1));
          hash.delete(URL_HASH_KEYS.addLibrary);
          window.history.replaceState({}, APP_NAME, `#${hash.toString()}`);
        } else if (window.location.search.includes(URL_QUERY_KEYS.addLibrary)) {
          const query = new URLSearchParams(window.location.search);
          query.delete(URL_QUERY_KEYS.addLibrary);
          window.history.replaceState({}, APP_NAME, `?${query.toString()}`);
        }
      }
    });

    const onHashChange = event => {
      event.preventDefault();
      const libraryUrlTokens = parseLibraryTokensFromUrl();

      if (libraryUrlTokens) {
        event.stopImmediatePropagation(); // If hash changed and it contains library url, import it and replace
        // the url to its previous state (important in case of collaboration
        // and similar).
        // Using history API won't trigger another hashchange.

        window.history.replaceState({}, "", event.oldURL);
        importLibraryFromURL(libraryUrlTokens);
      }
    }; // -------------------------------------------------------------------------
    // ------ init load --------------------------------------------------------


    if (getInitialLibraryRef.current) {
      excalidrawAPI.updateLibrary({
        libraryItems: getInitialLibraryRef.current()
      });
    }

    const libraryUrlTokens = parseLibraryTokensFromUrl();

    if (libraryUrlTokens) {
      importLibraryFromURL(libraryUrlTokens);
    } // --------------------------------------------------------- init load -----


    window.addEventListener(EVENT.HASHCHANGE, onHashChange);
    return () => {
      window.removeEventListener(EVENT.HASHCHANGE, onHashChange);
    };
  }, [excalidrawAPI]);
};