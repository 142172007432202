var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { getDropdownMenuItemClassName, useHandleDropdownMenuItemClick } from "./common";
import MenuItemContent from "./DropdownMenuItemContent";

const DropdownMenuItem = _a => {
  var _b;

  var {
    icon,
    onSelect,
    children,
    shortcut,
    className,
    selected
  } = _a,
      rest = __rest(_a, ["icon", "onSelect", "children", "shortcut", "className", "selected"]);

  const handleClick = useHandleDropdownMenuItemClick(rest.onClick, onSelect);
  return _jsx("button", Object.assign({}, rest, {
    onClick: handleClick,
    type: "button",
    className: getDropdownMenuItemClassName(className, selected),
    title: (_b = rest.title) !== null && _b !== void 0 ? _b : rest["aria-label"]
  }, {
    children: _jsx(MenuItemContent, Object.assign({
      icon: icon,
      shortcut: shortcut
    }, {
      children: children
    }))
  }));
};

export default DropdownMenuItem;
DropdownMenuItem.displayName = "DropdownMenuItem";