import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from "clsx"; // TODO: It might be "clever" to add option.icon to the existing component <ButtonSelect />

export const ButtonIconSelect = props => _jsx("div", Object.assign({
  className: "buttonList buttonListIcon"
}, {
  children: props.options.map(option => {
    var _a;

    return props.type === "button" ? _jsx("button", Object.assign({
      onClick: event => props.onClick(option.value, event),
      className: clsx({
        active: (_a = option.active) !== null && _a !== void 0 ? _a : props.value === option.value
      }),
      "data-testid": option.testId,
      title: option.text
    }, {
      children: option.icon
    }), option.text) : _jsxs("label", Object.assign({
      className: clsx({
        active: props.value === option.value
      }),
      title: option.text
    }, {
      children: [_jsx("input", {
        type: "radio",
        name: props.group,
        onChange: () => props.onChange(option.value),
        checked: props.value === option.value,
        "data-testid": option.testId
      }), option.icon]
    }), option.text);
  })
}));