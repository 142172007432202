export class Emitter {
  constructor(opts) {
    var _a;

    this.subscribers = [];
    this.updateOnChangeOnly = (_a = opts === null || opts === void 0 ? void 0 : opts.updateOnChangeOnly) !== null && _a !== void 0 ? _a : false;
    this.value = opts === null || opts === void 0 ? void 0 : opts.initialState;
  }
  /**
   * Attaches subscriber
   *
   * @returns unsubscribe function
   */


  on(...handlers) {
    const _handlers = handlers.flat().filter(item => typeof item === "function");

    this.subscribers.push(..._handlers);
    return () => this.off(_handlers);
  }

  off(...handlers) {
    const _handlers = handlers.flat();

    this.subscribers = this.subscribers.filter(handler => !_handlers.includes(handler));
  }

  trigger(...payload) {
    if (this.updateOnChangeOnly && this.value === payload) {
      return [];
    }

    this.value = payload;
    return this.subscribers.map(handler => handler(...payload));
  }

  destroy() {
    this.subscribers = [];
    this.value = undefined;
  }

}