import React, { useContext } from "react";
import { EVENT } from "../../constants";
import { composeEventHandlers } from "../../utils";
export const DropdownMenuContentPropsContext = React.createContext({});
export const getDropdownMenuItemClassName = (className = "", selected = false) => {
  return `dropdown-menu-item dropdown-menu-item-base ${className} ${selected ? "dropdown-menu-item--selected" : ""}`.trim();
};
export const useHandleDropdownMenuItemClick = (origOnClick, onSelect) => {
  const DropdownMenuContentProps = useContext(DropdownMenuContentPropsContext);
  return composeEventHandlers(origOnClick, event => {
    var _a;

    const itemSelectEvent = new CustomEvent(EVENT.MENU_ITEM_SELECT, {
      bubbles: true,
      cancelable: true
    });
    onSelect === null || onSelect === void 0 ? void 0 : onSelect(itemSelectEvent);

    if (!itemSelectEvent.defaultPrevented) {
      (_a = DropdownMenuContentProps.onSelect) === null || _a === void 0 ? void 0 : _a.call(DropdownMenuContentProps, itemSelectEvent);
    }
  });
};