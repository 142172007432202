import { jsx as _jsx } from "react/jsx-runtime";

const PickerHeading = ({
  children
}) => _jsx("div", Object.assign({
  className: "color-picker__heading"
}, {
  children: children
}));

export default PickerHeading;