import { jsx as _jsx } from "react/jsx-runtime";
import { VERSIONS } from "../constants";
import { t } from "../i18n";

const LibraryMenuBrowseButton = ({
  theme,
  id,
  libraryReturnUrl
}) => {
  const referrer = libraryReturnUrl || window.location.origin + window.location.pathname;
  return _jsx("a", Object.assign({
    className: "library-menu-browse-button",
    href: `${process.env.VITE_APP_LIBRARY_URL}?target=${window.name || "_blank"}&referrer=${referrer}&useHash=true&token=${id}&theme=${theme}&version=${VERSIONS.excalidrawLibrary}`,
    target: "_excalidraw_libraries"
  }, {
    children: t("labels.libraries")
  }));
};

export default LibraryMenuBrowseButton;