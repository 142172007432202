var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import { jsx as _jsx } from "react/jsx-runtime";
import * as RadixTabs from "@radix-ui/react-tabs";
import { useUIAppState } from "../../context/ui-appState";
import { useExcalidrawSetAppState } from "../App";
export const SidebarTabs = _a => {
  var {
    children
  } = _a,
      rest = __rest(_a, ["children"]);

  const appState = useUIAppState();
  const setAppState = useExcalidrawSetAppState();

  if (!appState.openSidebar) {
    return null;
  }

  const {
    name
  } = appState.openSidebar;
  return _jsx(RadixTabs.Root, Object.assign({
    className: "sidebar-tabs-root",
    value: appState.openSidebar.tab,
    onValueChange: tab => setAppState(state => Object.assign(Object.assign({}, state), {
      openSidebar: Object.assign(Object.assign({}, state.openSidebar), {
        name,
        tab
      })
    }))
  }, rest, {
    children: children
  }));
};
SidebarTabs.displayName = "SidebarTabs";