import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from "clsx";
import "./RadioGroup.scss";
export const RadioGroup = function ({
  onChange,
  value,
  choices,
  name
}) {
  return _jsx("div", Object.assign({
    className: "RadioGroup"
  }, {
    children: choices.map(choice => _jsxs("div", Object.assign({
      className: clsx("RadioGroup__choice", {
        active: choice.value === value
      })
    }, {
      children: [_jsx("input", {
        name: name,
        type: "radio",
        checked: choice.value === value,
        onChange: () => onChange(choice.value)
      }), choice.label]
    }), choice.label))
  }));
};