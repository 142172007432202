var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import { jsx as _jsx } from "react/jsx-runtime";
import * as RadixTabs from "@radix-ui/react-tabs";
export const SidebarTabTrigger = _a => {
  var {
    children,
    tab,
    onSelect
  } = _a,
      rest = __rest(_a, ["children", "tab", "onSelect"]);

  return _jsx(RadixTabs.Trigger, Object.assign({
    value: tab,
    asChild: true,
    onSelect: onSelect
  }, {
    children: _jsx("button", Object.assign({
      type: "button",
      className: `excalidraw-button sidebar-tab-trigger`
    }, rest, {
      children: children
    }))
  }));
};
SidebarTabTrigger.displayName = "SidebarTabTrigger";