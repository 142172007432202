import { LinearElementEditor } from "../element/linearElementEditor";
import { isLinearElement } from "../element/typeChecks";
import { register } from "./register";
export const actionToggleLinearEditor = register({
  name: "toggleLinearEditor",
  trackEvent: {
    category: "element"
  },
  predicate: (elements, appState, _, app) => {
    const selectedElements = app.scene.getSelectedElements(appState);

    if (selectedElements.length === 1 && isLinearElement(selectedElements[0])) {
      return true;
    }

    return false;
  },

  perform(elements, appState, _, app) {
    var _a;

    const selectedElement = app.scene.getSelectedElements({
      selectedElementIds: appState.selectedElementIds,
      includeBoundTextElement: true
    })[0];
    const editingLinearElement = ((_a = appState.editingLinearElement) === null || _a === void 0 ? void 0 : _a.elementId) === selectedElement.id ? null : new LinearElementEditor(selectedElement, app.scene);
    return {
      appState: Object.assign(Object.assign({}, appState), {
        editingLinearElement
      }),
      commitToHistory: false
    };
  },

  contextItemLabel: (elements, appState, app) => {
    var _a;

    const selectedElement = app.scene.getSelectedElements({
      selectedElementIds: appState.selectedElementIds,
      includeBoundTextElement: true
    })[0];
    return ((_a = appState.editingLinearElement) === null || _a === void 0 ? void 0 : _a.elementId) === selectedElement.id ? "labels.lineEditor.exit" : "labels.lineEditor.edit";
  }
});