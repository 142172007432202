import { jsx as _jsx } from "react/jsx-runtime";
import { alignElements } from "../align";
import { AlignBottomIcon, AlignLeftIcon, AlignRightIcon, AlignTopIcon, CenterHorizontallyIcon, CenterVerticallyIcon } from "../components/icons";
import { ToolButton } from "../components/ToolButton";
import { getNonDeletedElements } from "../element";
import { updateFrameMembershipOfSelectedElements } from "../frame";
import { t } from "../i18n";
import { KEYS } from "../keys";
import { isSomeElementSelected } from "../scene";
import { arrayToMap, getShortcutKey } from "../utils";
import { register } from "./register";

const alignActionsPredicate = (elements, appState, _, app) => {
  const selectedElements = app.scene.getSelectedElements(appState);
  return selectedElements.length > 1 && // TODO enable aligning frames when implemented properly
  !selectedElements.some(el => el.type === "frame");
};

const alignSelectedElements = (elements, appState, app, alignment) => {
  const selectedElements = app.scene.getSelectedElements(appState);
  const updatedElements = alignElements(selectedElements, alignment);
  const updatedElementsMap = arrayToMap(updatedElements);
  return updateFrameMembershipOfSelectedElements(elements.map(element => updatedElementsMap.get(element.id) || element), appState, app);
};

export const actionAlignTop = register({
  name: "alignTop",
  trackEvent: {
    category: "element"
  },
  predicate: alignActionsPredicate,
  perform: (elements, appState, _, app) => {
    return {
      appState,
      elements: alignSelectedElements(elements, appState, app, {
        position: "start",
        axis: "y"
      }),
      commitToHistory: true
    };
  },
  keyTest: event => event[KEYS.CTRL_OR_CMD] && event.shiftKey && event.key === KEYS.ARROW_UP,
  PanelComponent: ({
    elements,
    appState,
    updateData,
    app
  }) => _jsx(ToolButton, {
    hidden: !alignActionsPredicate(elements, appState, null, app),
    type: "button",
    icon: AlignTopIcon,
    onClick: () => updateData(null),
    title: `${t("labels.alignTop")} — ${getShortcutKey("CtrlOrCmd+Shift+Up")}`,
    "aria-label": t("labels.alignTop"),
    visible: isSomeElementSelected(getNonDeletedElements(elements), appState)
  })
});
export const actionAlignBottom = register({
  name: "alignBottom",
  trackEvent: {
    category: "element"
  },
  predicate: alignActionsPredicate,
  perform: (elements, appState, _, app) => {
    return {
      appState,
      elements: alignSelectedElements(elements, appState, app, {
        position: "end",
        axis: "y"
      }),
      commitToHistory: true
    };
  },
  keyTest: event => event[KEYS.CTRL_OR_CMD] && event.shiftKey && event.key === KEYS.ARROW_DOWN,
  PanelComponent: ({
    elements,
    appState,
    updateData,
    app
  }) => _jsx(ToolButton, {
    hidden: !alignActionsPredicate(elements, appState, null, app),
    type: "button",
    icon: AlignBottomIcon,
    onClick: () => updateData(null),
    title: `${t("labels.alignBottom")} — ${getShortcutKey("CtrlOrCmd+Shift+Down")}`,
    "aria-label": t("labels.alignBottom"),
    visible: isSomeElementSelected(getNonDeletedElements(elements), appState)
  })
});
export const actionAlignLeft = register({
  name: "alignLeft",
  trackEvent: {
    category: "element"
  },
  predicate: alignActionsPredicate,
  perform: (elements, appState, _, app) => {
    return {
      appState,
      elements: alignSelectedElements(elements, appState, app, {
        position: "start",
        axis: "x"
      }),
      commitToHistory: true
    };
  },
  keyTest: event => event[KEYS.CTRL_OR_CMD] && event.shiftKey && event.key === KEYS.ARROW_LEFT,
  PanelComponent: ({
    elements,
    appState,
    updateData,
    app
  }) => _jsx(ToolButton, {
    hidden: !alignActionsPredicate(elements, appState, null, app),
    type: "button",
    icon: AlignLeftIcon,
    onClick: () => updateData(null),
    title: `${t("labels.alignLeft")} — ${getShortcutKey("CtrlOrCmd+Shift+Left")}`,
    "aria-label": t("labels.alignLeft"),
    visible: isSomeElementSelected(getNonDeletedElements(elements), appState)
  })
});
export const actionAlignRight = register({
  name: "alignRight",
  trackEvent: {
    category: "element"
  },
  predicate: alignActionsPredicate,
  perform: (elements, appState, _, app) => {
    return {
      appState,
      elements: alignSelectedElements(elements, appState, app, {
        position: "end",
        axis: "x"
      }),
      commitToHistory: true
    };
  },
  keyTest: event => event[KEYS.CTRL_OR_CMD] && event.shiftKey && event.key === KEYS.ARROW_RIGHT,
  PanelComponent: ({
    elements,
    appState,
    updateData,
    app
  }) => _jsx(ToolButton, {
    hidden: !alignActionsPredicate(elements, appState, null, app),
    type: "button",
    icon: AlignRightIcon,
    onClick: () => updateData(null),
    title: `${t("labels.alignRight")} — ${getShortcutKey("CtrlOrCmd+Shift+Right")}`,
    "aria-label": t("labels.alignRight"),
    visible: isSomeElementSelected(getNonDeletedElements(elements), appState)
  })
});
export const actionAlignVerticallyCentered = register({
  name: "alignVerticallyCentered",
  trackEvent: {
    category: "element"
  },
  predicate: alignActionsPredicate,
  perform: (elements, appState, _, app) => {
    return {
      appState,
      elements: alignSelectedElements(elements, appState, app, {
        position: "center",
        axis: "y"
      }),
      commitToHistory: true
    };
  },
  PanelComponent: ({
    elements,
    appState,
    updateData,
    app
  }) => _jsx(ToolButton, {
    hidden: !alignActionsPredicate(elements, appState, null, app),
    type: "button",
    icon: CenterVerticallyIcon,
    onClick: () => updateData(null),
    title: t("labels.centerVertically"),
    "aria-label": t("labels.centerVertically"),
    visible: isSomeElementSelected(getNonDeletedElements(elements), appState)
  })
});
export const actionAlignHorizontallyCentered = register({
  name: "alignHorizontallyCentered",
  trackEvent: {
    category: "element"
  },
  predicate: alignActionsPredicate,
  perform: (elements, appState, _, app) => {
    return {
      appState,
      elements: alignSelectedElements(elements, appState, app, {
        position: "center",
        axis: "x"
      }),
      commitToHistory: true
    };
  },
  PanelComponent: ({
    elements,
    appState,
    updateData,
    app
  }) => _jsx(ToolButton, {
    hidden: !alignActionsPredicate(elements, appState, null, app),
    type: "button",
    icon: CenterHorizontallyIcon,
    onClick: () => updateData(null),
    title: t("labels.centerHorizontally"),
    "aria-label": t("labels.centerHorizontally"),
    visible: isSomeElementSelected(getNonDeletedElements(elements), appState)
  })
});