var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

import { ENCRYPTION_KEY_BITS } from "../constants";
import { blobToArrayBuffer } from "./blob";
export const IV_LENGTH_BYTES = 12;
export const createIV = () => {
  const arr = new Uint8Array(IV_LENGTH_BYTES);
  return window.crypto.getRandomValues(arr);
};
export const generateEncryptionKey = returnAs => __awaiter(void 0, void 0, void 0, function* () {
  const key = yield window.crypto.subtle.generateKey({
    name: "AES-GCM",
    length: ENCRYPTION_KEY_BITS
  }, true, // extractable
  ["encrypt", "decrypt"]);
  return returnAs === "cryptoKey" ? key : (yield window.crypto.subtle.exportKey("jwk", key)).k;
});
export const getCryptoKey = (key, usage) => window.crypto.subtle.importKey("jwk", {
  alg: "A128GCM",
  ext: true,
  k: key,
  key_ops: ["encrypt", "decrypt"],
  kty: "oct"
}, {
  name: "AES-GCM",
  length: ENCRYPTION_KEY_BITS
}, false, // extractable
[usage]);
export const encryptData = (key, data) => __awaiter(void 0, void 0, void 0, function* () {
  const importedKey = typeof key === "string" ? yield getCryptoKey(key, "encrypt") : key;
  const iv = createIV();
  const buffer = typeof data === "string" ? new TextEncoder().encode(data) : data instanceof Uint8Array ? data : data instanceof Blob ? yield blobToArrayBuffer(data) : data; // We use symmetric encryption. AES-GCM is the recommended algorithm and
  // includes checks that the ciphertext has not been modified by an attacker.

  const encryptedBuffer = yield window.crypto.subtle.encrypt({
    name: "AES-GCM",
    iv
  }, importedKey, buffer);
  return {
    encryptedBuffer,
    iv
  };
});
export const decryptData = (iv, encrypted, privateKey) => __awaiter(void 0, void 0, void 0, function* () {
  const key = yield getCryptoKey(privateKey, "decrypt");
  return window.crypto.subtle.decrypt({
    name: "AES-GCM",
    iv
  }, key, encrypted);
});