import { DEFAULT_FONT_FAMILY, DEFAULT_FONT_SIZE, TEXT_ALIGN, VERTICAL_ALIGN } from "../constants";
import { getCommonBounds, newElement, newLinearElement, redrawTextBoundingBox } from "../element";
import { bindLinearElement } from "../element/binding";
import { newFrameElement, newImageElement, newTextElement } from "../element/newElement";
import { getDefaultLineHeight, measureText, normalizeText } from "../element/textElement";
import { assertNever, cloneJSON, getFontString } from "../utils";
import { getSizeFromPoints } from "../points";
import { randomId } from "../random";
const DEFAULT_LINEAR_ELEMENT_PROPS = {
  width: 100,
  height: 0
};
const DEFAULT_DIMENSION = 100;

const bindTextToContainer = (container, textProps) => {
  const textElement = newTextElement(Object.assign(Object.assign({
    x: 0,
    y: 0,
    textAlign: TEXT_ALIGN.CENTER,
    verticalAlign: VERTICAL_ALIGN.MIDDLE
  }, textProps), {
    containerId: container.id,
    strokeColor: textProps.strokeColor || container.strokeColor
  }));
  Object.assign(container, {
    boundElements: (container.boundElements || []).concat({
      type: "text",
      id: textElement.id
    })
  });
  redrawTextBoundingBox(textElement, container);
  return [container, textElement];
};

const bindLinearElementToElement = (linearElement, start, end, elementStore) => {
  var _a, _b, _c, _d;

  let startBoundElement;
  let endBoundElement;
  Object.assign(linearElement, {
    startBinding: (linearElement === null || linearElement === void 0 ? void 0 : linearElement.startBinding) || null,
    endBinding: linearElement.endBinding || null
  });

  if (start) {
    const width = (_a = start === null || start === void 0 ? void 0 : start.width) !== null && _a !== void 0 ? _a : DEFAULT_DIMENSION;
    const height = (_b = start === null || start === void 0 ? void 0 : start.height) !== null && _b !== void 0 ? _b : DEFAULT_DIMENSION;
    let existingElement;

    if (start.id) {
      existingElement = elementStore.getElement(start.id);

      if (!existingElement) {
        console.error(`No element for start binding with id ${start.id} found`);
      }
    }

    const startX = start.x || linearElement.x - width;
    const startY = start.y || linearElement.y - height / 2;
    const startType = existingElement ? existingElement.type : start.type;

    if (startType) {
      if (startType === "text") {
        let text = "";

        if (existingElement && existingElement.type === "text") {
          text = existingElement.text;
        } else if (start.type === "text") {
          text = start.text;
        }

        if (!text) {
          console.error(`No text found for start binding text element for ${linearElement.id}`);
        }

        startBoundElement = newTextElement(Object.assign(Object.assign(Object.assign({
          x: startX,
          y: startY,
          type: "text"
        }, existingElement), start), {
          text
        })); // to position the text correctly when coordinates not provided

        Object.assign(startBoundElement, {
          x: start.x || linearElement.x - startBoundElement.width,
          y: start.y || linearElement.y - startBoundElement.height / 2
        });
      } else {
        switch (startType) {
          case "rectangle":
          case "ellipse":
          case "diamond":
            {
              startBoundElement = newElement(Object.assign(Object.assign(Object.assign({
                x: startX,
                y: startY,
                width,
                height
              }, existingElement), start), {
                type: startType
              }));
              break;
            }

          default:
            {
              assertNever(linearElement, `Unhandled element start type "${start.type}"`, true);
            }
        }
      }

      bindLinearElement(linearElement, startBoundElement, "start");
    }
  }

  if (end) {
    const height = (_c = end === null || end === void 0 ? void 0 : end.height) !== null && _c !== void 0 ? _c : DEFAULT_DIMENSION;
    const width = (_d = end === null || end === void 0 ? void 0 : end.width) !== null && _d !== void 0 ? _d : DEFAULT_DIMENSION;
    let existingElement;

    if (end.id) {
      existingElement = elementStore.getElement(end.id);

      if (!existingElement) {
        console.error(`No element for end binding with id ${end.id} found`);
      }
    }

    const endX = end.x || linearElement.x + linearElement.width;
    const endY = end.y || linearElement.y - height / 2;
    const endType = existingElement ? existingElement.type : end.type;

    if (endType) {
      if (endType === "text") {
        let text = "";

        if (existingElement && existingElement.type === "text") {
          text = existingElement.text;
        } else if (end.type === "text") {
          text = end.text;
        }

        if (!text) {
          console.error(`No text found for end binding text element for ${linearElement.id}`);
        }

        endBoundElement = newTextElement(Object.assign(Object.assign(Object.assign({
          x: endX,
          y: endY,
          type: "text"
        }, existingElement), end), {
          text
        })); // to position the text correctly when coordinates not provided

        Object.assign(endBoundElement, {
          y: end.y || linearElement.y - endBoundElement.height / 2
        });
      } else {
        switch (endType) {
          case "rectangle":
          case "ellipse":
          case "diamond":
            {
              endBoundElement = newElement(Object.assign(Object.assign(Object.assign({
                x: endX,
                y: endY,
                width,
                height
              }, existingElement), end), {
                type: endType
              }));
              break;
            }

          default:
            {
              assertNever(linearElement, `Unhandled element end type "${endType}"`, true);
            }
        }
      }

      bindLinearElement(linearElement, endBoundElement, "end");
    }
  } // Update start/end points by 0.5 so bindings don't overlap with start/end bound element coordinates.


  const endPointIndex = linearElement.points.length - 1;
  const delta = 0.5;
  const newPoints = cloneJSON(linearElement.points); // left to right so shift the arrow towards right

  if (linearElement.points[endPointIndex][0] > linearElement.points[endPointIndex - 1][0]) {
    newPoints[0][0] = delta;
    newPoints[endPointIndex][0] -= delta;
  } // right to left so shift the arrow towards left


  if (linearElement.points[endPointIndex][0] < linearElement.points[endPointIndex - 1][0]) {
    newPoints[0][0] = -delta;
    newPoints[endPointIndex][0] += delta;
  } // top to bottom so shift the arrow towards top


  if (linearElement.points[endPointIndex][1] > linearElement.points[endPointIndex - 1][1]) {
    newPoints[0][1] = delta;
    newPoints[endPointIndex][1] -= delta;
  } // bottom to top so shift the arrow towards bottom


  if (linearElement.points[endPointIndex][1] < linearElement.points[endPointIndex - 1][1]) {
    newPoints[0][1] = -delta;
    newPoints[endPointIndex][1] += delta;
  }

  Object.assign(linearElement, {
    points: newPoints
  });
  return {
    linearElement,
    startBoundElement,
    endBoundElement
  };
};

class ElementStore {
  constructor() {
    this.excalidrawElements = new Map();

    this.add = ele => {
      if (!ele) {
        return;
      }

      this.excalidrawElements.set(ele.id, ele);
    };

    this.getElements = () => {
      return Array.from(this.excalidrawElements.values());
    };

    this.getElement = id => {
      return this.excalidrawElements.get(id);
    };
  }

}

export const convertToExcalidrawElements = (elementsSkeleton, opts) => {
  var _a, _b, _c, _d;

  if (!elementsSkeleton) {
    return [];
  }

  const elements = cloneJSON(elementsSkeleton);
  const elementStore = new ElementStore();
  const elementsWithIds = new Map();
  const oldToNewElementIdMap = new Map(); // Create individual elements

  for (const element of elements) {
    let excalidrawElement;
    const originalId = element.id;

    if ((opts === null || opts === void 0 ? void 0 : opts.regenerateIds) !== false) {
      Object.assign(element, {
        id: randomId()
      });
    }

    switch (element.type) {
      case "rectangle":
      case "ellipse":
      case "diamond":
        {
          const width = ((_a = element === null || element === void 0 ? void 0 : element.label) === null || _a === void 0 ? void 0 : _a.text) && element.width === undefined ? 0 : (element === null || element === void 0 ? void 0 : element.width) || DEFAULT_DIMENSION;
          const height = ((_b = element === null || element === void 0 ? void 0 : element.label) === null || _b === void 0 ? void 0 : _b.text) && element.height === undefined ? 0 : (element === null || element === void 0 ? void 0 : element.height) || DEFAULT_DIMENSION;
          excalidrawElement = newElement(Object.assign(Object.assign({}, element), {
            width,
            height
          }));
          break;
        }

      case "line":
        {
          const width = element.width || DEFAULT_LINEAR_ELEMENT_PROPS.width;
          const height = element.height || DEFAULT_LINEAR_ELEMENT_PROPS.height;
          excalidrawElement = newLinearElement(Object.assign({
            width,
            height,
            points: [[0, 0], [width, height]]
          }, element));
          break;
        }

      case "arrow":
        {
          const width = element.width || DEFAULT_LINEAR_ELEMENT_PROPS.width;
          const height = element.height || DEFAULT_LINEAR_ELEMENT_PROPS.height;
          excalidrawElement = newLinearElement(Object.assign({
            width,
            height,
            endArrowhead: "arrow",
            points: [[0, 0], [width, height]]
          }, element));
          Object.assign(excalidrawElement, getSizeFromPoints(excalidrawElement.points));
          break;
        }

      case "text":
        {
          const fontFamily = (element === null || element === void 0 ? void 0 : element.fontFamily) || DEFAULT_FONT_FAMILY;
          const fontSize = (element === null || element === void 0 ? void 0 : element.fontSize) || DEFAULT_FONT_SIZE;
          const lineHeight = (element === null || element === void 0 ? void 0 : element.lineHeight) || getDefaultLineHeight(fontFamily);
          const text = (_c = element.text) !== null && _c !== void 0 ? _c : "";
          const normalizedText = normalizeText(text);
          const metrics = measureText(normalizedText, getFontString({
            fontFamily,
            fontSize
          }), lineHeight);
          excalidrawElement = newTextElement(Object.assign({
            width: metrics.width,
            height: metrics.height,
            fontFamily,
            fontSize
          }, element));
          break;
        }

      case "image":
        {
          excalidrawElement = newImageElement(Object.assign({
            width: (element === null || element === void 0 ? void 0 : element.width) || DEFAULT_DIMENSION,
            height: (element === null || element === void 0 ? void 0 : element.height) || DEFAULT_DIMENSION
          }, element));
          break;
        }

      case "frame":
        {
          excalidrawElement = newFrameElement(Object.assign({
            x: 0,
            y: 0
          }, element));
          break;
        }

      case "freedraw":
      case "embeddable":
        {
          excalidrawElement = element;
          break;
        }

      default:
        {
          excalidrawElement = element;
          assertNever(element, `Unhandled element type "${element.type}"`, true);
        }
    }

    const existingElement = elementStore.getElement(excalidrawElement.id);

    if (existingElement) {
      console.error(`Duplicate id found for ${excalidrawElement.id}`);
    } else {
      elementStore.add(excalidrawElement);
      elementsWithIds.set(excalidrawElement.id, element);

      if (originalId) {
        oldToNewElementIdMap.set(originalId, excalidrawElement.id);
      }
    }
  } // Add labels and arrow bindings


  for (const [id, element] of elementsWithIds) {
    const excalidrawElement = elementStore.getElement(id);

    switch (element.type) {
      case "rectangle":
      case "ellipse":
      case "diamond":
      case "arrow":
        {
          if ((_d = element.label) === null || _d === void 0 ? void 0 : _d.text) {
            let [container, text] = bindTextToContainer(excalidrawElement, element === null || element === void 0 ? void 0 : element.label);
            elementStore.add(container);
            elementStore.add(text);

            if (container.type === "arrow") {
              const originalStart = element.type === "arrow" ? element === null || element === void 0 ? void 0 : element.start : undefined;
              const originalEnd = element.type === "arrow" ? element === null || element === void 0 ? void 0 : element.end : undefined;

              if (originalStart && originalStart.id) {
                const newStartId = oldToNewElementIdMap.get(originalStart.id);

                if (newStartId) {
                  Object.assign(originalStart, {
                    id: newStartId
                  });
                }
              }

              if (originalEnd && originalEnd.id) {
                const newEndId = oldToNewElementIdMap.get(originalEnd.id);

                if (newEndId) {
                  Object.assign(originalEnd, {
                    id: newEndId
                  });
                }
              }

              const {
                linearElement,
                startBoundElement,
                endBoundElement
              } = bindLinearElementToElement(container, originalStart, originalEnd, elementStore);
              container = linearElement;
              elementStore.add(linearElement);
              elementStore.add(startBoundElement);
              elementStore.add(endBoundElement);
            }
          } else {
            switch (element.type) {
              case "arrow":
                {
                  const {
                    start,
                    end
                  } = element;

                  if (start && start.id) {
                    const newStartId = oldToNewElementIdMap.get(start.id);
                    Object.assign(start, {
                      id: newStartId
                    });
                  }

                  if (end && end.id) {
                    const newEndId = oldToNewElementIdMap.get(end.id);
                    Object.assign(end, {
                      id: newEndId
                    });
                  }

                  const {
                    linearElement,
                    startBoundElement,
                    endBoundElement
                  } = bindLinearElementToElement(excalidrawElement, start, end, elementStore);
                  elementStore.add(linearElement);
                  elementStore.add(startBoundElement);
                  elementStore.add(endBoundElement);
                  break;
                }
            }
          }

          break;
        }
    }
  } // Once all the excalidraw elements are created, we can add frames since we
  // need to calculate coordinates and dimensions of frame which is possibe after all
  // frame children are processed.


  for (const [id, element] of elementsWithIds) {
    if (element.type !== "frame") {
      continue;
    }

    const frame = elementStore.getElement(id);

    if (!frame) {
      throw new Error(`Excalidraw element with id ${id} doesn't exist`);
    }

    const childrenElements = [];
    element.children.forEach(id => {
      var _a;

      const newElementId = oldToNewElementIdMap.get(id);

      if (!newElementId) {
        throw new Error(`Element with ${id} wasn't mapped correctly`);
      }

      const elementInFrame = elementStore.getElement(newElementId);

      if (!elementInFrame) {
        throw new Error(`Frame element with id ${newElementId} doesn't exist`);
      }

      Object.assign(elementInFrame, {
        frameId: frame.id
      });
      (_a = elementInFrame === null || elementInFrame === void 0 ? void 0 : elementInFrame.boundElements) === null || _a === void 0 ? void 0 : _a.forEach(boundElement => {
        const ele = elementStore.getElement(boundElement.id);

        if (!ele) {
          throw new Error(`Bound element with id ${boundElement.id} doesn't exist`);
        }

        Object.assign(ele, {
          frameId: frame.id
        });
        childrenElements.push(ele);
      });
      childrenElements.push(elementInFrame);
    });
    let [minX, minY, maxX, maxY] = getCommonBounds(childrenElements);
    const PADDING = 10;
    minX = minX - PADDING;
    minY = minY - PADDING;
    maxX = maxX + PADDING;
    maxY = maxY + PADDING; // Take the max of calculated and provided frame dimensions, whichever is higher

    const width = Math.max(frame === null || frame === void 0 ? void 0 : frame.width, maxX - minX);
    const height = Math.max(frame === null || frame === void 0 ? void 0 : frame.height, maxY - minY);
    Object.assign(frame, {
      x: minX,
      y: minY,
      width,
      height
    });
  }

  return elementStore.getElements();
};