var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from "clsx";
import { DEFAULT_SIDEBAR, LIBRARY_SIDEBAR_TAB } from "../constants";
import { useTunnels } from "../context/tunnels";
import { useUIAppState } from "../context/ui-appState";
import { t } from "../i18n";
import { composeEventHandlers } from "../utils";
import { useExcalidrawSetAppState } from "./App";
import { withInternalFallback } from "./hoc/withInternalFallback";
import { LibraryMenu } from "./LibraryMenu";
import { Sidebar } from "./Sidebar/Sidebar";
const DefaultSidebarTrigger = withInternalFallback("DefaultSidebarTrigger", props => {
  const {
    DefaultSidebarTriggerTunnel
  } = useTunnels();
  return _jsx(DefaultSidebarTriggerTunnel.In, {
    children: _jsx(Sidebar.Trigger, Object.assign({}, props, {
      className: "default-sidebar-trigger",
      name: DEFAULT_SIDEBAR.name
    }))
  });
});
DefaultSidebarTrigger.displayName = "DefaultSidebarTrigger";

const DefaultTabTriggers = _a => {
  var {
    children
  } = _a,
      rest = __rest(_a, ["children"]);

  const {
    DefaultSidebarTabTriggersTunnel
  } = useTunnels();
  return _jsx(DefaultSidebarTabTriggersTunnel.In, {
    children: _jsx(Sidebar.TabTriggers, Object.assign({}, rest, {
      children: children
    }))
  });
};

DefaultTabTriggers.displayName = "DefaultTabTriggers";
export const DefaultSidebar = Object.assign(withInternalFallback("DefaultSidebar", _a => {
  var {
    children,
    className,
    onDock,
    docked
  } = _a,
      rest = __rest(_a, ["children", "className", "onDock", "docked"]);

  const appState = useUIAppState();
  const setAppState = useExcalidrawSetAppState();
  const {
    DefaultSidebarTabTriggersTunnel
  } = useTunnels();
  return _createElement(Sidebar, Object.assign({}, rest, {
    name: "default",
    key: "default",
    className: clsx("default-sidebar", className),
    docked: docked !== null && docked !== void 0 ? docked : appState.defaultSidebarDockedPreference,
    onDock: // `onDock=false` disables docking.
    // if `docked` passed, but no onDock passed, disable manual docking.
    onDock === false || !onDock && docked != null ? undefined : // compose to allow the host app to listen on default behavior
    composeEventHandlers(onDock, docked => {
      setAppState({
        defaultSidebarDockedPreference: docked
      });
    })
  }), _jsxs(Sidebar.Tabs, {
    children: [_jsxs(Sidebar.Header, {
      children: [rest.__fallback && _jsx("div", Object.assign({
        style: {
          color: "var(--color-primary)",
          fontSize: "1.2em",
          fontWeight: "bold",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
          paddingRight: "1em"
        }
      }, {
        children: t("toolBar.library")
      })), _jsx(DefaultSidebarTabTriggersTunnel.Out, {})]
    }), _jsx(Sidebar.Tab, Object.assign({
      tab: LIBRARY_SIDEBAR_TAB
    }, {
      children: _jsx(LibraryMenu, {})
    })), children]
  }));
}), {
  Trigger: DefaultSidebarTrigger,
  TabTriggers: DefaultTabTriggers
});