var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

import { tryParseSpreadsheet, VALID_SPREADSHEET } from "./charts";
import { ALLOWED_PASTE_MIME_TYPES, EXPORT_DATA_TYPES, MIME_TYPES } from "./constants";
import { isInitializedImageElement } from "./element/typeChecks";
import { deepCopyElement } from "./element/newElement";
import { mutateElement } from "./element/mutateElement";
import { getContainingFrame } from "./frame";
import { isMemberOf, isPromiseLike } from "./utils";
import { t } from "./i18n";
export const probablySupportsClipboardReadText = "clipboard" in navigator && "readText" in navigator.clipboard;
export const probablySupportsClipboardWriteText = "clipboard" in navigator && "writeText" in navigator.clipboard;
export const probablySupportsClipboardBlob = "clipboard" in navigator && "write" in navigator.clipboard && "ClipboardItem" in window && "toBlob" in HTMLCanvasElement.prototype;

const clipboardContainsElements = contents => {
  if ([EXPORT_DATA_TYPES.excalidraw, EXPORT_DATA_TYPES.excalidrawClipboard, EXPORT_DATA_TYPES.excalidrawClipboardWithAPI].includes(contents === null || contents === void 0 ? void 0 : contents.type) && Array.isArray(contents.elements)) {
    return true;
  }

  return false;
};

export const createPasteEvent = ({
  types,
  files
}) => {
  var _a, _b, _c, _d;

  if (!types && !files) {
    console.warn("createPasteEvent: no types or files provided");
  }

  const event = new ClipboardEvent("paste", {
    clipboardData: new DataTransfer()
  });

  if (types) {
    for (const [type, value] of Object.entries(types)) {
      try {
        (_a = event.clipboardData) === null || _a === void 0 ? void 0 : _a.setData(type, value);

        if (((_b = event.clipboardData) === null || _b === void 0 ? void 0 : _b.getData(type)) !== value) {
          throw new Error(`Failed to set "${type}" as clipboardData item`);
        }
      } catch (error) {
        throw new Error(error.message);
      }
    }
  }

  if (files) {
    let idx = -1;

    for (const file of files) {
      idx++;

      try {
        (_c = event.clipboardData) === null || _c === void 0 ? void 0 : _c.items.add(file);

        if (((_d = event.clipboardData) === null || _d === void 0 ? void 0 : _d.files[idx]) !== file) {
          throw new Error(`Failed to set file "${file.name}" as clipboardData item`);
        }
      } catch (error) {
        throw new Error(error.message);
      }
    }
  }

  return event;
};
export const serializeAsClipboardJSON = ({
  elements,
  files
}) => {
  const framesToCopy = new Set(elements.filter(element => element.type === "frame"));
  let foundFile = false;

  const _files = elements.reduce((acc, element) => {
    if (isInitializedImageElement(element)) {
      foundFile = true;

      if (files && files[element.fileId]) {
        acc[element.fileId] = files[element.fileId];
      }
    }

    return acc;
  }, {});

  if (foundFile && !files) {
    console.warn("copyToClipboard: attempting to file element(s) without providing associated `files` object.");
  } // select bound text elements when copying


  const contents = {
    type: EXPORT_DATA_TYPES.excalidrawClipboard,
    elements: elements.map(element => {
      if (getContainingFrame(element) && !framesToCopy.has(getContainingFrame(element))) {
        const copiedElement = deepCopyElement(element);
        mutateElement(copiedElement, {
          frameId: null
        });
        return copiedElement;
      }

      return element;
    }),
    files: files ? _files : undefined
  };
  return JSON.stringify(contents);
};
export const copyToClipboard = (elements, files,
/** supply if available to make the operation more certain to succeed */
clipboardEvent) => __awaiter(void 0, void 0, void 0, function* () {
  yield copyTextToSystemClipboard(serializeAsClipboardJSON({
    elements,
    files
  }), clipboardEvent);
});

const parsePotentialSpreadsheet = text => {
  const result = tryParseSpreadsheet(text);

  if (result.type === VALID_SPREADSHEET) {
    return {
      spreadsheet: result.spreadsheet
    };
  }

  return null;
};
/** internal, specific to parsing paste events. Do not reuse. */


function parseHTMLTree(el) {
  var _a;

  let result = [];

  for (const node of el.childNodes) {
    if (node.nodeType === 3) {
      const text = (_a = node.textContent) === null || _a === void 0 ? void 0 : _a.trim();

      if (text) {
        result.push({
          type: "text",
          value: text
        });
      }
    } else if (node instanceof HTMLImageElement) {
      const url = node.getAttribute("src");

      if (url && url.startsWith("http")) {
        result.push({
          type: "imageUrl",
          value: url
        });
      }
    } else {
      result = result.concat(parseHTMLTree(node));
    }
  }

  return result;
}

const maybeParseHTMLPaste = event => {
  var _a;

  const html = (_a = event.clipboardData) === null || _a === void 0 ? void 0 : _a.getData("text/html");

  if (!html) {
    return null;
  }

  try {
    const doc = new DOMParser().parseFromString(html, "text/html");
    const content = parseHTMLTree(doc.body);

    if (content.length) {
      return {
        type: "mixedContent",
        value: content
      };
    }
  } catch (error) {
    console.error(`error in parseHTMLFromPaste: ${error.message}`);
  }

  return null;
};

export const readSystemClipboard = () => __awaiter(void 0, void 0, void 0, function* () {
  var _a, _b, _c, _d;

  const types = {};

  try {
    if ((_a = navigator.clipboard) === null || _a === void 0 ? void 0 : _a.readText) {
      return {
        "text/plain": yield (_b = navigator.clipboard) === null || _b === void 0 ? void 0 : _b.readText()
      };
    }
  } catch (error) {
    // @ts-ignore
    if ((_c = navigator.clipboard) === null || _c === void 0 ? void 0 : _c.read) {
      console.warn(`navigator.clipboard.readText() failed (${error.message}). Failling back to navigator.clipboard.read()`);
    } else {
      throw error;
    }
  }

  let clipboardItems;

  try {
    clipboardItems = yield (_d = navigator.clipboard) === null || _d === void 0 ? void 0 : _d.read();
  } catch (error) {
    if (error.name === "DataError") {
      console.warn(`navigator.clipboard.read() error, clipboard is probably empty: ${error.message}`);
      return types;
    }

    throw error;
  }

  for (const item of clipboardItems) {
    for (const type of item.types) {
      if (!isMemberOf(ALLOWED_PASTE_MIME_TYPES, type)) {
        continue;
      }

      try {
        types[type] = yield (yield item.getType(type)).text();
      } catch (error) {
        console.warn(`Cannot retrieve ${type} from clipboardItem: ${error.message}`);
      }
    }
  }

  if (Object.keys(types).length === 0) {
    console.warn("No clipboard data found from clipboard.read().");
    return types;
  }

  return types;
});
/**
 * Parses "paste" ClipboardEvent.
 */

const parseClipboardEvent = (event, isPlainPaste = false) => __awaiter(void 0, void 0, void 0, function* () {
  var _e, _f;

  try {
    const mixedContent = !isPlainPaste && event && maybeParseHTMLPaste(event);

    if (mixedContent) {
      if (mixedContent.value.every(item => item.type === "text")) {
        return {
          type: "text",
          value: ((_e = event.clipboardData) === null || _e === void 0 ? void 0 : _e.getData("text/plain")) || mixedContent.value.map(item => item.value).join("\n").trim()
        };
      }

      return mixedContent;
    }

    const text = (_f = event.clipboardData) === null || _f === void 0 ? void 0 : _f.getData("text/plain");
    return {
      type: "text",
      value: (text || "").trim()
    };
  } catch (_g) {
    return {
      type: "text",
      value: ""
    };
  }
});
/**
 * Attempts to parse clipboard. Prefers system clipboard.
 */


export const parseClipboard = (event, isPlainPaste = false) => __awaiter(void 0, void 0, void 0, function* () {
  const parsedEventData = yield parseClipboardEvent(event, isPlainPaste);

  if (parsedEventData.type === "mixedContent") {
    return {
      mixedContent: parsedEventData.value
    };
  }

  try {
    // if system clipboard contains spreadsheet, use it even though it's
    // technically possible it's staler than in-app clipboard
    const spreadsheetResult = !isPlainPaste && parsePotentialSpreadsheet(parsedEventData.value);

    if (spreadsheetResult) {
      return spreadsheetResult;
    }
  } catch (error) {
    console.error(error);
  }

  try {
    const systemClipboardData = JSON.parse(parsedEventData.value);
    const programmaticAPI = systemClipboardData.type === EXPORT_DATA_TYPES.excalidrawClipboardWithAPI;

    if (clipboardContainsElements(systemClipboardData)) {
      return {
        elements: systemClipboardData.elements,
        files: systemClipboardData.files,
        text: isPlainPaste ? JSON.stringify(systemClipboardData.elements, null, 2) : undefined,
        programmaticAPI
      };
    }
  } catch (_h) {}

  return {
    text: parsedEventData.value
  };
});
export const copyBlobToClipboardAsPng = blob => __awaiter(void 0, void 0, void 0, function* () {
  try {
    // in Safari so far we need to construct the ClipboardItem synchronously
    // (i.e. in the same tick) otherwise browser will complain for lack of
    // user intent. Using a Promise ClipboardItem constructor solves this.
    // https://bugs.webkit.org/show_bug.cgi?id=222262
    //
    // Note that Firefox (and potentially others) seems to support Promise
    // ClipboardItem constructor, but throws on an unrelated MIME type error.
    // So we need to await this and fallback to awaiting the blob if applicable.
    yield navigator.clipboard.write([new window.ClipboardItem({
      [MIME_TYPES.png]: blob
    })]);
  } catch (error) {
    // if we're using a Promise ClipboardItem, let's try constructing
    // with resolution value instead
    if (isPromiseLike(blob)) {
      yield navigator.clipboard.write([new window.ClipboardItem({
        [MIME_TYPES.png]: yield blob
      })]);
    } else {
      throw error;
    }
  }
});
export const copyTextToSystemClipboard = (text, clipboardEvent) => __awaiter(void 0, void 0, void 0, function* () {
  var _j, _k; // (1) first try using Async Clipboard API


  if (probablySupportsClipboardWriteText) {
    try {
      // NOTE: doesn't work on FF on non-HTTPS domains, or when document
      // not focused
      yield navigator.clipboard.writeText(text || "");
      return;
    } catch (error) {
      console.error(error);
    }
  } // (2) if fails and we have access to ClipboardEvent, use plain old setData()


  try {
    if (clipboardEvent) {
      (_j = clipboardEvent.clipboardData) === null || _j === void 0 ? void 0 : _j.setData("text/plain", text || "");

      if (((_k = clipboardEvent.clipboardData) === null || _k === void 0 ? void 0 : _k.getData("text/plain")) !== text) {
        throw new Error("Failed to setData on clipboardEvent");
      }

      return;
    }
  } catch (error) {
    console.error(error);
  } // (3) if that fails, use document.execCommand


  if (!copyTextViaExecCommand(text)) {
    throw new Error(t("errors.copyToSystemClipboardFailed"));
  }
}); // adapted from https://github.com/zenorocha/clipboard.js/blob/ce79f170aa655c408b6aab33c9472e8e4fa52e19/src/clipboard-action.js#L48

const copyTextViaExecCommand = text => {
  // execCommand doesn't allow copying empty strings, so if we're
  // clearing clipboard using this API, we must copy at least an empty char
  if (!text) {
    text = " ";
  }

  const isRTL = document.documentElement.getAttribute("dir") === "rtl";
  const textarea = document.createElement("textarea");
  textarea.style.border = "0";
  textarea.style.padding = "0";
  textarea.style.margin = "0";
  textarea.style.position = "absolute";
  textarea.style[isRTL ? "right" : "left"] = "-9999px";
  const yPosition = window.pageYOffset || document.documentElement.scrollTop;
  textarea.style.top = `${yPosition}px`; // Prevent zooming on iOS

  textarea.style.fontSize = "12pt";
  textarea.setAttribute("readonly", "");
  textarea.value = text;
  document.body.appendChild(textarea);
  let success = false;

  try {
    textarea.select();
    textarea.setSelectionRange(0, textarea.value.length);
    success = document.execCommand("copy");
  } catch (error) {
    console.error(error);
  }

  textarea.remove();
  return success;
};