import { distance2d, rotate, rotatePoint } from "../math";
import rough from "roughjs/bin/rough";
import { generateRoughOptions } from "../scene/Shape";
import { isArrowElement, isBoundToContainer, isFreeDrawElement, isLinearElement, isTextElement } from "./typeChecks";
import { rescalePoints } from "../points";
import { getBoundTextElement, getContainerElement } from "./textElement";
import { LinearElementEditor } from "./linearElementEditor";
import { ShapeCache } from "../scene/ShapeCache";
import Scene from "../scene/Scene";
export class ElementBounds {
  static getBounds(element) {
    const cachedBounds = ElementBounds.boundsCache.get(element);

    if ((cachedBounds === null || cachedBounds === void 0 ? void 0 : cachedBounds.version) && cachedBounds.version === element.version && // we don't invalidate cache when we update containers and not labels,
    // which is causing problems down the line. Fix TBA.
    !isBoundToContainer(element)) {
      return cachedBounds.bounds;
    }

    const bounds = ElementBounds.calculateBounds(element); // hack to ensure that downstream checks could retrieve element Scene
    // so as to have correctly calculated bounds
    // FIXME remove when we get rid of all the id:Scene / element:Scene mapping

    const shouldCache = Scene.getScene(element);

    if (shouldCache) {
      ElementBounds.boundsCache.set(element, {
        version: element.version,
        bounds
      });
    }

    return bounds;
  }

  static calculateBounds(element) {
    let bounds;
    const [x1, y1, x2, y2, cx, cy] = getElementAbsoluteCoords(element);

    if (isFreeDrawElement(element)) {
      const [minX, minY, maxX, maxY] = getBoundsFromPoints(element.points.map(([x, y]) => rotate(x, y, cx - element.x, cy - element.y, element.angle)));
      return [minX + element.x, minY + element.y, maxX + element.x, maxY + element.y];
    } else if (isLinearElement(element)) {
      bounds = getLinearElementRotatedBounds(element, cx, cy);
    } else if (element.type === "diamond") {
      const [x11, y11] = rotate(cx, y1, cx, cy, element.angle);
      const [x12, y12] = rotate(cx, y2, cx, cy, element.angle);
      const [x22, y22] = rotate(x1, cy, cx, cy, element.angle);
      const [x21, y21] = rotate(x2, cy, cx, cy, element.angle);
      const minX = Math.min(x11, x12, x22, x21);
      const minY = Math.min(y11, y12, y22, y21);
      const maxX = Math.max(x11, x12, x22, x21);
      const maxY = Math.max(y11, y12, y22, y21);
      bounds = [minX, minY, maxX, maxY];
    } else if (element.type === "ellipse") {
      const w = (x2 - x1) / 2;
      const h = (y2 - y1) / 2;
      const cos = Math.cos(element.angle);
      const sin = Math.sin(element.angle);
      const ww = Math.hypot(w * cos, h * sin);
      const hh = Math.hypot(h * cos, w * sin);
      bounds = [cx - ww, cy - hh, cx + ww, cy + hh];
    } else {
      const [x11, y11] = rotate(x1, y1, cx, cy, element.angle);
      const [x12, y12] = rotate(x1, y2, cx, cy, element.angle);
      const [x22, y22] = rotate(x2, y2, cx, cy, element.angle);
      const [x21, y21] = rotate(x2, y1, cx, cy, element.angle);
      const minX = Math.min(x11, x12, x22, x21);
      const minY = Math.min(y11, y12, y22, y21);
      const maxX = Math.max(x11, x12, x22, x21);
      const maxY = Math.max(y11, y12, y22, y21);
      bounds = [minX, minY, maxX, maxY];
    }

    return bounds;
  }

}
ElementBounds.boundsCache = new WeakMap(); // Scene -> Scene coords, but in x1,x2,y1,y2 format.
//
// If the element is created from right to left, the width is going to be negative
// This set of functions retrieves the absolute position of the 4 points.

export const getElementAbsoluteCoords = (element, includeBoundText = false) => {
  if (isFreeDrawElement(element)) {
    return getFreeDrawElementAbsoluteCoords(element);
  } else if (isLinearElement(element)) {
    return LinearElementEditor.getElementAbsoluteCoords(element, includeBoundText);
  } else if (isTextElement(element)) {
    const container = getContainerElement(element);

    if (isArrowElement(container)) {
      const coords = LinearElementEditor.getBoundTextElementPosition(container, element);
      return [coords.x, coords.y, coords.x + element.width, coords.y + element.height, coords.x + element.width / 2, coords.y + element.height / 2];
    }
  }

  return [element.x, element.y, element.x + element.width, element.y + element.height, element.x + element.width / 2, element.y + element.height / 2];
};
/*
 * for a given element, `getElementLineSegments` returns line segments
 * that can be used for visual collision detection (useful for frames)
 * as opposed to bounding box collision detection
 */

export const getElementLineSegments = element => {
  const [x1, y1, x2, y2, cx, cy] = getElementAbsoluteCoords(element);
  const center = [cx, cy];

  if (isLinearElement(element) || isFreeDrawElement(element)) {
    const segments = [];
    let i = 0;

    while (i < element.points.length - 1) {
      segments.push([rotatePoint([element.points[i][0] + element.x, element.points[i][1] + element.y], center, element.angle), rotatePoint([element.points[i + 1][0] + element.x, element.points[i + 1][1] + element.y], center, element.angle)]);
      i++;
    }

    return segments;
  }

  const [nw, ne, sw, se, n, s, w, e] = [[x1, y1], [x2, y1], [x1, y2], [x2, y2], [cx, y1], [cx, y2], [x1, cy], [x2, cy]].map(point => rotatePoint(point, center, element.angle));

  if (element.type === "diamond") {
    return [[n, w], [n, e], [s, w], [s, e]];
  }

  if (element.type === "ellipse") {
    return [[n, w], [n, e], [s, w], [s, e], [n, w], [n, e], [s, w], [s, e]];
  }

  return [[nw, ne], [sw, se], [nw, sw], [ne, se], [nw, e], [sw, e], [ne, w], [se, w]];
};
/**
 * Scene -> Scene coords, but in x1,x2,y1,y2 format.
 *
 * Rectangle here means any rectangular frame, not an excalidraw element.
 */

export const getRectangleBoxAbsoluteCoords = boxSceneCoords => {
  return [boxSceneCoords.x, boxSceneCoords.y, boxSceneCoords.x + boxSceneCoords.width, boxSceneCoords.y + boxSceneCoords.height, boxSceneCoords.x + boxSceneCoords.width / 2, boxSceneCoords.y + boxSceneCoords.height / 2];
};
export const pointRelativeTo = (element, absoluteCoords) => {
  return [absoluteCoords[0] - element.x, absoluteCoords[1] - element.y];
};
export const getDiamondPoints = element => {
  // Here we add +1 to avoid these numbers to be 0
  // otherwise rough.js will throw an error complaining about it
  const topX = Math.floor(element.width / 2) + 1;
  const topY = 0;
  const rightX = element.width;
  const rightY = Math.floor(element.height / 2) + 1;
  const bottomX = topX;
  const bottomY = element.height;
  const leftX = 0;
  const leftY = rightY;
  return [topX, topY, rightX, rightY, bottomX, bottomY, leftX, leftY];
};
export const getCurvePathOps = shape => {
  for (const set of shape.sets) {
    if (set.type === "path") {
      return set.ops;
    }
  }

  return shape.sets[0].ops;
}; // reference: https://eliot-jones.com/2019/12/cubic-bezier-curve-bounding-boxes

const getBezierValueForT = (t, p0, p1, p2, p3) => {
  const oneMinusT = 1 - t;
  return Math.pow(oneMinusT, 3) * p0 + 3 * Math.pow(oneMinusT, 2) * t * p1 + 3 * oneMinusT * Math.pow(t, 2) * p2 + Math.pow(t, 3) * p3;
};

const solveQuadratic = (p0, p1, p2, p3) => {
  const i = p1 - p0;
  const j = p2 - p1;
  const k = p3 - p2;
  const a = 3 * i - 6 * j + 3 * k;
  const b = 6 * j - 6 * i;
  const c = 3 * i;
  const sqrtPart = b * b - 4 * a * c;
  const hasSolution = sqrtPart >= 0;

  if (!hasSolution) {
    return false;
  }

  let s1 = null;
  let s2 = null;
  let t1 = Infinity;
  let t2 = Infinity;

  if (a === 0) {
    t1 = t2 = -c / b;
  } else {
    t1 = (-b + Math.sqrt(sqrtPart)) / (2 * a);
    t2 = (-b - Math.sqrt(sqrtPart)) / (2 * a);
  }

  if (t1 >= 0 && t1 <= 1) {
    s1 = getBezierValueForT(t1, p0, p1, p2, p3);
  }

  if (t2 >= 0 && t2 <= 1) {
    s2 = getBezierValueForT(t2, p0, p1, p2, p3);
  }

  return [s1, s2];
};

const getCubicBezierCurveBound = (p0, p1, p2, p3) => {
  const solX = solveQuadratic(p0[0], p1[0], p2[0], p3[0]);
  const solY = solveQuadratic(p0[1], p1[1], p2[1], p3[1]);
  let minX = Math.min(p0[0], p3[0]);
  let maxX = Math.max(p0[0], p3[0]);

  if (solX) {
    const xs = solX.filter(x => x !== null);
    minX = Math.min(minX, ...xs);
    maxX = Math.max(maxX, ...xs);
  }

  let minY = Math.min(p0[1], p3[1]);
  let maxY = Math.max(p0[1], p3[1]);

  if (solY) {
    const ys = solY.filter(y => y !== null);
    minY = Math.min(minY, ...ys);
    maxY = Math.max(maxY, ...ys);
  }

  return [minX, minY, maxX, maxY];
};

export const getMinMaxXYFromCurvePathOps = (ops, transformXY) => {
  let currentP = [0, 0];
  const {
    minX,
    minY,
    maxX,
    maxY
  } = ops.reduce((limits, {
    op,
    data
  }) => {
    // There are only four operation types:
    // move, bcurveTo, lineTo, and curveTo
    if (op === "move") {
      // change starting point
      currentP = data; // move operation does not draw anything; so, it always
      // returns false
    } else if (op === "bcurveTo") {
      const _p1 = [data[0], data[1]];
      const _p2 = [data[2], data[3]];
      const _p3 = [data[4], data[5]];
      const p1 = transformXY ? transformXY(..._p1) : _p1;
      const p2 = transformXY ? transformXY(..._p2) : _p2;
      const p3 = transformXY ? transformXY(..._p3) : _p3;
      const p0 = transformXY ? transformXY(...currentP) : currentP;
      currentP = _p3;
      const [minX, minY, maxX, maxY] = getCubicBezierCurveBound(p0, p1, p2, p3);
      limits.minX = Math.min(limits.minX, minX);
      limits.minY = Math.min(limits.minY, minY);
      limits.maxX = Math.max(limits.maxX, maxX);
      limits.maxY = Math.max(limits.maxY, maxY);
    } else if (op === "lineTo") {// TODO: Implement this
    } else if (op === "qcurveTo") {// TODO: Implement this
    }

    return limits;
  }, {
    minX: Infinity,
    minY: Infinity,
    maxX: -Infinity,
    maxY: -Infinity
  });
  return [minX, minY, maxX, maxY];
};
export const getBoundsFromPoints = points => {
  let minX = Infinity;
  let minY = Infinity;
  let maxX = -Infinity;
  let maxY = -Infinity;

  for (const [x, y] of points) {
    minX = Math.min(minX, x);
    minY = Math.min(minY, y);
    maxX = Math.max(maxX, x);
    maxY = Math.max(maxY, y);
  }

  return [minX, minY, maxX, maxY];
};

const getFreeDrawElementAbsoluteCoords = element => {
  const [minX, minY, maxX, maxY] = getBoundsFromPoints(element.points);
  const x1 = minX + element.x;
  const y1 = minY + element.y;
  const x2 = maxX + element.x;
  const y2 = maxY + element.y;
  return [x1, y1, x2, y2, (x1 + x2) / 2, (y1 + y2) / 2];
};

export const getArrowheadPoints = (element, shape, position, arrowhead) => {
  const ops = getCurvePathOps(shape[0]);

  if (ops.length < 1) {
    return null;
  } // The index of the bCurve operation to examine.


  const index = position === "start" ? 1 : ops.length - 1;
  const data = ops[index].data;
  const p3 = [data[4], data[5]];
  const p2 = [data[2], data[3]];
  const p1 = [data[0], data[1]]; // We need to find p0 of the bezier curve.
  // It is typically the last point of the previous
  // curve; it can also be the position of moveTo operation.

  const prevOp = ops[index - 1];
  let p0 = [0, 0];

  if (prevOp.op === "move") {
    p0 = prevOp.data;
  } else if (prevOp.op === "bcurveTo") {
    p0 = [prevOp.data[4], prevOp.data[5]];
  } // B(t) = p0 * (1-t)^3 + 3p1 * t * (1-t)^2 + 3p2 * t^2 * (1-t) + p3 * t^3


  const equation = (t, idx) => Math.pow(1 - t, 3) * p3[idx] + 3 * t * Math.pow(1 - t, 2) * p2[idx] + 3 * Math.pow(t, 2) * (1 - t) * p1[idx] + p0[idx] * Math.pow(t, 3); // Ee know the last point of the arrow (or the first, if start arrowhead).


  const [x2, y2] = position === "start" ? p0 : p3; // By using cubic bezier equation (B(t)) and the given parameters,
  // we calculate a point that is closer to the last point.
  // The value 0.3 is chosen arbitrarily and it works best for all
  // the tested cases.

  const [x1, y1] = [equation(0.3, 0), equation(0.3, 1)]; // Find the normalized direction vector based on the
  // previously calculated points.

  const distance = Math.hypot(x2 - x1, y2 - y1);
  const nx = (x2 - x1) / distance;
  const ny = (y2 - y1) / distance;
  const size = {
    arrow: 30,
    bar: 15,
    dot: 15,
    triangle: 15
  }[arrowhead]; // pixels (will differ for each arrowhead)

  let length = 0;

  if (arrowhead === "arrow") {
    // Length for -> arrows is based on the length of the last section
    const [cx, cy] = element.points[element.points.length - 1];
    const [px, py] = element.points.length > 1 ? element.points[element.points.length - 2] : [0, 0];
    length = Math.hypot(cx - px, cy - py);
  } else {
    // Length for other arrowhead types is based on the total length of the line
    for (let i = 0; i < element.points.length; i++) {
      const [px, py] = element.points[i - 1] || [0, 0];
      const [cx, cy] = element.points[i];
      length += Math.hypot(cx - px, cy - py);
    }
  } // Scale down the arrowhead until we hit a certain size so that it doesn't look weird.
  // This value is selected by minimizing a minimum size with the last segment of the arrowhead


  const minSize = Math.min(size, length / 2);
  const xs = x2 - nx * minSize;
  const ys = y2 - ny * minSize;

  if (arrowhead === "dot") {
    const r = Math.hypot(ys - y2, xs - x2) + element.strokeWidth;
    return [x2, y2, r];
  }

  const angle = {
    arrow: 20,
    bar: 90,
    triangle: 25
  }[arrowhead]; // degrees
  // Return points

  const [x3, y3] = rotate(xs, ys, x2, y2, -angle * Math.PI / 180);
  const [x4, y4] = rotate(xs, ys, x2, y2, angle * Math.PI / 180);
  return [x2, y2, x3, y3, x4, y4];
};

const generateLinearElementShape = element => {
  const generator = rough.generator();
  const options = generateRoughOptions(element);

  const method = (() => {
    if (element.roundness) {
      return "curve";
    }

    if (options.fill) {
      return "polygon";
    }

    return "linearPath";
  })();

  return generator[method](element.points, options);
};

const getLinearElementRotatedBounds = (element, cx, cy) => {
  var _a;

  if (element.points.length < 2) {
    const [pointX, pointY] = element.points[0];
    const [x, y] = rotate(element.x + pointX, element.y + pointY, cx, cy, element.angle);
    let coords = [x, y, x, y];
    const boundTextElement = getBoundTextElement(element);

    if (boundTextElement) {
      const coordsWithBoundText = LinearElementEditor.getMinMaxXYWithBoundText(element, [x, y, x, y], boundTextElement);
      coords = [coordsWithBoundText[0], coordsWithBoundText[1], coordsWithBoundText[2], coordsWithBoundText[3]];
    }

    return coords;
  } // first element is always the curve


  const cachedShape = (_a = ShapeCache.get(element)) === null || _a === void 0 ? void 0 : _a[0];
  const shape = cachedShape !== null && cachedShape !== void 0 ? cachedShape : generateLinearElementShape(element);
  const ops = getCurvePathOps(shape);

  const transformXY = (x, y) => rotate(element.x + x, element.y + y, cx, cy, element.angle);

  const res = getMinMaxXYFromCurvePathOps(ops, transformXY);
  let coords = [res[0], res[1], res[2], res[3]];
  const boundTextElement = getBoundTextElement(element);

  if (boundTextElement) {
    const coordsWithBoundText = LinearElementEditor.getMinMaxXYWithBoundText(element, coords, boundTextElement);
    coords = [coordsWithBoundText[0], coordsWithBoundText[1], coordsWithBoundText[2], coordsWithBoundText[3]];
  }

  return coords;
};

export const getElementBounds = element => {
  return ElementBounds.getBounds(element);
};
export const getCommonBounds = elements => {
  if (!elements.length) {
    return [0, 0, 0, 0];
  }

  let minX = Infinity;
  let maxX = -Infinity;
  let minY = Infinity;
  let maxY = -Infinity;
  elements.forEach(element => {
    const [x1, y1, x2, y2] = getElementBounds(element);
    minX = Math.min(minX, x1);
    minY = Math.min(minY, y1);
    maxX = Math.max(maxX, x2);
    maxY = Math.max(maxY, y2);
  });
  return [minX, minY, maxX, maxY];
};
export const getDraggedElementsBounds = (elements, dragOffset) => {
  const [minX, minY, maxX, maxY] = getCommonBounds(elements);
  return [minX + dragOffset.x, minY + dragOffset.y, maxX + dragOffset.x, maxY + dragOffset.y];
};
export const getResizedElementAbsoluteCoords = (element, nextWidth, nextHeight, normalizePoints) => {
  if (!(isLinearElement(element) || isFreeDrawElement(element))) {
    return [element.x, element.y, element.x + nextWidth, element.y + nextHeight];
  }

  const points = rescalePoints(0, nextWidth, rescalePoints(1, nextHeight, element.points, normalizePoints), normalizePoints);
  let bounds;

  if (isFreeDrawElement(element)) {
    // Free Draw
    bounds = getBoundsFromPoints(points);
  } else {
    // Line
    const gen = rough.generator();
    const curve = !element.roundness ? gen.linearPath(points, generateRoughOptions(element)) : gen.curve(points, generateRoughOptions(element));
    const ops = getCurvePathOps(curve);
    bounds = getMinMaxXYFromCurvePathOps(ops);
  }

  const [minX, minY, maxX, maxY] = bounds;
  return [minX + element.x, minY + element.y, maxX + element.x, maxY + element.y];
};
export const getElementPointsCoords = (element, points) => {
  // This might be computationally heavey
  const gen = rough.generator();
  const curve = element.roundness == null ? gen.linearPath(points, generateRoughOptions(element)) : gen.curve(points, generateRoughOptions(element));
  const ops = getCurvePathOps(curve);
  const [minX, minY, maxX, maxY] = getMinMaxXYFromCurvePathOps(ops);
  return [minX + element.x, minY + element.y, maxX + element.x, maxY + element.y];
};
export const getClosestElementBounds = (elements, from) => {
  if (!elements.length) {
    return [0, 0, 0, 0];
  }

  let minDistance = Infinity;
  let closestElement = elements[0];
  elements.forEach(element => {
    const [x1, y1, x2, y2] = getElementBounds(element);
    const distance = distance2d((x1 + x2) / 2, (y1 + y2) / 2, from.x, from.y);

    if (distance < minDistance) {
      minDistance = distance;
      closestElement = element;
    }
  });
  return getElementBounds(closestElement);
};
export const getCommonBoundingBox = elements => {
  const [minX, minY, maxX, maxY] = getCommonBounds(elements);
  return {
    minX,
    minY,
    maxX,
    maxY,
    width: maxX - minX,
    height: maxY - minY,
    midX: (minX + maxX) / 2,
    midY: (minY + maxY) / 2
  };
};