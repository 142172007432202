var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { t } from "../../i18n";
import { usersIcon } from "../icons";
import { Button } from "../Button";
import clsx from "clsx";
import "./LiveCollaborationTrigger.scss";
import { useUIAppState } from "../../context/ui-appState";

const LiveCollaborationTrigger = _a => {
  var {
    isCollaborating,
    onSelect
  } = _a,
      rest = __rest(_a, ["isCollaborating", "onSelect"]);

  const appState = useUIAppState();
  return _jsxs(Button, Object.assign({}, rest, {
    className: clsx("collab-button", {
      active: isCollaborating
    }),
    type: "button",
    onSelect: onSelect,
    style: {
      position: "relative"
    },
    title: t("labels.liveCollaboration")
  }, {
    children: [usersIcon, appState.collaborators.size > 0 && _jsx("div", Object.assign({
      className: "CollabButton-collaborators"
    }, {
      children: appState.collaborators.size
    }))]
  }));
};

export default LiveCollaborationTrigger;
LiveCollaborationTrigger.displayName = "LiveCollaborationTrigger";