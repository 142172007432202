var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { t } from "../i18n";
import { Dialog } from "./Dialog";
import { exportToFileIcon, LinkIcon } from "./icons";
import { ToolButton } from "./ToolButton";
import { actionSaveFileToDisk } from "../actions/actionExport";
import { Card } from "./Card";
import "./ExportDialog.scss";
import { nativeFileSystemSupported } from "../data/filesystem";
import { trackEvent } from "../analytics";
import { getFrame } from "../utils";

const JSONExportModal = ({
  elements,
  appState,
  setAppState,
  files,
  actionManager,
  exportOpts,
  canvas,
  onCloseRequest
}) => {
  const {
    onExportToBackend
  } = exportOpts;
  return _jsx("div", Object.assign({
    className: "ExportDialog ExportDialog--json"
  }, {
    children: _jsxs("div", Object.assign({
      className: "ExportDialog-cards"
    }, {
      children: [exportOpts.saveFileToDisk && _jsxs(Card, Object.assign({
        color: "lime"
      }, {
        children: [_jsx("div", Object.assign({
          className: "Card-icon"
        }, {
          children: exportToFileIcon
        })), _jsx("h2", {
          children: t("exportDialog.disk_title")
        }), _jsxs("div", Object.assign({
          className: "Card-details"
        }, {
          children: [t("exportDialog.disk_details"), !nativeFileSystemSupported && actionManager.renderAction("changeProjectName")]
        })), _jsx(ToolButton, {
          className: "Card-button",
          type: "button",
          title: t("exportDialog.disk_button"),
          "aria-label": t("exportDialog.disk_button"),
          showAriaLabel: true,
          onClick: () => {
            actionManager.executeAction(actionSaveFileToDisk, "ui");
          }
        })]
      })), onExportToBackend && _jsxs(Card, Object.assign({
        color: "pink"
      }, {
        children: [_jsx("div", Object.assign({
          className: "Card-icon"
        }, {
          children: LinkIcon
        })), _jsx("h2", {
          children: t("exportDialog.link_title")
        }), _jsx("div", Object.assign({
          className: "Card-details"
        }, {
          children: t("exportDialog.link_details")
        })), _jsx(ToolButton, {
          className: "Card-button",
          type: "button",
          title: t("exportDialog.link_button"),
          "aria-label": t("exportDialog.link_button"),
          showAriaLabel: true,
          onClick: () => __awaiter(void 0, void 0, void 0, function* () {
            try {
              trackEvent("export", "link", `ui (${getFrame()})`);
              yield onExportToBackend(elements, appState, files, canvas);
              onCloseRequest();
            } catch (error) {
              setAppState({
                errorMessage: error.message
              });
            }
          })
        })]
      })), exportOpts.renderCustomUI && exportOpts.renderCustomUI(elements, appState, files, canvas)]
    }))
  }));
};

export const JSONExportDialog = ({
  elements,
  appState,
  files,
  actionManager,
  exportOpts,
  canvas,
  setAppState
}) => {
  const handleClose = React.useCallback(() => {
    setAppState({
      openDialog: null
    });
  }, [setAppState]);
  return _jsx(_Fragment, {
    children: appState.openDialog === "jsonExport" && _jsx(Dialog, Object.assign({
      onCloseRequest: handleClose,
      title: t("buttons.export")
    }, {
      children: _jsx(JSONExportModal, {
        elements: elements,
        appState: appState,
        setAppState: setAppState,
        files: files,
        actionManager: actionManager,
        onCloseRequest: handleClose,
        exportOpts: exportOpts,
        canvas: canvas
      })
    }))
  });
};