var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import { jsx as _jsx } from "react/jsx-runtime";
import clsx from "clsx";
import { useDevice } from "../App";

const MenuTrigger = _a => {
  var {
    className = "",
    children,
    onToggle,
    title
  } = _a,
      rest = __rest(_a, ["className", "children", "onToggle", "title"]);

  const device = useDevice();
  const classNames = clsx(`dropdown-menu-button ${className}`, "zen-mode-transition", {
    "dropdown-menu-button--mobile": device.editor.isMobile
  }).trim();
  return _jsx("button", Object.assign({
    "data-prevent-outside-click": true,
    className: classNames,
    onClick: onToggle,
    type: "button",
    "data-testid": "dropdown-menu-button",
    title: title
  }, rest, {
    children: children
  }));
};

export default MenuTrigger;
MenuTrigger.displayName = "DropdownMenuTrigger";