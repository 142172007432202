import { RoughGenerator } from "roughjs/bin/generator";
import { elementWithCanvasCache } from "../renderer/renderElement";
import { _generateElementShape } from "./Shape";
export class ShapeCache {}
ShapeCache.rg = new RoughGenerator();
ShapeCache.cache = new WeakMap();
/**
 * Retrieves shape from cache if available. Use this only if shape
 * is optional and you have a fallback in case it's not cached.
 */

ShapeCache.get = element => {
  return ShapeCache.cache.get(element);
};

ShapeCache.set = (element, shape) => ShapeCache.cache.set(element, shape);

ShapeCache.delete = element => ShapeCache.cache.delete(element);

ShapeCache.destroy = () => {
  ShapeCache.cache = new WeakMap();
};
/**
 * Generates & caches shape for element if not already cached, otherwise
 * returns cached shape.
 */


ShapeCache.generateElementShape = (element, isExporting = false) => {
  // when exporting, always regenerated to guarantee the latest shape
  const cachedShape = isExporting ? undefined : ShapeCache.get(element); // `null` indicates no rc shape applicable for this element type,
  // but it's considered a valid cache value (= do not regenerate)

  if (cachedShape !== undefined) {
    return cachedShape;
  }

  elementWithCanvasCache.delete(element);

  const shape = _generateElementShape(element, ShapeCache.rg, isExporting);

  ShapeCache.cache.set(element, shape);
  return shape;
};