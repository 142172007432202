import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { memo, useEffect, useState } from "react";
import { EmptyLibraryUnit, LibraryUnit } from "./LibraryUnit";
import { useTransition } from "../hooks/useTransition";
export const LibraryMenuSectionGrid = ({
  children
}) => {
  return _jsx("div", Object.assign({
    className: "library-menu-items-container__grid"
  }, {
    children: children
  }));
};
export const LibraryMenuSection = memo(({
  items,
  onItemSelectToggle,
  onItemDrag,
  isItemSelected,
  onClick,
  svgCache,
  itemsRenderedPerBatch
}) => {
  const [, startTransition] = useTransition();
  const [index, setIndex] = useState(0);
  useEffect(() => {
    if (index < items.length) {
      startTransition(() => {
        setIndex(index + itemsRenderedPerBatch);
      });
    }
  }, [index, items.length, startTransition, itemsRenderedPerBatch]);
  return _jsx(_Fragment, {
    children: items.map((item, i) => {
      var _a;

      return i < index ? _jsx(LibraryUnit, {
        elements: item === null || item === void 0 ? void 0 : item.elements,
        isPending: !(item === null || item === void 0 ? void 0 : item.id) && !!(item === null || item === void 0 ? void 0 : item.elements),
        onClick: onClick,
        svgCache: svgCache,
        id: item === null || item === void 0 ? void 0 : item.id,
        selected: isItemSelected(item.id),
        onToggle: onItemSelectToggle,
        onDrag: onItemDrag
      }, (_a = item === null || item === void 0 ? void 0 : item.id) !== null && _a !== void 0 ? _a : i) : _jsx(EmptyLibraryUnit, {}, i);
    })
  });
});