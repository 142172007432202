import { jsx as _jsx } from "react/jsx-runtime";
import "./Stack.scss";
import React, { forwardRef } from "react";
import clsx from "clsx";
const RowStack = forwardRef(({
  children,
  gap,
  align,
  justifyContent,
  className,
  style
}, ref) => {
  return _jsx("div", Object.assign({
    className: clsx("Stack Stack_horizontal", className),
    style: Object.assign({
      "--gap": gap,
      alignItems: align,
      justifyContent
    }, style),
    ref: ref
  }, {
    children: children
  }));
});
const ColStack = forwardRef(({
  children,
  gap,
  align,
  justifyContent,
  className,
  style
}, ref) => {
  return _jsx("div", Object.assign({
    className: clsx("Stack Stack_vertical", className),
    style: Object.assign({
      "--gap": gap,
      justifyItems: align,
      justifyContent
    }, style),
    ref: ref
  }, {
    children: children
  }));
});
export default {
  Row: RowStack,
  Col: ColStack
};