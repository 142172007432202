var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { t } from "../i18n";
import { useExcalidrawContainer } from "./App";
export const Section = _a => {
  var {
    heading,
    children
  } = _a,
      props = __rest(_a, ["heading", "children"]);

  const {
    id
  } = useExcalidrawContainer();

  const header = _jsx("h2", Object.assign({
    className: "visually-hidden",
    id: `${id}-${heading}-title`
  }, {
    children: t(`headings.${heading}`)
  }));

  return _jsx("section", Object.assign({}, props, {
    "aria-labelledby": `${id}-${heading}-title`
  }, {
    children: typeof children === "function" ? children(header) : _jsxs(_Fragment, {
      children: [header, children]
    })
  }));
};