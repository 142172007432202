import { jsx as _jsx } from "react/jsx-runtime";
import React, { useLayoutEffect, useRef, useEffect } from "react";
import "./Popover.scss";
import { unstable_batchedUpdates } from "react-dom";
import { queryFocusableElements } from "../utils";
import { KEYS } from "../keys";
export const Popover = ({
  children,
  left,
  top,
  onCloseRequest,
  fitInViewport = false,
  offsetLeft = 0,
  offsetTop = 0,
  viewportWidth = window.innerWidth,
  viewportHeight = window.innerHeight
}) => {
  const popoverRef = useRef(null);
  useEffect(() => {
    const container = popoverRef.current;

    if (!container) {
      return;
    } // focus popover only if the caller didn't focus on something else nested
    // within the popover, which should take precedence. Fixes cases
    // like color picker listening to keydown events on containers nested
    // in the popover.


    if (!container.contains(document.activeElement)) {
      container.focus();
    }

    const handleKeyDown = event => {
      var _a, _b, _c;

      if (event.key === KEYS.TAB) {
        const focusableElements = queryFocusableElements(container);
        const {
          activeElement
        } = document;
        const currentIndex = focusableElements.findIndex(element => element === activeElement);

        if (activeElement === container) {
          if (event.shiftKey) {
            (_a = focusableElements[focusableElements.length - 1]) === null || _a === void 0 ? void 0 : _a.focus();
          } else {
            focusableElements[0].focus();
          }

          event.preventDefault();
          event.stopImmediatePropagation();
        } else if (currentIndex === 0 && event.shiftKey) {
          (_b = focusableElements[focusableElements.length - 1]) === null || _b === void 0 ? void 0 : _b.focus();
          event.preventDefault();
          event.stopImmediatePropagation();
        } else if (currentIndex === focusableElements.length - 1 && !event.shiftKey) {
          (_c = focusableElements[0]) === null || _c === void 0 ? void 0 : _c.focus();
          event.preventDefault();
          event.stopImmediatePropagation();
        }
      }
    };

    container.addEventListener("keydown", handleKeyDown);
    return () => container.removeEventListener("keydown", handleKeyDown);
  }, []);
  const lastInitializedPosRef = useRef(null); // ensure the popover doesn't overflow the viewport

  useLayoutEffect(() => {
    var _a, _b;

    if (fitInViewport && popoverRef.current && top != null && left != null) {
      const container = popoverRef.current;
      const {
        width,
        height
      } = container.getBoundingClientRect(); // hack for StrictMode so this effect only runs once for
      // the same top/left position, otherwise
      // we'd potentically reposition twice (once for viewport overflow)
      // and once for top/left position afterwards

      if (((_a = lastInitializedPosRef.current) === null || _a === void 0 ? void 0 : _a.top) === top && ((_b = lastInitializedPosRef.current) === null || _b === void 0 ? void 0 : _b.left) === left) {
        return;
      }

      lastInitializedPosRef.current = {
        top,
        left
      };

      if (width >= viewportWidth) {
        container.style.width = `${viewportWidth}px`;
        container.style.left = "0px";
        container.style.overflowX = "scroll";
      } else if (left + width - offsetLeft > viewportWidth) {
        container.style.left = `${viewportWidth - width - 10}px`;
      } else {
        container.style.left = `${left}px`;
      }

      if (height >= viewportHeight) {
        container.style.height = `${viewportHeight - 20}px`;
        container.style.top = "10px";
        container.style.overflowY = "scroll";
      } else if (top + height - offsetTop > viewportHeight) {
        container.style.top = `${viewportHeight - height}px`;
      } else {
        container.style.top = `${top}px`;
      }
    }
  }, [top, left, fitInViewport, viewportWidth, viewportHeight, offsetLeft, offsetTop]);
  useEffect(() => {
    if (onCloseRequest) {
      const handler = event => {
        var _a;

        if (!((_a = popoverRef.current) === null || _a === void 0 ? void 0 : _a.contains(event.target))) {
          unstable_batchedUpdates(() => onCloseRequest(event));
        }
      };

      document.addEventListener("pointerdown", handler, false);
      return () => document.removeEventListener("pointerdown", handler, false);
    }
  }, [onCloseRequest]);
  return _jsx("div", Object.assign({
    className: "popover",
    ref: popoverRef,
    tabIndex: -1
  }, {
    children: children
  }));
};