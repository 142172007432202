var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

import { CODES, KEYS } from "../keys";
import { register } from "./register";
import { copyTextToSystemClipboard, copyToClipboard, createPasteEvent, probablySupportsClipboardBlob, probablySupportsClipboardWriteText, readSystemClipboard } from "../clipboard";
import { actionDeleteSelected } from "./actionDeleteSelected";
import { exportCanvas, prepareElementsForExport } from "../data/index";
import { isTextElement } from "../element";
import { t } from "../i18n";
import { isFirefox } from "../constants";
export const actionCopy = register({
  name: "copy",
  trackEvent: {
    category: "element"
  },
  perform: (elements, appState, event, app) => __awaiter(void 0, void 0, void 0, function* () {
    const elementsToCopy = app.scene.getSelectedElements({
      selectedElementIds: appState.selectedElementIds,
      includeBoundTextElement: true,
      includeElementsInFrames: true
    });

    try {
      yield copyToClipboard(elementsToCopy, app.files, event);
    } catch (error) {
      return {
        commitToHistory: false,
        appState: Object.assign(Object.assign({}, appState), {
          errorMessage: error.message
        })
      };
    }

    return {
      commitToHistory: false
    };
  }),
  contextItemLabel: "labels.copy",
  // don't supply a shortcut since we handle this conditionally via onCopy event
  keyTest: undefined
});
export const actionPaste = register({
  name: "paste",
  trackEvent: {
    category: "element"
  },
  perform: (elements, appState, data, app) => __awaiter(void 0, void 0, void 0, function* () {
    let types;

    try {
      types = yield readSystemClipboard();
    } catch (error) {
      if (error.name === "AbortError" || error.name === "NotAllowedError") {
        // user probably aborted the action. Though not 100% sure, it's best
        // to not annoy them with an error message.
        return false;
      }

      console.error(`actionPaste ${error.name}: ${error.message}`);

      if (isFirefox) {
        return {
          commitToHistory: false,
          appState: Object.assign(Object.assign({}, appState), {
            errorMessage: t("hints.firefox_clipboard_write")
          })
        };
      }

      return {
        commitToHistory: false,
        appState: Object.assign(Object.assign({}, appState), {
          errorMessage: t("errors.asyncPasteFailedOnRead")
        })
      };
    }

    try {
      app.pasteFromClipboard(createPasteEvent({
        types
      }));
    } catch (error) {
      console.error(error);
      return {
        commitToHistory: false,
        appState: Object.assign(Object.assign({}, appState), {
          errorMessage: t("errors.asyncPasteFailedOnParse")
        })
      };
    }

    return {
      commitToHistory: false
    };
  }),
  contextItemLabel: "labels.paste",
  // don't supply a shortcut since we handle this conditionally via onCopy event
  keyTest: undefined
});
export const actionCut = register({
  name: "cut",
  trackEvent: {
    category: "element"
  },
  perform: (elements, appState, event, app) => {
    actionCopy.perform(elements, appState, event, app);
    return actionDeleteSelected.perform(elements, appState);
  },
  contextItemLabel: "labels.cut",
  keyTest: event => event[KEYS.CTRL_OR_CMD] && event.key === KEYS.X
});
export const actionCopyAsSvg = register({
  name: "copyAsSvg",
  trackEvent: {
    category: "element"
  },
  perform: (elements, appState, _data, app) => __awaiter(void 0, void 0, void 0, function* () {
    if (!app.canvas) {
      return {
        commitToHistory: false
      };
    }

    const {
      exportedElements,
      exportingFrame
    } = prepareElementsForExport(elements, appState, true);

    try {
      yield exportCanvas("clipboard-svg", exportedElements, appState, app.files, Object.assign(Object.assign({}, appState), {
        exportingFrame
      }));
      return {
        commitToHistory: false
      };
    } catch (error) {
      console.error(error);
      return {
        appState: Object.assign(Object.assign({}, appState), {
          errorMessage: error.message
        }),
        commitToHistory: false
      };
    }
  }),
  predicate: elements => {
    return probablySupportsClipboardWriteText && elements.length > 0;
  },
  contextItemLabel: "labels.copyAsSvg"
});
export const actionCopyAsPng = register({
  name: "copyAsPng",
  trackEvent: {
    category: "element"
  },
  perform: (elements, appState, _data, app) => __awaiter(void 0, void 0, void 0, function* () {
    if (!app.canvas) {
      return {
        commitToHistory: false
      };
    }

    const selectedElements = app.scene.getSelectedElements({
      selectedElementIds: appState.selectedElementIds,
      includeBoundTextElement: true,
      includeElementsInFrames: true
    });
    const {
      exportedElements,
      exportingFrame
    } = prepareElementsForExport(elements, appState, true);

    try {
      yield exportCanvas("clipboard", exportedElements, appState, app.files, Object.assign(Object.assign({}, appState), {
        exportingFrame
      }));
      return {
        appState: Object.assign(Object.assign({}, appState), {
          toast: {
            message: t("toast.copyToClipboardAsPng", {
              exportSelection: selectedElements.length ? t("toast.selection") : t("toast.canvas"),
              exportColorScheme: appState.exportWithDarkMode ? t("buttons.darkMode") : t("buttons.lightMode")
            })
          }
        }),
        commitToHistory: false
      };
    } catch (error) {
      console.error(error);
      return {
        appState: Object.assign(Object.assign({}, appState), {
          errorMessage: error.message
        }),
        commitToHistory: false
      };
    }
  }),
  predicate: elements => {
    return probablySupportsClipboardBlob && elements.length > 0;
  },
  contextItemLabel: "labels.copyAsPng",
  keyTest: event => event.code === CODES.C && event.altKey && event.shiftKey
});
export const copyText = register({
  name: "copyText",
  trackEvent: {
    category: "element"
  },
  perform: (elements, appState, _, app) => {
    const selectedElements = app.scene.getSelectedElements({
      selectedElementIds: appState.selectedElementIds,
      includeBoundTextElement: true
    });
    const text = selectedElements.reduce((acc, element) => {
      if (isTextElement(element)) {
        acc.push(element.text);
      }

      return acc;
    }, []).join("\n\n");
    copyTextToSystemClipboard(text);
    return {
      commitToHistory: false
    };
  },
  predicate: (elements, appState, _, app) => {
    return probablySupportsClipboardWriteText && app.scene.getSelectedElements({
      selectedElementIds: appState.selectedElementIds,
      includeBoundTextElement: true
    }).some(isTextElement);
  },
  contextItemLabel: "labels.copyText"
});