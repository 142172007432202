import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";

const MenuGroup = ({
  children,
  className = "",
  style,
  title
}) => {
  return _jsxs("div", Object.assign({
    className: `dropdown-menu-group ${className}`,
    style: style
  }, {
    children: [title && _jsx("p", Object.assign({
      className: "dropdown-menu-group-title"
    }, {
      children: title
    })), children]
  }));
};

export default MenuGroup;
MenuGroup.displayName = "DropdownMenuGroup";