import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";

const MenuSeparator = () => _jsx("div", {
  style: {
    height: "1px",
    backgroundColor: "var(--default-border-color)",
    margin: ".5rem 0"
  }
});

export default MenuSeparator;
MenuSeparator.displayName = "DropdownMenuSeparator";