import oc from "open-color"; // FIXME can't put to utils.ts rn because of circular dependency

const pick = (source, keys) => {
  return keys.reduce((acc, key) => {
    if (key in source) {
      acc[key] = source[key];
    }

    return acc;
  }, {});
};

export const MAX_CUSTOM_COLORS_USED_IN_CANVAS = 5;
export const COLORS_PER_ROW = 5;
export const DEFAULT_CHART_COLOR_INDEX = 4;
export const DEFAULT_ELEMENT_STROKE_COLOR_INDEX = 4;
export const DEFAULT_ELEMENT_BACKGROUND_COLOR_INDEX = 1;
export const ELEMENTS_PALETTE_SHADE_INDEXES = [0, 2, 4, 6, 8];
export const CANVAS_PALETTE_SHADE_INDEXES = [0, 1, 2, 3, 4];
export const getSpecificColorShades = (color, indexArr) => {
  return indexArr.map(index => oc[color][index]);
};
export const COLOR_PALETTE = {
  transparent: "transparent",
  black: "#1e1e1e",
  white: "#ffffff",
  // open-colors
  gray: getSpecificColorShades("gray", ELEMENTS_PALETTE_SHADE_INDEXES),
  red: getSpecificColorShades("red", ELEMENTS_PALETTE_SHADE_INDEXES),
  pink: getSpecificColorShades("pink", ELEMENTS_PALETTE_SHADE_INDEXES),
  grape: getSpecificColorShades("grape", ELEMENTS_PALETTE_SHADE_INDEXES),
  violet: getSpecificColorShades("violet", ELEMENTS_PALETTE_SHADE_INDEXES),
  blue: getSpecificColorShades("blue", ELEMENTS_PALETTE_SHADE_INDEXES),
  cyan: getSpecificColorShades("cyan", ELEMENTS_PALETTE_SHADE_INDEXES),
  teal: getSpecificColorShades("teal", ELEMENTS_PALETTE_SHADE_INDEXES),
  green: getSpecificColorShades("green", ELEMENTS_PALETTE_SHADE_INDEXES),
  yellow: getSpecificColorShades("yellow", ELEMENTS_PALETTE_SHADE_INDEXES),
  orange: getSpecificColorShades("orange", ELEMENTS_PALETTE_SHADE_INDEXES),
  // radix bronze shades 3,5,7,9,11
  bronze: ["#f8f1ee", "#eaddd7", "#d2bab0", "#a18072", "#846358"]
};
const COMMON_ELEMENT_SHADES = pick(COLOR_PALETTE, ["cyan", "blue", "violet", "grape", "pink", "green", "teal", "yellow", "orange", "red"]); // -----------------------------------------------------------------------------
// quick picks defaults
// -----------------------------------------------------------------------------
// ORDER matters for positioning in quick picker

export const DEFAULT_ELEMENT_STROKE_PICKS = [COLOR_PALETTE.black, COLOR_PALETTE.red[DEFAULT_ELEMENT_STROKE_COLOR_INDEX], COLOR_PALETTE.green[DEFAULT_ELEMENT_STROKE_COLOR_INDEX], COLOR_PALETTE.blue[DEFAULT_ELEMENT_STROKE_COLOR_INDEX], COLOR_PALETTE.yellow[DEFAULT_ELEMENT_STROKE_COLOR_INDEX]]; // ORDER matters for positioning in quick picker

export const DEFAULT_ELEMENT_BACKGROUND_PICKS = [COLOR_PALETTE.transparent, COLOR_PALETTE.red[DEFAULT_ELEMENT_BACKGROUND_COLOR_INDEX], COLOR_PALETTE.green[DEFAULT_ELEMENT_BACKGROUND_COLOR_INDEX], COLOR_PALETTE.blue[DEFAULT_ELEMENT_BACKGROUND_COLOR_INDEX], COLOR_PALETTE.yellow[DEFAULT_ELEMENT_BACKGROUND_COLOR_INDEX]]; // ORDER matters for positioning in quick picker

export const DEFAULT_CANVAS_BACKGROUND_PICKS = [COLOR_PALETTE.white, // radix slate2
"#f8f9fa", // radix blue2
"#f5faff", // radix yellow2
"#fffce8", // radix bronze2
"#fdf8f6"]; // -----------------------------------------------------------------------------
// palette defaults
// -----------------------------------------------------------------------------

export const DEFAULT_ELEMENT_STROKE_COLOR_PALETTE = Object.assign({
  // 1st row
  transparent: COLOR_PALETTE.transparent,
  white: COLOR_PALETTE.white,
  gray: COLOR_PALETTE.gray,
  black: COLOR_PALETTE.black,
  bronze: COLOR_PALETTE.bronze
}, COMMON_ELEMENT_SHADES); // ORDER matters for positioning in pallete (5x3 grid)s

export const DEFAULT_ELEMENT_BACKGROUND_COLOR_PALETTE = Object.assign({
  transparent: COLOR_PALETTE.transparent,
  white: COLOR_PALETTE.white,
  gray: COLOR_PALETTE.gray,
  black: COLOR_PALETTE.black,
  bronze: COLOR_PALETTE.bronze
}, COMMON_ELEMENT_SHADES); // -----------------------------------------------------------------------------
// helpers
// -----------------------------------------------------------------------------
// !!!MUST BE WITHOUT GRAY, TRANSPARENT AND BLACK!!!

export const getAllColorsSpecificShade = index => [// 2nd row
COLOR_PALETTE.cyan[index], COLOR_PALETTE.blue[index], COLOR_PALETTE.violet[index], COLOR_PALETTE.grape[index], COLOR_PALETTE.pink[index], // 3rd row
COLOR_PALETTE.green[index], COLOR_PALETTE.teal[index], COLOR_PALETTE.yellow[index], COLOR_PALETTE.orange[index], COLOR_PALETTE.red[index]];
export const rgbToHex = (r, g, b) => `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`; // -----------------------------------------------------------------------------