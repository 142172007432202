import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
import "./LaserToolOverlay.scss";
export const LaserToolOverlay = ({
  manager
}) => {
  const svgRef = useRef(null);
  useEffect(() => {
    if (svgRef.current) {
      manager.start(svgRef.current);
    }

    return () => {
      manager.stop();
    };
  }, [manager]);
  return _jsx("div", Object.assign({
    className: "LaserToolOverlay"
  }, {
    children: _jsx("svg", {
      ref: svgRef,
      className: "LaserToolOverlayCanvas"
    })
  }));
};