import { fileOpen as _fileOpen, fileSave as _fileSave, supported as nativeFileSystemSupported } from "browser-fs-access";
import { EVENT, MIME_TYPES } from "../constants";
import { AbortError } from "../errors";
import { debounce } from "../utils";
const INPUT_CHANGE_INTERVAL_MS = 500;
export const fileOpen = opts => {
  var _a, _b, _c;

  const mimeTypes = (_a = opts.extensions) === null || _a === void 0 ? void 0 : _a.reduce((mimeTypes, type) => {
    mimeTypes.push(MIME_TYPES[type]);
    return mimeTypes;
  }, []);
  const extensions = (_b = opts.extensions) === null || _b === void 0 ? void 0 : _b.reduce((acc, ext) => {
    if (ext === "jpg") {
      return acc.concat(".jpg", ".jpeg");
    }

    return acc.concat(`.${ext}`);
  }, []);
  return _fileOpen({
    description: opts.description,
    extensions,
    mimeTypes,
    multiple: (_c = opts.multiple) !== null && _c !== void 0 ? _c : false,
    legacySetup: (resolve, reject, input) => {
      const scheduleRejection = debounce(reject, INPUT_CHANGE_INTERVAL_MS);

      const focusHandler = () => {
        checkForFile();
        document.addEventListener(EVENT.KEYUP, scheduleRejection);
        document.addEventListener(EVENT.POINTER_UP, scheduleRejection);
        scheduleRejection();
      };

      const checkForFile = () => {
        var _a; // this hack might not work when expecting multiple files


        if ((_a = input.files) === null || _a === void 0 ? void 0 : _a.length) {
          const ret = opts.multiple ? [...input.files] : input.files[0];
          resolve(ret);
        }
      };

      requestAnimationFrame(() => {
        window.addEventListener(EVENT.FOCUS, focusHandler);
      });
      const interval = window.setInterval(() => {
        checkForFile();
      }, INPUT_CHANGE_INTERVAL_MS);
      return rejectPromise => {
        clearInterval(interval);
        scheduleRejection.cancel();
        window.removeEventListener(EVENT.FOCUS, focusHandler);
        document.removeEventListener(EVENT.KEYUP, scheduleRejection);
        document.removeEventListener(EVENT.POINTER_UP, scheduleRejection);

        if (rejectPromise) {
          // so that something is shown in console if we need to debug this
          console.warn("Opening the file was canceled (legacy-fs).");
          rejectPromise(new AbortError());
        }
      };
    }
  });
};
export const fileSave = (blob, opts) => {
  return _fileSave(blob, {
    fileName: `${opts.name}.${opts.extension}`,
    description: opts.description,
    extensions: [`.${opts.extension}`]
  }, opts.fileHandle);
};
export { nativeFileSystemSupported };