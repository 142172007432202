import { jsx as _jsx } from "react/jsx-runtime";
import { getClientColor } from "../clients";
import { Avatar } from "../components/Avatar";
import { centerScrollOn } from "../scene/scroll";
import { register } from "./register";
export const actionGoToCollaborator = register({
  name: "goToCollaborator",
  viewMode: true,
  trackEvent: {
    category: "collab"
  },
  perform: (_elements, appState, value) => {
    const point = value;

    if (!point) {
      return {
        appState,
        commitToHistory: false
      };
    }

    return {
      appState: Object.assign(Object.assign(Object.assign({}, appState), centerScrollOn({
        scenePoint: point,
        viewportDimensions: {
          width: appState.width,
          height: appState.height
        },
        zoom: appState.zoom
      })), {
        // Close mobile menu
        openMenu: appState.openMenu === "canvas" ? null : appState.openMenu
      }),
      commitToHistory: false
    };
  },
  PanelComponent: ({
    updateData,
    data
  }) => {
    const [clientId, collaborator] = data;
    const background = getClientColor(clientId);
    return _jsx(Avatar, {
      color: background,
      onClick: () => updateData(collaborator.pointer),
      name: collaborator.username || "",
      src: collaborator.avatarUrl
    });
  }
});