import { ArrowIcon, DiamondIcon, EllipseIcon, EraserIcon, FreedrawIcon, ImageIcon, LineIcon, RectangleIcon, SelectionIcon, TextIcon } from "./components/icons";
import { KEYS } from "./keys";
export const SHAPES = [{
  icon: SelectionIcon,
  value: "selection",
  key: KEYS.V,
  numericKey: KEYS["1"],
  fillable: true
}, {
  icon: RectangleIcon,
  value: "rectangle",
  key: KEYS.R,
  numericKey: KEYS["2"],
  fillable: true
}, {
  icon: DiamondIcon,
  value: "diamond",
  key: KEYS.D,
  numericKey: KEYS["3"],
  fillable: true
}, {
  icon: EllipseIcon,
  value: "ellipse",
  key: KEYS.O,
  numericKey: KEYS["4"],
  fillable: true
}, {
  icon: ArrowIcon,
  value: "arrow",
  key: KEYS.A,
  numericKey: KEYS["5"],
  fillable: true
}, {
  icon: LineIcon,
  value: "line",
  key: KEYS.L,
  numericKey: KEYS["6"],
  fillable: true
}, {
  icon: FreedrawIcon,
  value: "freedraw",
  key: [KEYS.P, KEYS.X],
  numericKey: KEYS["7"],
  fillable: false
}, {
  icon: TextIcon,
  value: "text",
  key: KEYS.T,
  numericKey: KEYS["8"],
  fillable: false
}, {
  icon: ImageIcon,
  value: "image",
  key: null,
  numericKey: KEYS["9"],
  fillable: false
}, {
  icon: EraserIcon,
  value: "eraser",
  key: KEYS.E,
  numericKey: KEYS["0"],
  fillable: false
} // TODO: frame, create icon and set up numeric key
// {
//   icon: RectangleIcon,
//   value: "frame",
//   key: KEYS.F,
//   numericKey: KEYS.SUBTRACT,
//   fillable: false,
// },
];
export const findShapeByKey = key => {
  const shape = SHAPES.find((shape, index) => {
    return shape.numericKey != null && key === shape.numericKey.toString() || shape.key && (typeof shape.key === "string" ? shape.key === key : shape.key.includes(key));
  });
  return (shape === null || shape === void 0 ? void 0 : shape.value) || null;
};