var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import clsx from "clsx";
import React from "react";
import { CLASSES, DEFAULT_SIDEBAR, LIBRARY_SIDEBAR_WIDTH } from "../constants";
import { showSelectedShapeActions } from "../element";
import { t } from "../i18n";
import { calculateScrollCenter } from "../scene";
import { capitalizeString, isShallowEqual } from "../utils";
import { SelectedShapeActions, ShapesSwitcher } from "./Actions";
import { ErrorDialog } from "./ErrorDialog";
import { ImageExportDialog } from "./ImageExportDialog";
import { FixedSideContainer } from "./FixedSideContainer";
import { HintViewer } from "./HintViewer";
import { Island } from "./Island";
import { LoadingMessage } from "./LoadingMessage";
import { LockButton } from "./LockButton";
import { MobileMenu } from "./MobileMenu";
import { PasteChartDialog } from "./PasteChartDialog";
import { Section } from "./Section";
import { HelpDialog } from "./HelpDialog";
import Stack from "./Stack";
import { UserList } from "./UserList";
import { JSONExportDialog } from "./JSONExportDialog";
import { PenModeButton } from "./PenModeButton";
import { trackEvent } from "../analytics";
import { useDevice } from "../components/App";
import { Stats } from "./Stats";
import { actionToggleStats } from "../actions/actionToggleStats";
import Footer from "./footer/Footer";
import { isSidebarDockedAtom } from "./Sidebar/Sidebar";
import { jotaiScope } from "../jotai";
import { Provider, useAtom, useAtomValue } from "jotai";
import MainMenu from "./main-menu/MainMenu";
import { ActiveConfirmDialog } from "./ActiveConfirmDialog";
import { OverwriteConfirmDialog } from "./OverwriteConfirm/OverwriteConfirm";
import { HandButton } from "./HandButton";
import { isHandToolActive } from "../appState";
import { TunnelsContext, useInitializeTunnels } from "../context/tunnels";
import { LibraryIcon } from "./icons";
import { UIAppStateContext } from "../context/ui-appState";
import { DefaultSidebar } from "./DefaultSidebar";
import { EyeDropper, activeEyeDropperAtom } from "./EyeDropper";
import "./LayerUI.scss";
import "./Toolbar.scss";
import { mutateElement } from "../element/mutateElement";
import { ShapeCache } from "../scene/ShapeCache";
import Scene from "../scene/Scene";
import { LaserPointerButton } from "./LaserTool/LaserPointerButton";

const DefaultMainMenu = ({
  UIOptions
}) => {
  return _jsxs(MainMenu, Object.assign({
    __fallback: true
  }, {
    children: [_jsx(MainMenu.DefaultItems.LoadScene, {}), _jsx(MainMenu.DefaultItems.SaveToActiveFile, {}), UIOptions.canvasActions.export && _jsx(MainMenu.DefaultItems.Export, {}), UIOptions.canvasActions.saveAsImage && _jsx(MainMenu.DefaultItems.SaveAsImage, {}), _jsx(MainMenu.DefaultItems.Help, {}), _jsx(MainMenu.DefaultItems.ClearCanvas, {}), _jsx(MainMenu.Separator, {}), _jsx(MainMenu.Group, Object.assign({
      title: "Excalidraw links"
    }, {
      children: _jsx(MainMenu.DefaultItems.Socials, {})
    })), _jsx(MainMenu.Separator, {}), _jsx(MainMenu.DefaultItems.ToggleTheme, {}), _jsx(MainMenu.DefaultItems.ChangeCanvasBackground, {})]
  }));
};

const DefaultOverwriteConfirmDialog = () => {
  return _jsxs(OverwriteConfirmDialog, Object.assign({
    __fallback: true
  }, {
    children: [_jsx(OverwriteConfirmDialog.Actions.SaveToDisk, {}), _jsx(OverwriteConfirmDialog.Actions.ExportToImage, {})]
  }));
};

const LayerUI = ({
  actionManager,
  appState,
  files,
  setAppState,
  elements,
  canvas,
  onLockToggle,
  onHandToolToggle,
  onPenModeToggle,
  showExitZenModeBtn,
  renderTopRightUI,
  renderCustomStats,
  UIOptions,
  onExportImage,
  renderWelcomeScreen,
  children,
  app,
  isCollaborating
}) => {
  const device = useDevice();
  const tunnels = useInitializeTunnels();
  const [eyeDropperState, setEyeDropperState] = useAtom(activeEyeDropperAtom, jotaiScope);

  const renderJSONExportDialog = () => {
    if (!UIOptions.canvasActions.export) {
      return null;
    }

    return _jsx(JSONExportDialog, {
      elements: elements,
      appState: appState,
      files: files,
      actionManager: actionManager,
      exportOpts: UIOptions.canvasActions.export,
      canvas: canvas,
      setAppState: setAppState
    });
  };

  const renderImageExportDialog = () => {
    if (!UIOptions.canvasActions.saveAsImage || appState.openDialog !== "imageExport") {
      return null;
    }

    return _jsx(ImageExportDialog, {
      elements: elements,
      appState: appState,
      files: files,
      actionManager: actionManager,
      onExportImage: onExportImage,
      onCloseRequest: () => setAppState({
        openDialog: null
      })
    });
  };

  const renderCanvasActions = () => _jsxs("div", Object.assign({
    style: {
      position: "relative"
    }
  }, {
    children: [_jsx(tunnels.MainMenuTunnel.Out, {}), renderWelcomeScreen && _jsx(tunnels.WelcomeScreenMenuHintTunnel.Out, {})]
  }));

  const renderSelectedShapeActions = () => _jsx(Section, Object.assign({
    heading: "selectedShapeActions",
    className: clsx("selected-shape-actions zen-mode-transition", {
      "transition-left": appState.zenModeEnabled
    })
  }, {
    children: _jsx(Island, Object.assign({
      className: CLASSES.SHAPE_ACTIONS_MENU,
      padding: 2,
      style: {
        // we want to make sure this doesn't overflow so subtracting the
        // approximate height of hamburgerMenu + footer
        maxHeight: `${appState.height - 166}px`
      }
    }, {
      children: _jsx(SelectedShapeActions, {
        appState: appState,
        elements: elements,
        renderAction: actionManager.renderAction
      })
    }))
  }));

  const renderFixedSideContainer = () => {
    var _a;

    const shouldRenderSelectedShapeActions = showSelectedShapeActions(appState, elements);
    return _jsx(FixedSideContainer, Object.assign({
      side: "top"
    }, {
      children: _jsxs("div", Object.assign({
        className: "App-menu App-menu_top"
      }, {
        children: [_jsxs(Stack.Col, Object.assign({
          gap: 6,
          className: clsx("App-menu_top__left")
        }, {
          children: [renderCanvasActions(), shouldRenderSelectedShapeActions && renderSelectedShapeActions()]
        })), !appState.viewModeEnabled && _jsx(Section, Object.assign({
          heading: "shapes",
          className: "shapes-section"
        }, {
          children: heading => _jsxs("div", Object.assign({
            style: {
              position: "relative"
            }
          }, {
            children: [renderWelcomeScreen && _jsx(tunnels.WelcomeScreenToolbarHintTunnel.Out, {}), _jsx(Stack.Col, Object.assign({
              gap: 4,
              align: "start"
            }, {
              children: _jsxs(Stack.Row, Object.assign({
                gap: 1,
                className: clsx("App-toolbar-container", {
                  "zen-mode": appState.zenModeEnabled
                })
              }, {
                children: [_jsxs(Island, Object.assign({
                  padding: 1,
                  className: clsx("App-toolbar", {
                    "zen-mode": appState.zenModeEnabled
                  })
                }, {
                  children: [_jsx(HintViewer, {
                    appState: appState,
                    isMobile: device.editor.isMobile,
                    device: device,
                    app: app
                  }), heading, _jsxs(Stack.Row, Object.assign({
                    gap: 1
                  }, {
                    children: [_jsx(PenModeButton, {
                      zenModeEnabled: appState.zenModeEnabled,
                      checked: appState.penMode,
                      onChange: () => onPenModeToggle(null),
                      title: t("toolBar.penMode"),
                      penDetected: appState.penDetected
                    }), _jsx(LockButton, {
                      checked: appState.activeTool.locked,
                      onChange: onLockToggle,
                      title: t("toolBar.lock")
                    }), _jsx("div", {
                      className: "App-toolbar__divider"
                    }), _jsx(HandButton, {
                      checked: isHandToolActive(appState),
                      onChange: () => onHandToolToggle(),
                      title: t("toolBar.hand"),
                      isMobile: true
                    }), _jsx(ShapesSwitcher, {
                      appState: appState,
                      activeTool: appState.activeTool,
                      UIOptions: UIOptions,
                      app: app
                    })]
                  }))]
                })), isCollaborating && _jsx(Island, Object.assign({
                  style: {
                    marginLeft: 8,
                    alignSelf: "center",
                    height: "fit-content"
                  }
                }, {
                  children: _jsx(LaserPointerButton, {
                    title: t("toolBar.laser"),
                    checked: appState.activeTool.type === "laser",
                    onChange: () => app.setActiveTool({
                      type: "laser"
                    }),
                    isMobile: true
                  })
                }))]
              }))
            }))]
          }))
        })), _jsxs("div", Object.assign({
          className: clsx("layer-ui__wrapper__top-right zen-mode-transition", {
            "transition-right": appState.zenModeEnabled
          })
        }, {
          children: [_jsx(UserList, {
            collaborators: appState.collaborators
          }), renderTopRightUI === null || renderTopRightUI === void 0 ? void 0 : renderTopRightUI(device.editor.isMobile, appState), !appState.viewModeEnabled && ( // hide button when sidebar docked
          !isSidebarDocked || ((_a = appState.openSidebar) === null || _a === void 0 ? void 0 : _a.name) !== DEFAULT_SIDEBAR.name) && _jsx(tunnels.DefaultSidebarTriggerTunnel.Out, {})]
        }))]
      }))
    }));
  };

  const renderSidebars = () => {
    return _jsx(DefaultSidebar, {
      __fallback: true,
      onDock: docked => {
        trackEvent("sidebar", `toggleDock (${docked ? "dock" : "undock"})`, `(${device.editor.isMobile ? "mobile" : "desktop"})`);
      }
    });
  };

  const isSidebarDocked = useAtomValue(isSidebarDockedAtom, jotaiScope);

  const layerUIJSX = _jsxs(_Fragment, {
    children: [children, _jsx(DefaultMainMenu, {
      UIOptions: UIOptions
    }), _jsx(DefaultSidebar.Trigger, Object.assign({
      __fallback: true,
      icon: LibraryIcon,
      title: capitalizeString(t("toolBar.library")),
      onToggle: open => {
        if (open) {
          trackEvent("sidebar", `${DEFAULT_SIDEBAR.name} (open)`, `button (${device.editor.isMobile ? "mobile" : "desktop"})`);
        }
      },
      tab: DEFAULT_SIDEBAR.defaultTab
    }, {
      children: t("toolBar.library")
    })), _jsx(DefaultOverwriteConfirmDialog, {}), appState.isLoading && _jsx(LoadingMessage, {
      delay: 250
    }), appState.errorMessage && _jsx(ErrorDialog, Object.assign({
      onClose: () => setAppState({
        errorMessage: null
      })
    }, {
      children: appState.errorMessage
    })), eyeDropperState && !device.editor.isMobile && _jsx(EyeDropper, {
      colorPickerType: eyeDropperState.colorPickerType,
      onCancel: () => {
        setEyeDropperState(null);
      },
      onChange: (colorPickerType, color, selectedElements, {
        altKey
      }) => {
        var _a;

        if (colorPickerType !== "elementBackground" && colorPickerType !== "elementStroke") {
          return;
        }

        if (selectedElements.length) {
          for (const element of selectedElements) {
            mutateElement(element, {
              [altKey && eyeDropperState.swapPreviewOnAlt ? colorPickerType === "elementBackground" ? "strokeColor" : "backgroundColor" : colorPickerType === "elementBackground" ? "backgroundColor" : "strokeColor"]: color
            }, false);
            ShapeCache.delete(element);
          }

          (_a = Scene.getScene(selectedElements[0])) === null || _a === void 0 ? void 0 : _a.informMutation();
        } else if (colorPickerType === "elementBackground") {
          setAppState({
            currentItemBackgroundColor: color
          });
        } else {
          setAppState({
            currentItemStrokeColor: color
          });
        }
      },
      onSelect: (color, event) => {
        var _a;

        setEyeDropperState(state => {
          return (state === null || state === void 0 ? void 0 : state.keepOpenOnAlt) && event.altKey ? state : null;
        });
        (_a = eyeDropperState === null || eyeDropperState === void 0 ? void 0 : eyeDropperState.onSelect) === null || _a === void 0 ? void 0 : _a.call(eyeDropperState, color, event);
      }
    }), appState.openDialog === "help" && _jsx(HelpDialog, {
      onClose: () => {
        setAppState({
          openDialog: null
        });
      }
    }), _jsx(ActiveConfirmDialog, {}), _jsx(tunnels.OverwriteConfirmDialogTunnel.Out, {}), renderImageExportDialog(), renderJSONExportDialog(), appState.pasteDialog.shown && _jsx(PasteChartDialog, {
      setAppState: setAppState,
      appState: appState,
      onClose: () => setAppState({
        pasteDialog: {
          shown: false,
          data: null
        }
      })
    }), device.editor.isMobile && _jsx(MobileMenu, {
      app: app,
      appState: appState,
      elements: elements,
      actionManager: actionManager,
      renderJSONExportDialog: renderJSONExportDialog,
      renderImageExportDialog: renderImageExportDialog,
      setAppState: setAppState,
      onLockToggle: onLockToggle,
      onHandToolToggle: onHandToolToggle,
      onPenModeToggle: onPenModeToggle,
      renderTopRightUI: renderTopRightUI,
      renderCustomStats: renderCustomStats,
      renderSidebars: renderSidebars,
      device: device,
      renderWelcomeScreen: renderWelcomeScreen,
      UIOptions: UIOptions
    }), !device.editor.isMobile && _jsxs(_Fragment, {
      children: [_jsxs("div", Object.assign({
        className: "layer-ui__wrapper",
        style: appState.openSidebar && isSidebarDocked && device.editor.canFitSidebar ? {
          width: `calc(100% - ${LIBRARY_SIDEBAR_WIDTH}px)`
        } : {}
      }, {
        children: [renderWelcomeScreen && _jsx(tunnels.WelcomeScreenCenterTunnel.Out, {}), renderFixedSideContainer(), _jsx(Footer, {
          appState: appState,
          actionManager: actionManager,
          showExitZenModeBtn: showExitZenModeBtn,
          renderWelcomeScreen: renderWelcomeScreen
        }), appState.showStats && _jsx(Stats, {
          appState: appState,
          setAppState: setAppState,
          elements: elements,
          onClose: () => {
            actionManager.executeAction(actionToggleStats);
          },
          renderCustomStats: renderCustomStats
        }), appState.scrolledOutside && _jsx("button", Object.assign({
          className: "scroll-back-to-content",
          onClick: () => {
            setAppState(appState => Object.assign({}, calculateScrollCenter(elements, appState)));
          }
        }, {
          children: t("buttons.scrollBackToContent")
        }))]
      })), renderSidebars()]
    })]
  });

  return _jsx(UIAppStateContext.Provider, Object.assign({
    value: appState
  }, {
    children: _jsx(Provider, Object.assign({
      scope: tunnels.jotaiScope
    }, {
      children: _jsx(TunnelsContext.Provider, Object.assign({
        value: tunnels
      }, {
        children: layerUIJSX
      }))
    }))
  }));
};

const stripIrrelevantAppStateProps = appState => {
  const {
    suggestedBindings,
    startBoundElement,
    cursorButton,
    scrollX,
    scrollY
  } = appState,
        ret = __rest(appState, ["suggestedBindings", "startBoundElement", "cursorButton", "scrollX", "scrollY"]);

  return ret;
};

const areEqual = (prevProps, nextProps) => {
  // short-circuit early
  if (prevProps.children !== nextProps.children) {
    return false;
  }

  const {
    canvas: _pC,
    appState: prevAppState
  } = prevProps,
        prev = __rest(prevProps, ["canvas", "appState"]);

  const {
    canvas: _nC,
    appState: nextAppState
  } = nextProps,
        next = __rest(nextProps, ["canvas", "appState"]);

  return isShallowEqual( // asserting AppState because we're being passed the whole AppState
  // but resolve to only the UI-relevant props
  stripIrrelevantAppStateProps(prevAppState), stripIrrelevantAppStateProps(nextAppState), {
    selectedElementIds: isShallowEqual,
    selectedGroupIds: isShallowEqual
  }) && isShallowEqual(prev, next);
};

export default React.memo(LayerUI, areEqual);