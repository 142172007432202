import { useState, useLayoutEffect } from "react";
import { useDevice, useExcalidrawContainer } from "../components/App";
import { useUIAppState } from "../context/ui-appState";
export const useCreatePortalContainer = opts => {
  const [div, setDiv] = useState(null);
  const device = useDevice();
  const {
    theme
  } = useUIAppState();
  const {
    container: excalidrawContainer
  } = useExcalidrawContainer();
  useLayoutEffect(() => {
    var _a;

    if (div) {
      div.className = "";
      div.classList.add("excalidraw", ...(((_a = opts === null || opts === void 0 ? void 0 : opts.className) === null || _a === void 0 ? void 0 : _a.split(/\s+/)) || []));
      div.classList.toggle("excalidraw--mobile", device.editor.isMobile);
      div.classList.toggle("theme--dark", theme === "dark");
    }
  }, [div, theme, device.editor.isMobile, opts === null || opts === void 0 ? void 0 : opts.className]);
  useLayoutEffect(() => {
    const container = (opts === null || opts === void 0 ? void 0 : opts.parentSelector) ? excalidrawContainer === null || excalidrawContainer === void 0 ? void 0 : excalidrawContainer.querySelector(opts.parentSelector) : document.body;

    if (!container) {
      return;
    }

    const div = document.createElement("div");
    container.appendChild(div);
    setDiv(div);
    return () => {
      container.removeChild(div);
    };
  }, [excalidrawContainer, opts === null || opts === void 0 ? void 0 : opts.parentSelector]);
  return div;
};