import { arrayToMapWithIndex } from "../utils";

const normalizeGroupElementOrder = elements => {
  const origElements = elements.slice();
  const sortedElements = new Set();

  const orderInnerGroups = elements => {
    var _a, _b, _c;

    const firstGroupSig = (_b = (_a = elements[0]) === null || _a === void 0 ? void 0 : _a.groupIds) === null || _b === void 0 ? void 0 : _b.join("");
    const aGroup = [elements[0]];
    const bGroup = [];

    for (const element of elements.slice(1)) {
      if (((_c = element.groupIds) === null || _c === void 0 ? void 0 : _c.join("")) === firstGroupSig) {
        aGroup.push(element);
      } else {
        bGroup.push(element);
      }
    }

    return bGroup.length ? [...aGroup, ...orderInnerGroups(bGroup)] : aGroup;
  };

  const groupHandledElements = new Map();
  origElements.forEach((element, idx) => {
    var _a;

    if (groupHandledElements.has(element.id)) {
      return;
    }

    if ((_a = element.groupIds) === null || _a === void 0 ? void 0 : _a.length) {
      const topGroup = element.groupIds[element.groupIds.length - 1];
      const groupElements = origElements.slice(idx).filter(element => {
        var _a;

        const ret = (_a = element === null || element === void 0 ? void 0 : element.groupIds) === null || _a === void 0 ? void 0 : _a.some(id => id === topGroup);

        if (ret) {
          groupHandledElements.set(element.id, true);
        }

        return ret;
      });

      for (const elem of orderInnerGroups(groupElements)) {
        sortedElements.add(elem);
      }
    } else {
      sortedElements.add(element);
    }
  }); // if there's a bug which resulted in losing some of the elements, return
  // original instead as that's better than losing data

  if (sortedElements.size !== elements.length) {
    console.error("normalizeGroupElementOrder: lost some elements... bailing!");
    return elements;
  }

  return [...sortedElements];
};
/**
 * In theory, when we have text elements bound to a container, they
 * should be right after the container element in the elements array.
 * However, this is not guaranteed due to old and potential future bugs.
 *
 * This function sorts containers and their bound texts together. It prefers
 * original z-index of container (i.e. it moves bound text elements after
 * containers).
 */


const normalizeBoundElementsOrder = elements => {
  const elementsMap = arrayToMapWithIndex(elements);
  const origElements = elements.slice();
  const sortedElements = new Set();
  origElements.forEach((element, idx) => {
    var _a, _b;

    if (!element) {
      return;
    }

    if ((_a = element.boundElements) === null || _a === void 0 ? void 0 : _a.length) {
      sortedElements.add(element);
      origElements[idx] = null;
      element.boundElements.forEach(boundElement => {
        const child = elementsMap.get(boundElement.id);

        if (child && boundElement.type === "text") {
          sortedElements.add(child[0]);
          origElements[child[1]] = null;
        }
      });
    } else if (element.type === "text" && element.containerId) {
      const parent = elementsMap.get(element.containerId);

      if (!((_b = parent === null || parent === void 0 ? void 0 : parent[0].boundElements) === null || _b === void 0 ? void 0 : _b.find(x => x.id === element.id))) {
        sortedElements.add(element);
        origElements[idx] = null; // if element has a container and container lists it, skip this element
        // as it'll be taken care of by the container
      }
    } else {
      sortedElements.add(element);
      origElements[idx] = null;
    }
  }); // if there's a bug which resulted in losing some of the elements, return
  // original instead as that's better than losing data

  if (sortedElements.size !== elements.length) {
    console.error("normalizeBoundElementsOrder: lost some elements... bailing!");
    return elements;
  }

  return [...sortedElements];
};

export const normalizeElementOrder = elements => {
  // console.time();
  const ret = normalizeBoundElementsOrder(normalizeGroupElementOrder(elements)); // console.timeEnd();

  return ret;
};